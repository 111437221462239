@import 'variables.less';

// ****************************************************************************
// *                               MISCELLANEOUS                              *
// *                         includes all extra styles                        *
// ****************************************************************************
.img-icon
{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.list-group-navigation
{
	.list-group-item
	{
		.list-icon
		{
			width: 40px;
			height: 40px;
			background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
			border-radius: 4px;
			font-size: 16px;
			color: white;
			text-align: center;
			line-height: 40px;
			float: left;
		}
		.list-text
		{
			margin-left: 60px;
			color: @primary-content-color;
			font-weight: 600;
			text-transform: capitalize;
			.text-muted
			{
				font-weight: 400;
			}
		}
		
		border: 1px solid @panel-border-color;
		&:hover
		{
			background-color: @default-from-color;
		}
		
	}
}