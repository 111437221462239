// ****************************************************************************
// *                                  MIXINS                                  *
// *                       we can define all mixins here                      *
// ****************************************************************************

.btn-variant(@from-color,@to-color) {
  	
  	background-color: @to-color;

  	& when (@button-gradients = true) {
		.gradient(@from-color,@to-color);
		border: 1px solid darken(@from-color, 5%);
	}
	
	//for adding any icons at prefix or suffix, use <span></span>
	span
	{
		color: lighten(@to-color, 20%);
		margin-right: 8px;
	}
	&:hover, &:active, &:focus
	{
		& when (@button-gradients = true) {
    		background-image: linear-gradient(-1deg, darken(@from-color, 10%) 2%, darken(@to-color, 10%) 98%);
    	}
		border-color: darken(@from-color,7%);
	}
}


.gradient(@from-color,@to-color)
{

				// background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);


	background-image: -webkit-linear-gradient(-1deg, @from-color 2%, @to-color 98%);
	background-image: -o-linear-gradient(-1deg, @from-color 2%, @to-color 98%);
	background-image: linear-gradient(-1deg, @from-color 2%, @to-color 98%);
}