@import 'variables.less';

// ****************************************************************************
// *                                TYPOGRAPHY                                *
// *                            includes h tags, p                            *
// ****************************************************************************

body
{
    background: #EFF3F6;
    font-family: @source-sans;
    font-size: @primary-font-size;
    color: @primary-content-color;
	letter-spacing: 0.1px;

}

h1
{
	font-size: 32px;
	line-height: 44px !important;	
}
h2
{
	font-size: 22px;
	line-height: 34px !important;	
}
h3
{
	font-size: 20px;
	line-height: 28px !important;	
}
h4
{
	font-size: 16px;
	line-height: 28px !important;	
}
h4
{
	font-size: 14px;
	line-height: 24px !important;	
}
p
{
	font-family: @primary-font;
	font-size: 14px;
	color: #71787E;
	line-height: 24px;
}

.striked
{
	text-decoration: line-through;
}

.no-margin
{
	margin: 0px !important;
}

a
{
	color: @primary-color;
}