@import 'variables.less';

// ****************************************************************************
// *                               NAVBAR BRAND                               *
// *                            logo or brand name                            *
// ****************************************************************************
.navbar-brand
{
	padding: @navbar-brand-padding;
}

.navbar-default
{
	box-shadow: 0px -1px 0px 0px #E6EAEE;
	border-width: 0px;
	border-radius: 0px;
	.navbar-brand
	{
		padding: 13px 10px 13px 30px;
	}
	> .navbar-nav
	{
		> a
		{
			font-family : @primary-font;
			text-transform: capitalize;
			.fa-angle-down
			{
				margin-left: 11px;
			}
		}
		.active > a
		{
			background-color: transparent !important;
		}
	}
}
.navbar-default.navbar-blue
{
	background-image: linear-gradient(-1deg, #2EA1F8 2%, #1990EA 100%);
	.navbar-nav.navbar-left
	{	
		a 
		{
			font-weight: 600;
			font-size: @primary-font-size;
			text-transform: capitalize;
			color: #FFFFFF;	
		}
		.dropdown-menu
		{
			a 
			{
				color: #333333;
			}
		}
	}
}
.navbar-right
{
	.position-icons-list
	{
		margin-top: 20px;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-right: 21px;
		border-right: 1px solid #2087D5;

		li 
		{
			img 
			{
				width: 14px;
				height: 14px;
			}
		}
	}
	.admin-settings-list
	{
		
		padding-left: 20px;
		li 
		{	
			padding-left: 10px;
			padding-right: 10px;
			i 
			{
				color: #fff;
				font-size: 16px;	
			}
		}
	}
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus
{
	background-color: transparent;
}
.nav .open > a, .nav .open > a:hover, .nav .open > a:focus
{
	background-color: transparent;
}
.dropdown.user-image
{	
	padding-top: 17px;
	.dropdown-toggle
	{
		text-decoration: none;
		background-color: transparent;
		border-color: transparent;
		color: transparent;
		margin-left: 10px;
		padding-left: 10px;
		i 
		{
			color: #86CAFF;
			border-radius: 83px;
			margin-left: 8px;
			padding-right: 5px;
		}
	}
}
.dropdown.user-image.with-form
{
	margin-right: 10px;
	margin-left: 5px;
}
.navbar-heading
{
	font-family: @primary-font;
	font-weight: 600;
	font-size: 18px;
	color: @primary-content-color;
	text-transform: capitalize;
	margin-top: 15px;
	.sub-text
	{
		font-family: @primary-font;
		font-weight: 400;
		font-size:  @primary-font-size;
		color: @secondary-content-color;	
		border-left: 1px solid @input-border-color;
		text-transform: capitalize;
		padding-left: 13px;
		margin-left: 9px;
	}
}
.navbar-heading.dark-background
{
	color: #fff;
	.sub-text
	{
		border-left: 1px solid @secondary-content-color;
	}
}
.play-list
{	
	margin-top: 17px;	
	li 
	{	
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 8px;
		padding-bottom: 8px;
		border-left: 1px solid #E5E9EF;
		&:first-child
		{
			border-left: 0px;
			padding-left: 20px;
		}
		.btn 
		{
			width: 24px;
			height: 24px;
			border-radius: 50%;
			padding: 0px;
			border: 2px solid #BFC7D8;
			i 
			{
				
				color: #BFC7D8;
				border-radius: 1px;
			}
		}
	}
}
.navbar-nav
{
	li
	{	
		position: relative;
		.activity-count
		{
			position: absolute;
			background: #FF7800;
			width: 20px;
			height: 20px;
			text-align: center;
			border-radius: 50%;
			top: 14px;
			font-family: @primary-font;
			font-size: 12px;
			font-weight: 600;
			line-height: 17px;
			color: #FFFFFF;
			right: 2px;
		}
	}
	.dropdown-menu
	{
	    padding: 5px 0;
	    margin: 2px 0 0;
	    list-style: none;
	    font-size: 14px;
	    text-align: left;
	    background-color: #fff;
	    border: 1px solid @panel-border-color;
	    border-radius: 4px;
	    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	    background-clip: padding-box;
	    > li
	    {
	    	 > a
		    {
					    	
		    }
	    }
	}

}
.navbar-form
{		
	margin-top: 16px;
	.input-group
	{
		.form-control
		{
			border-right-width: 0px;
			height: 36px;
			margin-left: 7px;
		}
		.input-group-btn
		{
			.btn, a
			{
				background: none;
				border-color: @input-border-color;
				color: #A1A7AF;
				border-left-width: 0px;
				height: 36px;
			}
		}
	}
	button.btn 
	{
		padding: 7px 12px;
		margin-left: 7px;
	}
}

.navbar
{
	li.button
	{
		padding-top:18px;
		a.btn
		{
			padding-top: 3px;
			padding-bottom: 5px;
			i.fa
			{
				font-size: 16px !important;
			}
		}
	}
}

.navbar-icons
{
	 > li 
	 {

	 	> a 
	 	{
			color: #BFC7D8 !important; 	
			font-size: 24px !important;
		 }
	 }
}

.navbar-primary 
{
	.gradient(#1990EA,#2EA1F8);
	.navbar-nav > li > a {
	  color: #FFFFFF;
	  font-size: 16px !important;
	}
}

.navbar-dark
{
	background: #273142;
	.navbar-nav > li > a
	{
		color : #7F8FA4 !important;
		text-transform: capitalize;
		font-weight: 600;
	}
	.navbar-form
	{
		.form-control
		{
			background: #222C3C;
			border: 1px solid #313D4F;
			border-radius: 4px;
			color : #7F8FA4;
			border-right-width: 0px;
		}
		.input-group-btn
		{
			.btn, a
			{
				background: #222C3C;
				border-color: #313D4F !important;
				color: #7F8FA4 !important;
				border-left-width: 0px;
			}
		}
	}
	.dropdown.user-image
	{
		.dropdown-toggle
		{
			i 
			{
				color: #A1A7AF;
			}
		}
	}
}