@import 'variables.less';

.nav-tabs
{
	border-bottom-width: 0px;
	li
	{
		a
		{
			.gradient(#F4F7FA,#FFFFFF);
			border: 1px solid #E6EAEE;
			border-radius: 6px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			color: @primary-content-color;
			font-size: 14px;
			margin-bottom: -1px;
			text-transform: capitalize;
			padding-left: 30px;
			padding-right: 30px;
			&:hover
			{
				border-bottom-width: 0px;

			}
			&:hover, &:active, &:focus
			{
				& when (@button-gradients = true) {
		    		background-image: linear-gradient(-1deg, darken(#F4F7FA, 2%) 2%, darken(#FFFFFF, 2%) 98%);
		    	}
			}
		}
	}
	li.active a
	{
		border-top:1px solid #E6EAEE !important; 	
		border-left:1px solid #E6EAEE !important; 	
		border-right:1px solid #E6EAEE !important; 	
		background-image: none;
		line-height: 26px;
		color: @primary-content-color;
		margin-top: -5px;
	}
}
.tab-content
{
	background-color: white;
	padding:20px;
	margin-bottom: 20px;
	border:1px solid #E6EAEE;
	border-radius: 4px;
	box-shadow: none !important;
}