.panel-dark
{
	background-color: #222C3C;
	border-radius: 4px;
}	
.sort-panel
{
	border: 0px;
	.search-form-box
	{
		padding: 5px;
		
		form 
		{
			.radio-inline
			{
				font-size: @primary-font-size;
				color: @default-to-color;
				font-weight: 400;
				margin-top: -13px;
				font-family: @source-sans!important;
				letter-spacing: 0.6px;
				text-transform: capitalize;
				.select
				{
					margin-top: 0px;
				}
			}
			.sort-form 
			{
				margin-bottom: 10px;
				.sort-radio 
				{
					margin: 0px;
				}
				.sort-check 
				{
					margin: 0px;
					.sort-select 
					{
						font-size: 14px;
						color: #FFFFFF;
						text-transform: none;
						font-weight: 400;
					}
				}
				.sort-radio 
				{
					margin: 0px;
					.sort-check 
					{
						font-size: 14px;
						margin-right: 17px;
						color: #FFFFFF;
						text-transform: none;
						font-weight: 400;
					}
				}

			}
			.form-group 
			{
				
				.selection
				{
					margin-bottom: 0px;
				}
				.search-group 
				{
					margin-bottom: 1px;
					font-size: 14px;
					font-family: @source-sans!important;
					.form-icon 
					{
						border-radius: 4px;
						height: 36px;
						border-color: #FFFFFF;
						border-top-right-radius: 0px;
						border-bottom-right-radius: 0px;
						background: #FFFFFF;
						border-right: 0px;
						i 
						{
							color: #BBC5D5;
						}
					}
					.search-form 
					{
						height: 36px;
						border-color: #FFFFFF;
						border-radius: 4px;
						border-top-left-radius: 0px;
						border-bottom-left-radius: 0px;
						padding: 0px;
						border-left: 0px;
						background: #FFFFFF;
					}
				}
			}
		}
		.select-option
		{
			color: @default-to-color;
			font-weight: 400;
			font-size: 14px;
			font-family: @source-sans!important;
			text-transform: capitalize;
			letter-spacing: 0.6px;
			cursor: pointer;
			input[type="checkbox"]
			{
				margin-right: 9px;
			}
		}
		
		.btn-primary
		{
			height: 36px;
			width: 100%;
			margin-top:3px;
			color: @default-to-color;
			font-family: @source-sans;
			font-weight: 600;
			
			i 
			{
				font-size: 16px;
				margin-right: 7px;
				color: @default-to-color;
			}
			span 
			{
				color: @default-to-color;
				font-weight: 600;
				letter-spacing: 0.6px;
				font-family: @source-sans;
			}
		}
	}	
}

// Revenue kit //
.revenue-panel
{
	padding-bottom: 0px;
}
.revenue-block
{
	padding-right: 14px;
	padding-left: 14px;
	background-color: @default-to-color ;
	.monthly-revenue
	{
		position: relative;
		.revenue-head 
		{
			color: @primary-content-color;
			font-size: 24px;
			font-family: @source-sans;
			font-weight: 400;
			margin-top: 10px;
		}
		.btn-month
		{
			margin-top: 7px;
			background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
			border: 1px solid #CED0DA;
			border-radius: 4px;
			text-transform: capitalize;
			font-size: 14px;
			font-family: @source-sans;
			padding: 6px 8px 7px 16px;
			font-weight: 400;
			margin-right: 3px;
			color: @primary-content-color;
			span 
			{
				margin-left: 8px;
				i 
				{
					color: #A8AAB7;
				}
			}
		}
		.month-dropdown
		{
			
			right:0px;
			top: 40px;
			left: auto;
		}
	}
	.revenue-spending
	{
		.revenue
		{
			font-size: @primary-font-size;
			color: @secondary-content-color;
			text-transform: capitalize;
			font-family: @source-sans;
			letter-spacing: 0.6px;
			font-weight: 400;
			margin-top: 15px;
			.income 
			{
				font-size: 18px;
				font-family: @source-sans;
				color: @primary-content-color;
				font-weight: 400;
				display: block;
			}
		}
	}
	
		
	
	.revenue_progress
	{
		margin-top: 8px;
		height: 36px;
		border-radius: 4px;
		background-image: linear-gradient(-1deg, #445C83 2%, #5684AF 98%);
		.progress-bar
		{
			background-image: linear-gradient(-1deg, #2BB415 2%, #5AD946 98%);
			border-radius: 4px 0px 0px 4px;
		}
	}
	.budget-spending
	{
		border-top: 1px solid @table-border-color;
		padding-top: 14px;
		.budget
		{
			font-size: @primary-font-size;
			font-family: @source-sans;
			color: @secondary-content-color;
			text-transform: capitalize;
			letter-spacing: 0.6px;
			font-weight: 400;

			.income
			{
				font-size: 18px;
				display: block;
				color: @primary-content-color;
				font-weight: 400;
				font-family: @source-sans;
			}
		}
	}
	
	
	.budget_progress
	{
		margin-top: 8px;
		height: 36px;
		border-radius: 4px;
		background: #E2E7EE;
		.budget-bar
		{
			background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
			border-radius: 4px 0px 0px 4px;
		}
	}
}
.last-month-income
{
	background-color: #FAFBFC;
	border-top: 1px solid @table-border-color;
}
.previous-month-details
{
	padding: 13px 15px 20px 15px;
	.previous-month
	{
		font-size: @primary-font-size;
		color: @secondary-content-color;
		text-transform: capitalize;
		font-family: @source-sans;
		letter-spacing: 0.6px;
		font-weight: 400;
		margin-top: 4px;
	}
	.previous-month-income
	{
		font-family: @source-sans;
		margin-top: 5px;
		display: flex;
		margin-left: 0px;
    	justify-content: space-between;

		.month_income
		{
			font-size: @primary-font-size;
			color: @primary-content-color;
			text-transform: capitalize;
			letter-spacing: 0.6px;
			font-family: @source-sans;
			font-weight: 400;
		}
		.income-details
		{
			font-size: @primary-font-size;
			color: @secondary-content-color;
			text-transform: capitalize;
			letter-spacing: 0.6px;
			font-weight: 400;
			text-align: center;
			// padding-left: 50px;
			// padding-right: 45px;
		}
	}
	.exmp
	{
		width: 100%;
	}
	.last-month-progress
	{
		height: 8px;
		border-radius: 4px;
		margin-bottom: 10px;
		margin-top: -6px;
		background-image: linear-gradient(-1deg, #445C83 2%, #5684AF 98%);
		.previous-month-bar
		{
			background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
			border-radius: 4px 0px 0px 4px;
		}
	}
	.last-month-budget-progress
	{
		height: 8px;
		border-radius: 4px;
		margin-bottom: 10px;
		background: #E2E7EE;
		margin-top: -4px;
		.previous-month-bar
		{
			background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
			border-radius: 4px 0px 0px 4px;
		}
	}
}
//Product Sales
.product-sales-panel
{
	padding: 27px 30px 30px 30px;
	border-top: 3px solid #1A91EB;
	.product-sales
	{
		.sales-header
		{
			color: @primary-content-color;
			font-size: 24px;
			font-family: @source-sans;
			font-weight: 300;
			margin-top: -5px;
			margin-bottom: 22px;
			text-transform: capitalize;
		}
		.product-sales-list
		{
			border-bottom: 1px solid @table-border-color;
			margin-top: 11px;
			li 
			{
				margin-top: -11px;
				&:last-child
				{
					.progress
					{
						margin-bottom: 15px;
					}
				}
			}
			.product-name
			{
				font-size: @primary-font-size;
				font-weight: 600;
				text-transform: capitalize;
				font-family: @source-sans;
				margin-bottom: 7px;
				color: @primary-content-color;
				.circle-primary
				{
					height: 10px;
					width: 10px;
					float: left;
					border:2px solid #1AA6D6;
					border-radius: 50%;
					margin-top: 2px;
				}
				.circle-purple
				{
					height: 10px;
					width: 10px;
					float: left;
					border:2px solid #8E76E4;
					border-radius: 50%;
					margin-top: 2px;
				}
				.circle-success
				{
					height: 10px;
					width: 10px;
					float: left;
					border:2px solid #49C834;
					border-radius: 50%;
					margin-top: 2px;
				}
				.circle-yellow
				{
					height: 10px;
					width: 10px;
					float: left;
					border:2px solid #FAC942;
					border-radius: 50%;
					margin-top: 2px;
				}
				span 
				{
					margin-left: 9px;
				}
			}
			.sales-count
			{
				color: @secondary-content-color;
				font-size: @primary-font-size;
				font-weight: 600;
				font-family: @source-sans;
				margin-top: 10px;
			}

			.progress
			{
				height: 18px;
				background: #E2E7EE;
				border-radius: 10px;
				margin-top: 3px;
				.progress-primary
				{
					background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
					border-radius: 10px;
				}
				.progress-purple
				{
					background-image: linear-gradient(-180deg, #9D90E4 2%, #8261E6 98%);
					border-radius: 10px;
				}
				.progress-success
				{
					background-image: linear-gradient(-1deg, #2BB415 2%, #5AD946 98%);
					border-radius: 10px;
				}
				.progress-yellow
				{
					background-image: linear-gradient(-1deg, #FDC018 2%, #F8CF5D 98%);
					border-radius: 10px;
				}
			}
		}
		.sales-range
		{
			// text-align: justify;
			display: flex;
    		justify-content: space-around;
    		margin-left: -15px;
    		margin-right: -15px;
    		margin-bottom: -5px;
			li 
			{
				text-align: justify;
				color: @primary-content-color ;
				font-size: 12px;
				font-family: @source-sans;
				font-weight: 400;
			}
		}
	}
}

// Customers.kit

.election-card
{	
	position: relative;
	.customer-section 
	{
		margin-bottom: 0px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		.customers-panel
		{
			padding: 5px 20px 6px 20px;
			.customer-block
			{
				.customer-head
				{
					font-size: 20px;
					color: @primary-content-color;
					font-weight: 400;
					margin-top: -9px;
					font-family: @source-sans;
					letter-spacing: 0.6px;
				}
				.customers-count
				{
					font-size: 30px;
					font-family: @source-sans;
					color: @primary-content-color;
					font-weight: 400;
					margin-top: -13px;
				}
				.customers-percentage
				{
					color: #3FC529;
					display: block;
					font-size: @primary-font-size;
					font-weight: 400;
					margin-bottom: -27px;
					font-family: @source-sans;
				}
				.customers-list
				{
					display: flex;
		    		justify-content: space-around;
		    		margin-bottom: 0px;
		    		margin-left: -20px;
		    		margin-right: -20px;
		    		margin-top: 1px;
					li 
					{
						text-align: justify;
						.circle-new
						{
							border:2px solid #1AA6D6;
							height: 10px;
							width: 10px;
							border-radius: 50%;
							float: left;
							margin-top: 5px;
							margin-right: 5px;
						}
						.circle-return
						{
							border:2px solid #40557D;
							height: 10px;
							width: 10px;
							border-radius: 50%;
							float: left;
							margin-top: 5px;
							margin-right: 5px;
						}
						.circle-referal
						{
							border:2px solid #FAC942;
							height: 10px;
							width: 10px;
							border-radius: 50%;
							float: left;
							margin-top: 5px;
							margin-right: 5px;
						}
						span 
						{
							font-size: @primary-font-size;
							font-weight: 600;
							color: @primary-content-color;
						}
					}
				}
			}
		}
	}
		
	.customer-referrals
	{
		background-color: #FAFBFC;
		margin-bottom: 20px;
		padding: 11px 15px 15px 15px;
		border: 1px solid #e6eaee;
	    border-top-width: 0px;
	    position: relative;
	    .referral-heading
	    {
	    	margin-top: 0px;
	    	font-size: 15px;
	    	margin-bottom: 0px;
	    	b 
	    	{
	    		text-transform: capitalize;
	    	}
	    }
	    .election-date
	    {
	    	color: @secondary-content-color;
	    	font-size: 13px;
	    	line-height: 23px; 
	    	i 
	    	{
				margin-right: 4px;
	    	}
	    }
	    .btn-default
	    {
	    	padding: 1px 6px; 
	    	i 
	    	{
				font-size: 12px;
	    	}
	    }
	    .btn-left,.btn-right
	    {	
	    	position: absolute;
			margin-bottom: -12px;
			bottom: 50%;
	    }
	    .btn-left
	    {
	    	left: 15px;
	    }
	    .btn-right
	    {
	    	right: 15px;
	    }
	    @media(min-width: 991px) and (max-width: 1200px)
	    {
			.btn-left
			{
				margin-left: -12px;
			}
			.btn-right
			{
				margin-right: -12px;
			}
	    }
	}
	.electioncard-loader
	{
		
		position: absolute;
		top: 0px;
		left: 0px;
		text-align: center;
		display: none;
		width: 100%;
		height: 100%;
		.fa-spinner
		{	
			position: absolute;
			top: 50%;
			margin-left: -17px;
			margin-top: -17px;
			font-size: 35px;
			animation: spin 2s linear infinite;
			color: #1991EB;
		}
		@keyframes spin {
		    0% { transform: rotate(0deg); }
		    100% { transform: rotate(360deg); }
		}
	}
}
.election-card.loading
{
	.electioncard-loader
	{
		display: block;
	}
	.customer-referrals
	{
		opacity: 0.4;
	}
	.customer-section 
	{
		opacity: 0.4;
	}
}


//spending.kit 
.spending-panel
{
	padding: 30px;
	
	.spending-block
	{
		.spending-head
		{
			font-size: 24px;
			color: @primary-content-color;
			font-weight: 400;
			margin-top: -6px;
			font-family: @source-sans;
			letter-spacing: 0.6px;
		}
		.spenders-count
		{
			font-size: 24px;
			font-family: @source-sans;
			color: #1A91EB;
			font-weight: 400;
			margin-top: -9px;
		}
		#char
		{
			margin-top: -21px;
		}
		.spending-progress-table
		{
			margin-top: 10px;
			margin-bottom: -11px;
			
			.spend-for
			{
				font-size: 16px;
				color: @primary-content-color ;
				font-weight: 600;
				padding-top: 7px;
				font-family: @source-sans;
				text-transform: capitalize;
				.food-share
				{
					border:2px solid #40557D;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					float: left;
					margin-top: 5px;
					margin-right: 5px;
				}
				.family-share
				{
					border:2px solid #FAC942;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					float: left;
					margin-top: 5px;
					margin-right: 5px;
				}
				.company-share
				{
					border:2px solid #1AA6D6;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					float: left;
					margin-top: 5px;
					margin-right: 5px;
				}
				.stocks-share
				{
					border:2px solid #F0B499;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					float: left;
					margin-top: 5px;
					margin-right: 5px;
				}
				.travel-share
				{
					border:2px solid #72C4B9;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					float: left;
					margin-top: 5px;
					margin-right: 5px;
				}
				.other-share
				{
					border:2px solid #289DF5;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					float: left;
					margin-top: 5px;
					margin-right: 5px;
				}
			}
			.spent-percentage
			{
				color: @secondary-content-color;
				font-size: @primary-font-size;
				font-family: @source-sans;
				margin-left: 13px;
				font-weight: 400;
				float: left;
				margin-bottom: 7px;
			}
		
		}
	}
}	

// Daily sales.kit
.daily-sales-section 
{
	margin-bottom: 0px;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	.sales-panel
	{
		padding: 29px 29px 30px 29px;
		.sales-block 
		{
			.sales-head
			{
				font-size: 20px;
				color: @primary-content-color;
				font-weight: 400;
				margin-top: -9px;
				font-family: @source-sans;
				letter-spacing: 0.6px;
			}
			.sales-count
			{
				font-size: 30px;
				font-family: @source-sans;
				color: @primary-content-color;
				font-weight: 400;
				margin-top: -13px;
			}
			.sales-percentage
			{
				color: #3FC529;
				display: block;
				font-size: @primary-font-size;
				font-weight: 400;
				margin-bottom: -27px;
				font-family: @source-sans;
			}
			.daily-sales-progress
			{
				.progress-bar-vertical 
				{
				  width: 3px;
				  min-height: 100px;
				  display: flex;
				  align-items: flex-end;
				  margin-right: 5px;
				  background: transparent;
				  border-bottom-right-radius:0px;
				  border-bottom-left-radius:0px;
				  float: left;
				}

				.progress-bar-vertical .progress-bar 
				{
				  width: 100%;
				  border-top-right-radius:10px;
				  border-top-left-radius:10px;
				  height: 0;
				  -webkit-transition: height 0.6s ease;
				  -o-transition: height 0.6s ease;
				  transition: height 0.6s ease;
				}
			}
		}
	}
}		


















































