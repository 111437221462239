.wrapper {
  position: relative;
}
.wrapper .content-wrapper {
  margin-left: 250px;
  padding-bottom: 20px;
}
.wrapper .left-sidebar {
  position: absolute;
  width: 250px;
  min-height: 1200px !important;
  top: 0;
  bottom: 0;
  /* any large number will do */
  display: block;
  background: #273142;
  border-widt: 0px;
  box-shadow: inset -1px 0px 0px 0px #313D4F;
  padding-bottom: 30px;
}
.wrapper .left-sidebar .left-sidebar-brand {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  height: 70px;
  width: 100%;
  text-decoration: none;
  display: block;
  margin-bottom: 30px;
  color: white;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 64px;
  text-align: center;
}
.wrapper .left-sidebar .left-sidebar-menu {
  padding-left: 0px;
}
.wrapper .left-sidebar .left-sidebar-menu li {
  list-style: none;
  padding-left: 30px;
  padding-right: 30px;
}
.wrapper .left-sidebar .left-sidebar-menu li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  letter-spacing: 0.3px;
  font-size: 14px;
  color: #B7C0CD;
  display: block;
  text-transform: capitalize;
  line-height: 36px;
  text-decoration: none;
}
.wrapper .left-sidebar .left-sidebar-menu li a .badge {
  float: right;
  margin-top: 6px;
}
.wrapper .left-sidebar .left-sidebar-menu li a .menu-icon {
  float: right !important;
  color: #475364;
  font-size: 14px;
  line-height: 32px;
}
.wrapper .left-sidebar .left-sidebar-menu li:hover,
.wrapper .left-sidebar .left-sidebar-menu li.active {
  background-color: #313D4F;
}
.wrapper .left-sidebar .left-sidebar-menu li:hover > a,
.wrapper .left-sidebar .left-sidebar-menu li.active > a {
  color: #FFF;
}
.wrapper .left-sidebar .left-sidebar-menu li:hover.has-submenu,
.wrapper .left-sidebar .left-sidebar-menu li.active.has-submenu {
  background-color: transparent;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header:hover {
  background-color: transparent;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  display: inline-block;
  color: #5B6779;
  text-transform: uppercase;
  font-size: 12px;
  cursor: default;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header a.menu-icon {
  float: right !important;
  color: #475364;
  font-size: 16px;
  cursor: pointer;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header a.menu-icon:hover {
  color: white;
}
.wrapper .left-sidebar .seperator {
  /* Divider: */
  background: #354052;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 26px;
}
.wrapper .left-sidebar li.has-submenu > ul {
  display: none;
}
.wrapper .left-sidebar li.has-submenu.active > ul {
  display: block;
}
.wrapper .left-sidebar li.has-submenu:hover {
  background: transparent;
}
.wrapper .left-sidebar li.has-submenu ul {
  padding-left: 0px;
}
.wrapper .left-sidebar li.has-submenu ul > li {
  padding-left: 10px;
}
.wrapper .left-sidebar li.has-submenu ul > li > a {
  font-size: 12px;
}
@media (max-width: 1024px) {
  .wrapper .content-wrapper {
    margin-left: 0px;
    padding-bottom: 20px;
  }
  .wrapper .left-sidebar {
    position: absolute;
    width: 250px;
    margin-left: -250px;
    left: -250px;
  }
}
body {
  background: #EFF3F6;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  letter-spacing: 0.1px;
}
h1 {
  font-size: 32px;
  line-height: 44px !important;
}
h2 {
  font-size: 22px;
  line-height: 34px !important;
}
h3 {
  font-size: 20px;
  line-height: 28px !important;
}
h4 {
  font-size: 16px;
  line-height: 28px !important;
}
h4 {
  font-size: 14px;
  line-height: 24px !important;
}
p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #71787E;
  line-height: 24px;
}
.striked {
  text-decoration: line-through;
}
.no-margin {
  margin: 0px !important;
}
a {
  color: #00AEEF;
}
.form-group {
  margin-bottom: 18px;
}
.input-group {
  margin-bottom: 10px;
}
label,
.conrol-label {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #7F8FA4;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 15px;
}
.radio.disabled {
  color: #7F8FA4;
  opacity: 0.6;
}
.text-muted {
  color: #7F8FA4;
  font-size: 12px;
}
.input-group .input-group-addon {
  background-image: -webkit-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: -o-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  border-color: #DFE3E9;
  color: #7F8FA4;
}
.input-group.facebook-input-group .input-group-addon {
  background-image: -webkit-linear-gradient(271deg, #137ECE 2%, #288BD5 98%);
  background-image: -o-linear-gradient(271deg, #137ECE 2%, #288BD5 98%);
  background-image: linear-gradient(1deg, #137ECE 2%, #288BD5 98%);
  color: white;
  border-color: #137ECE;
}
.input-group.facebook-input-group .form-control:focus {
  border-left-color: transparent;
}
.input-group.twitter-input-group .input-group-addon {
  background-image: -webkit-linear-gradient(#2EA1F8 0%, #1A91EC 98%);
  background-image: -o-linear-gradient(#2EA1F8 0%, #1A91EC 98%);
  background-image: linear-gradient(#2EA1F8 0%, #1A91EC 98%);
  border-color: #2EA1F8;
  color: white;
}
.form-control,
textarea {
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  background: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #9A9FA8;
  line-height: 19px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 12px;
  padding-right: 12px;
}
.form-control:focus,
textarea:focus {
  border-color: #8D98A9;
  outline: 0;
  color: #354052;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.form-control::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #c3c6cb;
}
.form-control:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #c3c6cb;
}
.form-control::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #c3c6cb;
}
.form-control:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #c3c6cb;
}
.has-success .form-control {
  border-color: #1BB934;
  color: #354052;
  background: url(../images/icons/check.png);
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 18px;
}
.has-success .form-control:focus {
  border-color: #1BB934;
  color: #354052;
}
.has-error .form-control {
  border-color: #ED1C24;
  color: #354052;
  background: url(../images/icons/cross.png);
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 18px;
}
.has-error .form-control:focus {
  border-color: #ED1C24;
  color: #354052;
}
.has-warning .form-control {
  border-color: #FECF39;
  color: #354052;
  background: url(../images/icons/warning.png);
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 18px;
}
.has-warning .form-control:focus {
  border-color: #FECF39;
  color: #354052;
}
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: -webkit-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: -o-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  border: 1px solid #DFE3E9;
  border-radius: 4px;
}
textarea.form-control {
  padding-top: 10px;
  resize: none;
}
.btn {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: capitalize;
  outline: none !important;
  border-radius: 4px;
}
.btn img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.btn-success {
  background-color: #34AA44;
  background-image: -webkit-linear-gradient(-1deg, #39B54A 2%, #34AA44 98%);
  background-image: -o-linear-gradient(-1deg, #39B54A 2%, #34AA44 98%);
  background-image: linear-gradient(-1deg, #39B54A 2%, #34AA44 98%);
  border: 1px solid #33a242;
}
.btn-success span {
  color: #71d37e;
  margin-right: 8px;
}
.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  background-image: linear-gradient(-1deg, #2d8e3a 2%, #288334 98%);
  border-color: #309a3f;
}
.btn-default {
  background-color: #FFFFFF;
  background-image: -webkit-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: -o-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  border: 1px solid #e2e7ed;
  color: #7F8FA4;
  border-color: #CED0DA;
}
.btn-default span {
  color: #ffffff;
  margin-right: 8px;
}
.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  background-image: linear-gradient(-1deg, #d2d9e4 2%, #e6e6e6 98%);
  border-color: #dce1e9;
}
.btn-default:hover {
  color: #7F8FA4;
  border-color: #bfc2cf;
}
.btn-default span {
  color: #bbc3ce;
}
.btn-primary {
  background-color: #2DA1F8;
  background-image: -webkit-linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  background-image: -o-linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border: 1px solid #1383d8;
  border-color: #0089bc;
}
.btn-primary span {
  color: #90cdfb;
  margin-right: 8px;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-image: linear-gradient(-1deg, #1175c0 2%, #0889ea 98%);
  border-color: #127ece;
}
.btn-info {
  background-color: #94E1F1;
  background-image: -webkit-linear-gradient(-1deg, #5bc0de 2%, #94E1F1 98%);
  background-image: -o-linear-gradient(-1deg, #5bc0de 2%, #94E1F1 98%);
  background-image: linear-gradient(-1deg, #5bc0de 2%, #94E1F1 98%);
  border: 1px solid #46b8da;
}
.btn-info span {
  color: #eefafd;
  margin-right: 8px;
}
.btn-info:hover,
.btn-info:active,
.btn-info:focus {
  background-image: linear-gradient(-1deg, #31b0d5 2%, #67d4eb 98%);
  border-color: #3db5d8;
}
.btn-danger {
  background-color: #E84327;
  background-image: -webkit-linear-gradient(-1deg, #C8270C 2%, #E84327 98%);
  background-image: -o-linear-gradient(-1deg, #C8270C 2%, #E84327 98%);
  background-image: linear-gradient(-1deg, #C8270C 2%, #E84327 98%);
  border: 1px solid #b0220b;
}
.btn-danger span {
  color: #f29383;
  margin-right: 8px;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
  background-image: linear-gradient(-1deg, #981e09 2%, #c72f15 98%);
  border-color: #a6200a;
}
.btn-warning {
  background-color: #F8CF5D;
  background-image: -webkit-linear-gradient(-1deg, #FDC018 2%, #F8CF5D 98%);
  background-image: -o-linear-gradient(-1deg, #FDC018 2%, #F8CF5D 98%);
  background-image: linear-gradient(-1deg, #FDC018 2%, #F8CF5D 98%);
  border: 1px solid #f9b702;
}
.btn-warning span {
  color: #fcecbf;
  margin-right: 8px;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  background-image: linear-gradient(-1deg, #e0a502 2%, #f6c12c 98%);
  border-color: #efb002;
}
.badge {
  min-width: 22px;
  border-radius: 31px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  padding: 5px 8px;
  font-size: 13px;
  color: #FFFFFF;
}
.badge.badge-primary {
  background-image: -webkit-linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  background-image: -o-linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
}
.badge.badge-danger {
  background-image: -webkit-linear-gradient(-1deg, #C8270C 2%, #F45438 98%);
  background-image: -o-linear-gradient(-1deg, #C8270C 2%, #F45438 98%);
  background-image: linear-gradient(-1deg, #C8270C 2%, #F45438 98%);
}
.navbar-brand {
  padding: 13px 15px;
}
.navbar-default {
  box-shadow: 0px -1px 0px 0px #E6EAEE;
  border-width: 0px;
  border-radius: 0px;
}
.navbar-default .navbar-brand {
  padding: 13px 10px 13px 30px;
}
.navbar-default > .navbar-nav > a {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: capitalize;
}
.navbar-default > .navbar-nav > a .fa-angle-down {
  margin-left: 11px;
}
.navbar-default > .navbar-nav .active > a {
  background-color: transparent !important;
}
.navbar-default.navbar-blue {
  background-image: linear-gradient(-1deg, #2EA1F8 2%, #1990EA 100%);
}
.navbar-default.navbar-blue .navbar-nav.navbar-left a {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #FFFFFF;
}
.navbar-default.navbar-blue .navbar-nav.navbar-left .dropdown-menu a {
  color: #333333;
}
.navbar-right .position-icons-list {
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 21px;
  border-right: 1px solid #2087D5;
}
.navbar-right .position-icons-list li img {
  width: 14px;
  height: 14px;
}
.navbar-right .admin-settings-list {
  padding-left: 20px;
}
.navbar-right .admin-settings-list li {
  padding-left: 10px;
  padding-right: 10px;
}
.navbar-right .admin-settings-list li i {
  color: #fff;
  font-size: 16px;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
}
.dropdown.user-image {
  padding-top: 17px;
}
.dropdown.user-image .dropdown-toggle {
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
  color: transparent;
  margin-left: 10px;
  padding-left: 10px;
}
.dropdown.user-image .dropdown-toggle i {
  color: #86CAFF;
  border-radius: 83px;
  margin-left: 8px;
  padding-right: 5px;
}
.dropdown.user-image.with-form {
  margin-right: 10px;
  margin-left: 5px;
}
.navbar-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #354052;
  text-transform: capitalize;
  margin-top: 15px;
}
.navbar-heading .sub-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #7F8FA4;
  border-left: 1px solid #DFE3E9;
  text-transform: capitalize;
  padding-left: 13px;
  margin-left: 9px;
}
.navbar-heading.dark-background {
  color: #fff;
}
.navbar-heading.dark-background .sub-text {
  border-left: 1px solid #7F8FA4;
}
.play-list {
  margin-top: 17px;
}
.play-list li {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-left: 1px solid #E5E9EF;
}
.play-list li:first-child {
  border-left: 0px;
  padding-left: 20px;
}
.play-list li .btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0px;
  border: 2px solid #BFC7D8;
}
.play-list li .btn i {
  color: #BFC7D8;
  border-radius: 1px;
}
.navbar-nav li {
  position: relative;
}
.navbar-nav li .activity-count {
  position: absolute;
  background: #FF7800;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  top: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: #FFFFFF;
  right: 2px;
}
.navbar-nav .dropdown-menu {
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.navbar-form {
  margin-top: 16px;
}
.navbar-form .input-group .form-control {
  border-right-width: 0px;
  height: 36px;
  margin-left: 7px;
}
.navbar-form .input-group .input-group-btn .btn,
.navbar-form .input-group .input-group-btn a {
  background: none;
  border-color: #DFE3E9;
  color: #A1A7AF;
  border-left-width: 0px;
  height: 36px;
}
.navbar-form button.btn {
  padding: 7px 12px;
  margin-left: 7px;
}
.navbar li.button {
  padding-top: 18px;
}
.navbar li.button a.btn {
  padding-top: 3px;
  padding-bottom: 5px;
}
.navbar li.button a.btn i.fa {
  font-size: 16px !important;
}
.navbar-icons > li > a {
  color: #BFC7D8 !important;
  font-size: 24px !important;
}
.navbar-primary {
  background-image: -webkit-linear-gradient(-1deg, #1990EA 2%, #2EA1F8 98%);
  background-image: -o-linear-gradient(-1deg, #1990EA 2%, #2EA1F8 98%);
  background-image: linear-gradient(-1deg, #1990EA 2%, #2EA1F8 98%);
}
.navbar-primary .navbar-nav > li > a {
  color: #FFFFFF;
  font-size: 16px !important;
}
.navbar-dark {
  background: #273142;
}
.navbar-dark .navbar-nav > li > a {
  color: #7F8FA4 !important;
  text-transform: capitalize;
  font-weight: 600;
}
.navbar-dark .navbar-form .form-control {
  background: #222C3C;
  border: 1px solid #313D4F;
  border-radius: 4px;
  color: #7F8FA4;
  border-right-width: 0px;
}
.navbar-dark .navbar-form .input-group-btn .btn,
.navbar-dark .navbar-form .input-group-btn a {
  background: #222C3C;
  border-color: #313D4F !important;
  color: #7F8FA4 !important;
  border-left-width: 0px;
}
.navbar-dark .dropdown.user-image .dropdown-toggle i {
  color: #A1A7AF;
}
.panel-default {
  background: #FFFFFF;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-default .panel-heading {
  background-image: -webkit-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: -o-linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  background-image: linear-gradient(-1deg, #F2F4F7 2%, #FFFFFF 98%);
  border-radius: 4px 4px 0px 0px !important;
  height: 50px;
  color: #354052;
  border: 1px solid #E6EAEE;
  border-radius: 0px 0px 4px 4px;
}
.panel-default .panel-heading .panel-title {
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  color: #354052;
  font-weight: 600;
}
.panel-default .panel-heading .panel-title a {
  color: #00AEEF;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none !important;
}
.panel-default .panel-heading .panel-title a:hover {
  color: #009bd6;
}
.panel-default .panel-heading.no-bg {
  background: none;
  border-color: transparent;
}
.panel-default .panel-body.nopadding-top-bottom {
  padding: 0px 15px;
}
.panel-default .panel-body.nopadding,
.panel-default .panel-heading.nopadding {
  padding: 0px;
}
.panel-default .panel-body.bg-grey {
  background-color: #EFF3F6;
}
.nav-tabs {
  border-bottom-width: 0px;
}
.nav-tabs li a {
  background-image: -webkit-linear-gradient(-1deg, #F4F7FA 2%, #FFFFFF 98%);
  background-image: -o-linear-gradient(-1deg, #F4F7FA 2%, #FFFFFF 98%);
  background-image: linear-gradient(-1deg, #F4F7FA 2%, #FFFFFF 98%);
  border: 1px solid #E6EAEE;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #354052;
  font-size: 14px;
  margin-bottom: -1px;
  text-transform: capitalize;
  padding-left: 30px;
  padding-right: 30px;
}
.nav-tabs li a:hover {
  border-bottom-width: 0px;
}
.nav-tabs li a:hover,
.nav-tabs li a:active,
.nav-tabs li a:focus {
  background-image: linear-gradient(-1deg, #edf2f7 2%, #fafafa 98%);
}
.nav-tabs li.active a {
  border-top: 1px solid #E6EAEE !important;
  border-left: 1px solid #E6EAEE !important;
  border-right: 1px solid #E6EAEE !important;
  background-image: none;
  line-height: 26px;
  color: #354052;
  margin-top: -5px;
}
.tab-content {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  box-shadow: none !important;
}
.panel > .panel-body + .table {
  border-top: 1px solid #E6EAEE;
}
.table {
  background-color: white;
}
.table > thead > tr > th {
  text-transform: capitalize;
  color: #7F8FA4;
  background-color: #F5F8FA;
  border-top: 1px solid #E6EAEE;
  border-bottom: 1px solid #E6EAEE;
}
.table > thead > tr > th .sort {
  float: right;
  margin-right: 10px;
}
.table > thead > tr > th:first-child {
  padding-left: 20px;
}
.table > tbody > tr > td {
  color: #354052;
  font-weight: 600;
  vertical-align: middle;
  border-bottom: 1px solid #E6EAEE;
}
.table > tbody > tr > td .text-muted {
  font-weight: 400;
}
.table > tbody > tr > td:first-child {
  padding-left: 20px;
}
.table-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  color: #354052;
}
.table-heading .sub-text {
  color: #7F8FA4;
  font-size: 14px;
  margin-left: 8px;
  border-left: 1px solid #E6EAEE;
  padding-left: 8px;
}
.modal-content {
  margin-top: 170px;
}
.modal-backdrop {
  background-color: #131820;
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1039 !important;
}
.modal-backdrop.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.modal-body label,
.modal-body select {
  color: #354052;
  text-transform: capitalize;
}
.modal-body .seperator {
  background: #E6EAEE;
  height: 1px;
  margin-bottom: 16px;
}
.modal-footer {
  background: #F0F3F8;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center !important;
}
.user-successfully-added .modal-body {
  padding: 0px;
}
.user-successfully-added .modal-body .user-info {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  height: 280px;
  display: block;
  width: 100%;
  color: white;
  padding-top: 53px;
  text-align: center;
  background-image: -webkit-linear-gradient(271deg, #2EA1F8 2%, #1990EA 100%);
  background-image: -o-linear-gradient(271deg, #2EA1F8 2%, #1990EA 100%);
  background-image: linear-gradient(1deg, #2EA1F8 2%, #1990EA 100%);
}
.user-successfully-added .modal-body .user-info .close-modal i {
  color: #70BBFB;
  position: absolute;
  top: 30px;
  right: 30px;
}
.user-successfully-added .modal-body .user-info img.user-avatar {
  width: 120px;
  height: 120px;
  margin: 0px auto;
  display: block;
  margin-bottom: 18px;
}
.user-successfully-added .modal-body .user-info .user-name {
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 600;
  text-transform: capitalize;
}
.user-successfully-added .modal-body .user-info .user-designation {
  font-size: 14px;
  color: #D1DDED;
  font-weight: 300;
}
.user-successfully-added .modal-body .user-message {
  padding: 25px 50px;
  text-align: center;
}
.user-successfully-added .modal-body .user-message h3 {
  font-size: 18px;
  color: #7F8FA4;
  font-weight: 600;
}
.user-successfully-added .modal-body .user-message .user-message-description {
  font-size: 14px;
  color: #354052;
  line-height: 20px;
  font-weight: 300;
}
.feedback-modal .modal-body {
  padding: 0px;
}
.feedback-modal .modal-body .user-info {
  text-align: center;
  background-image: -webkit-linear-gradient(#2EA1F8 0%, #1990EA 99%);
  background-image: -o-linear-gradient(#2EA1F8 0%, #1990EA 99%);
  background-image: linear-gradient(#2EA1F8 0%, #1990EA 99%);
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
  height: 110px;
  font-weight: 600;
}
.feedback-modal .modal-body .user-info .user-avatar {
  width: 90px;
  height: 90px;
  border: 3px solid white;
  border-radius: 50%;
  margin: 0px auto;
  display: block;
  margin-top: -55px;
  position: absolute;
  left: 0;
  right: 0;
}
.feedback-modal .modal-body .user-info .support-heading {
  padding-top: 50px;
  font-size: 18px;
  color: #FFFFFF;
  text-transform: capitalize;
}
.feedback-modal .modal-body .user-info .support-heading .close-modal {
  position: absolute;
  top: 17px;
  right: 17px;
  color: #70BBFB;
}
.feedback-modal .modal-body .user-info .user-with {
  opacity: 0.8;
  font-size: 14px;
  color: #FFFFFF;
}
.feedback-modal .modal-body .feedback-form {
  padding: 20px;
}
.feedback-modal .modal-body .feedback-form i.fa-info-circle {
  margin-left: 10px;
  font-size: 16px;
  color: #D7D7D7;
}
.feedback-modal .modal-body .feedback-form .form-heading {
  display: block;
  text-align: center;
  font-size: 14px;
  color: #414F64;
  font-weight: 600;
  margin-bottom: 15px;
}
.feedback-modal .modal-body .feedback-form .feedback-rate {
  text-align: center;
  margin-bottom: 20px;
}
.feedback-modal .modal-body .feedback-form .feedback-rate li {
  margin-right: 15px;
}
.feedback-modal .modal-body .feedback-form .feedback-rate li a {
  font-size: 14px;
  color: #8E939D;
  font-weight: 600;
  text-decoration: none;
  width: 35px;
  display: inline-block;
  height: 35px;
  text-align: center;
  border: 2px solid #D7D7D7;
  border-radius: 50%;
  line-height: 31px;
}
.feedback-modal .modal-body .feedback-form .feedback-rate li.active a,
.feedback-modal .modal-body .feedback-form .feedback-rate li:hover a {
  color: white;
  background: #36AF47;
  border-color: transparent;
}
.feedback-modal .modal-body .feedback-form textarea {
  font-size: 14px;
  color: #354052;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 19px;
}
.feedback-modal .modal-footer a {
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 600;
}
.billing-modal .modal-body {
  padding: 0px;
}
.billing-modal .modal-body .tab-navigation {
  background-image: -webkit-linear-gradient(271deg, #2EA1F8 2%, #1990EA 100%);
  background-image: -o-linear-gradient(271deg, #2EA1F8 2%, #1990EA 100%);
  background-image: linear-gradient(1deg, #2EA1F8 2%, #1990EA 100%);
  height: 96px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.billing-modal .modal-body .tab-navigation .list-inline {
  margin: 0px;
}
.billing-modal .modal-body .tab-navigation .list-inline li {
  height: 96px;
  width: 25%;
  float: left;
  text-align: center;
  margin: 0px !important;
}
.billing-modal .modal-body .tab-navigation .list-inline li a {
  margin: 0px !important;
  color: #B5D7F0;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: none;
}
.billing-modal .modal-body .tab-navigation .list-inline li a i {
  font-size: 40px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.billing-modal .modal-body .tab-navigation .list-inline li.active,
.billing-modal .modal-body .tab-navigation .list-inline li:hover {
  background-color: #1D8CE0;
}
.billing-modal .modal-body .tab-navigation .list-inline li.active a,
.billing-modal .modal-body .tab-navigation .list-inline li:hover a {
  color: white;
}
.billing-modal .modal-body .billing-content .mentions-holder {
  padding: 20px;
}
.billing-modal .modal-body .billing-content h5 {
  text-transform: capitalize;
  margin-bottom: 0px;
}
.billing-modal .modal-body .billing-content p {
  font-size: 12px;
  color: #7F8FA4;
}
.billing-modal .modal-body .billing-content .limit-stats span {
  color: #00AEEF !important;
}
.billing-modal .modal-body .billing-content .progress-stats {
  margin-top: 10px;
}
.billing-modal .modal-body .billing-content .change-plan {
  margin-top: 15px;
}
.billing-modal .modal-body .billing-content .payment-card {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.billing-modal .modal-body .billing-content .payment-card i.fa-warning {
  color: #ED1C24;
  margin-left: 5px;
}
.billing-modal .modal-body .billing-content .payment-card img {
  width: 28px;
  height: auto;
  margin-right: 5px;
}
.billing-modal .modal-body .billing-content .subscription-cancel {
  padding-left: 20px;
  padding-right: 20px;
}
.billing-modal .modal-body .billing-content .subscription-cancel .expiring-notes,
.billing-modal .modal-body .billing-content .subscription-cancel .cancel-notes {
  padding: 15px;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.billing-modal .modal-body .billing-content .subscription-cancel .expiring-notes a,
.billing-modal .modal-body .billing-content .subscription-cancel .cancel-notes a {
  text-decoration: none !important;
}
.billing-modal .modal-body .billing-content .subscription-cancel .expiring-notes {
  background: #FFFDFD;
  border: 1px solid #DF0C0C;
  border-radius: 4px;
}
.billing-modal .modal-body .billing-content .subscription-cancel .cancel-notes {
  background: #F0F3F8;
  border: 1px solid #DCE1EA;
  border-radius: 4px;
}
.billing-modal .modal-body .billing-content .subscription-cancel .subject {
  font-size: 14px;
  color: #354052;
  line-height: 19px;
  font-weight: 600;
}
.billing-modal .modal-body .billing-content .subscription-cancel p {
  opacity: 0.59;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  color: #414F64;
  line-height: 19px;
}
.setting-modal .modal-dialog {
  width: 630px;
}
.setting-modal .modal-body {
  padding: 0px;
}
.setting-modal .modal-body .settings-block {
  text-transform: capitalize;
  background: #2EA1F8;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.setting-modal .modal-body .settings-block .close-modal i {
  color: #70BBFB;
}
.setting-modal .modal-body .settings-list {
  background-color: #222C3C;
  margin-left: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
}
.setting-modal .modal-body .settings-list li {
  margin-left: 30px;
  margin-right: 30px;
  text-transform: capitalize;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 3px solid transparent;
}
.setting-modal .modal-body .settings-list li:first-child {
  margin-left: 10px;
}
.setting-modal .modal-body .settings-list li:last-child {
  margin-right: 7px;
}
.setting-modal .modal-body .settings-list li a {
  color: #848C98;
  padding: 0px;
  border-radius: 0px;
  padding-top: 14px;
  padding-bottom: 11px;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 400;
}
.setting-modal .modal-body .settings-list li.active {
  border-bottom: 3px solid #2EA1F8;
}
.setting-modal .modal-body .settings-list li.active a {
  border-radius: 0px;
  color: #FFFFFF;
}
.setting-modal .modal-body .settings-content {
  padding: 0px;
  margin-bottom: 0px;
  border: 0px;
}
.setting-modal .modal-body .list-group {
  margin-bottom: 0px;
}
.setting-modal .modal-body .list-group .list-group-item {
  border: 1px solid #F2F3F5;
  padding: 16px 30px;
  cursor: pointer;
  font-size: 14px;
  color: #354052;
  border-left: 0px;
  border-right: 0px;
}
.setting-modal .modal-body .list-group .list-group-item .text-toggle {
  opacity: 0.6;
}
.setting-modal .modal-body .list-group .list-group-item:hover {
  color: #354052 !important;
}
.setting-modal .settings-footer {
  padding: 12px 30px;
}
.setting-modal .settings-footer .btn-send {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
}
/* ============================================================
  COMMON
============================================================ */
.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ============================================================
  SWITCH 1 - ROUND
============================================================ */
input.cmn-toggle-round + label {
  padding: 2px;
  width: 48px;
  height: 24px;
  background-color: #dddddd;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  border-radius: 24px;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  border-radius: 24px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 24px;
  background-color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: margin 0.4s;
  -moz-transition: margin 0.4s;
  -o-transition: margin 0.4s;
  transition: margin 0.4s;
}
input.cmn-toggle-round:checked + label:before {
  background-color: #36AF47;
}
input.cmn-toggle-round:checked + label:after {
  margin-left: 20px;
}
/* ============================================================
  SWITCH 2 - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat + label {
  padding: 2px;
  width: 48px;
  height: 24px;
  background-color: #dddddd;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  border-radius: 24px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}
input.cmn-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  border-radius: 24px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 15px;
  background-color: #dddddd;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat:checked + label {
  background-color: #36AF47;
}
input.cmn-toggle-round-flat:checked + label:after {
  margin-left: 20px;
  background-color: #36AF47;
}
/* ============================================================
  SWITCH 3 - YES NO
============================================================ */
input.cmn-toggle-yes-no + label {
  padding: 2px;
  width: 48px;
  height: 24px;
}
input.cmn-toggle-yes-no + label:before,
input.cmn-toggle-yes-no + label:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
}
input.cmn-toggle-yes-no + label:before {
  background-color: red;
  content: attr(data-off);
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
input.cmn-toggle-yes-no + label:after {
  background-color: #36AF47;
  content: attr(data-on);
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
input.cmn-toggle-yes-no:checked + label:before {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
input.cmn-toggle-yes-no:checked + label:after {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
}
.assign-modal .modal-dialog {
  width: 960px;
}
.assign-modal .modal-body {
  padding: 0px;
}
.assign-modal .modal-body .assign-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.assign-modal .modal-body .assign-header .close-modal i {
  color: #70BBFB;
}
.assign-modal .modal-body .table > thead > tr > th:first-child {
  padding-left: 30px;
}
.assign-modal .modal-body .table > tbody > tr > td:first-child {
  padding-left: 30px;
}
.assign-modal .modal-body .team-members-table {
  margin-bottom: 0px;
}
.assign-modal .modal-body .team-members-table thead .table-heading {
  color: #7F8FA4;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #F0F3F8;
}
.assign-modal .modal-body .team-members-table thead .table-heading th {
  font-weight: 400;
}
.assign-modal .modal-body .team-members-table tbody .members-progress {
  cursor: pointer;
}
.assign-modal .modal-body .team-members-table tbody .members-progress:hover {
  background-color: #1A91EB;
  color: #FFFFFF;
}
.assign-modal .modal-body .team-members-table tbody .members-progress:hover .progress {
  background: #2573A7;
}
.assign-modal .modal-body .team-members-table tbody .members-progress:hover .progress .progress-bar {
  background: #FFFFFF;
}
.assign-modal .modal-body .team-members-table tbody .members-progress:hover .user-image .user-name {
  color: #FFFFFF;
}
.assign-modal .modal-body .team-members-table tbody .members-progress:hover span {
  color: #FFFFFF;
}
.assign-modal .modal-body .team-members-table tbody .members-progress:hover span a {
  color: #FFFFFF;
}
.assign-modal .modal-body .team-members-table tbody .members-progress:hover .last-activity {
  color: #FFFFFF;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .user-image {
  width: 40px;
  height: 40px;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .user-image img {
  width: 40px;
  height: 40px;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .user-image .user-name {
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #354052;
  font-weight: 400;
  margin-left: 10px;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .progress {
  margin-bottom: 0px;
  margin-top: 9px;
  width: 50%;
  float: left;
  height: 4px;
  background: #F0F3F8;
  border-radius: 4px;
  margin-right: 10px;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user1-progress {
  background: #FF7800;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user2-progress {
  background: #2FABC1;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user3-progress {
  background: #FF7800;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user4-progress {
  background: #39B54A;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user5-progress {
  background: #FF7800;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .progress .user6-progress {
  background: #2FABC1;
}
.assign-modal .modal-body .team-members-table tbody .members-progress span {
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #354052;
  font-weight: 400;
}
.assign-modal .modal-body .team-members-table tbody .members-progress span a {
  text-decoration: none;
}
.assign-modal .modal-body .team-members-table tbody .members-progress .last-activity {
  color: #7F8FA4;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.achievement-modal .modal-body {
  padding: 0px;
}
.achievement-modal .modal-body .achievement-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.achievement-modal .modal-body .achievement-header .close-modal i {
  color: #70BBFB;
}
.achievement-modal .modal-body .awards {
  background-color: #222C3C;
  padding: 25px 30px 20px 30px;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #FFFFFF;
}
.achievement-modal .modal-body .table > tbody > tr > td {
  padding-left: 30px;
  padding-top: 23px;
  padding-bottom: 22px;
}
.achievement-modal .modal-body .achievements {
  max-height: 485px;
}
.achievement-modal .modal-body .achievements-table {
  margin-bottom: 0px;
}
.achievement-modal .modal-body .achievements-table .achievements-list {
  padding: 23px 30px;
}
.achievement-modal .modal-body .achievements-table .achievements-list .medal {
  width: 50px;
  height: 50px;
}
.achievement-modal .modal-body .achievements-table .achievements-list .medal-title {
  text-transform: uppercase;
  color: #9299A7;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  padding-left: 20px;
}
.achievement-modal .modal-body .achievements-table .achievements-list .medal-title .text-muted {
  color: #354052;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-transform: none;
}
.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .btn-share {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border: 1px solid #1585D8;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 7px 27px;
}
.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .help-text {
  text-transform: uppercase;
  font-size: 12px;
  color: #9299A7;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .progress {
  background: #F0F3F8;
  border-radius: 4px;
  height: 6px;
  width: 92px;
  margin: 0px;
}
.achievement-modal .modal-body .achievements-table .achievements-list .share-progress .progress .progress-bar {
  background: #FF7800;
  border-radius: 4px;
}
.onboarding-modal .modal-dialog {
  width: 770px;
}
.onboarding-modal .modal-body {
  padding: 0px;
}
.onboarding-modal .modal-body .onboard-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.onboarding-modal .modal-body .onboard-header .close-modal i {
  color: #70BBFB;
}
.onboarding-modal .modal-body .app-process {
  background: #222C3C;
  margin-bottom: 0px;
  margin-left: 0px;
}
.onboarding-modal .modal-body .app-process .no-style {
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  padding-left: 32px;
  padding-right: 6px;
  line-height: 60px;
}
.onboarding-modal .modal-body .app-process li {
  cursor: pointer;
}
.onboarding-modal .modal-body .app-process li a {
  padding: 16px 15px;
}
.onboarding-modal .modal-body .app-process li .steps {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  background: #313C4D;
  text-decoration: none;
  width: 28px;
  display: inline-block;
  height: 28px;
  text-align: center;
  border: 1px solid #313C4D;
  border-radius: 50%;
  margin-right: 10px;
  line-height: 28px;
}
.onboarding-modal .modal-body .app-process li .completed {
  color: #848C98;
}
.onboarding-modal .modal-body .app-process li span {
  color: #848C98;
  cursor: pointer;
}
.onboarding-modal .modal-body .app-process li.active .steps {
  color: #FFFFFF;
  background-color: #1991EB;
  border: 0px solid transparent;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  background-image: none;
}
.onboarding-modal .modal-body .app-process li.active span {
  color: #FFFFFF;
}
.onboarding-modal .modal-body .app-process li.active .app-list {
  color: #FFFFFF;
  cursor: pointer;
}
.onboarding-modal .modal-body .app-structure {
  padding-top: 37px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  border: 0px solid transparent;
}
.onboarding-modal .modal-body .app-structure .app-heading {
  color: #354052;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
.onboarding-modal .modal-body .app-structure .app-content {
  font-size: 14px;
  color: #7F8FA4;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin: 0px auto;
  margin-top: 14px;
  padding-left: 78px;
  padding-right: 40px;
}
.onboarding-modal .modal-body .app-structure .screen-image {
  margin-top: 33px;
}
.onboarding-modal .modal-body .app-structure .skip-next .skip {
  margin-top: -40px;
}
.onboarding-modal .modal-body .app-structure .skip-next .skip a {
  color: #7383A1;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-decoration: none;
}
.onboarding-modal .modal-body .app-structure .skip-next .btn-next {
  margin-top: -45px;
  margin-right: -10px;
}
.magnify {
  z-index: 20;
  border: 3px solid #999;
  position: relative;
  width: 900px;
  height: 640px;
  margin: 0px auto;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.magnify img {
  display: block;
}
.element_to_magnify {
  position: relative;
  width: 900px;
  height: 640px;
  overflow: hidden;
}
.element_to_magnify a {
  display: block;
  position: absolute;
  top: 80px;
  right: 80px;
  background-color: rgba(200, 200, 200, 0.25);
  padding: 4px;
  font-size: 18px;
  color: #fff;
}
.element_to_magnify img {
  width: 100%;
  height: auto;
}
.magnify_glass {
  z-index: 300;
  position: absolute;
  width: 200px;
  height: 200px;
  cursor: move;
}
.mg_ring {
  pointer-events: none;
  background: none;
  position: absolute;
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  -ms-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
  border: 4px solid #CCC;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  z-index: 20;
}
.mg_zone {
  position: absolute;
  border-radius: 50%;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
}
.pm_btn {
  border: 4px solid #CCC;
  z-index: 30;
  color: #333;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
}
.pm_btn h2 {
  text-align: center;
  user-select: none;
  cursor: pointer;
}
.minus {
  right: -24px;
  bottom: 30px;
}
.plus {
  right: 0px;
  bottom: -5px;
}
.button {
  width: 30px;
  height: 30px;
  padding-top: 5px;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgba(50, 50, 50, 0.5);
  position: absolute;
  text-indent: -999em;
  border: solid black 1px;
}
.connectnewservice-modal .modal-body {
  padding: 0px;
}
.connectnewservice-modal .modal-body .service-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.connectnewservice-modal .modal-body .service-header .close-modal i {
  color: #70BBFB;
}
.connectnewservice-modal .modal-body .authentication {
  padding: 30px;
}
.connectnewservice-modal .modal-body .authentication .authenticate {
  color: #7F8FA4;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.connectnewservice-modal .modal-body .authentication .authenticate .authentication-form {
  background: #FFFFFF;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  font-size: 14px;
  color: #354052;
}
.connectnewservice-modal .modal-body .authentication .connect-through .btn-payment {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
}
.connectnewservice-modal .modal-body .authentication .connect-through .payment-form {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
  box-shadow: none;
}
.connectnewservice-modal .modal-body .authentication .account-info {
  padding: 20px;
  margin-top: 25px;
  background: #F8FAFC;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  margin-bottom: 20px;
}
.connectnewservice-modal .modal-body .authentication .account-info .info {
  color: #333C48;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
.topbarsettings-modal .modal-dialog {
  width: 770px;
}
.topbarsettings-modal .modal-body {
  padding: 0px;
}
.topbarsettings-modal .modal-body .topbar-header {
  padding: 20px 30px;
  background-color: #2EA1F8;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings {
  margin-bottom: 0px;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li:first-child {
  padding-left: 0px;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li:last-child {
  padding-right: 0px;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li a {
  padding: 0px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  text-decoration: none;
  width: 40px;
  display: inline-block;
  height: 40px;
  text-align: center;
  border: 2px solid #1D8CE0;
  border-radius: 50%;
  line-height: 35px;
  margin-right: 0px;
  background: #1D8CE0;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li .settings {
  font-size: 14px;
  margin-top: 10px;
  color: #FFFFFF;
  text-transform: capitalize;
  cursor: pointer;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li:hover a {
  background: #FFFFFF;
  color: #2EA1F8;
  border: 2px solid #2EA1F8;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li:hover a .settings {
  color: #FFFFFF;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li.active a {
  background: #FFFFFF;
  color: #2EA1F8;
  border: 2px solid #2EA1F8;
}
.topbarsettings-modal .modal-body .topbar-header .top-settings li.active a .settings {
  color: #FFFFFF;
}
.topbarsettings-modal .modal-body .top-settings-content {
  padding: 0px;
  margin-bottom: 0px;
  border: 0px;
}
.topbarsettings-modal .modal-body .toggle-settings-list {
  margin-bottom: 0px;
}
.topbarsettings-modal .modal-body .toggle-settings-list .list-group-item {
  border: 1px solid #F2F3F5;
  padding: 19px 30px;
  font-size: 14px;
  color: #354052;
  border-left: 0px;
  border-right: 0px;
}
.topbarsettings-modal .modal-body .toggle-settings-list .list-group-item:first-child {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.darkmessage-modal {
  font-family: 'Source Sans Pro', sans-serif;
}
.darkmessage-modal .dark-body {
  background: #222C3C;
  padding: 30px 30px 17px 30px;
}
.darkmessage-modal .dark-body .media .media-left {
  width: 60px;
  height: 60px;
}
.darkmessage-modal .dark-body .media .media-body {
  padding-left: 10px;
  padding-top: 6px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  margin-left: 15px;
}
.darkmessage-modal .dark-body .media .media-body .media-heading {
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 400;
}
.darkmessage-modal .dark-body .media .media-body .dark-close {
  margin-top: -34px;
  color: #636E7D;
}
.darkmessage-modal .dark-body .media .media-body .circle {
  width: 12px;
  height: 12px;
  border: 2px solid #1A91EB;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 10px;
}
.darkmessage-modal .dark-body .select {
  margin-top: 25px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #273142;
}
.darkmessage-modal .dark-body .select .selection {
  border: 1px solid #313D4F;
  border-radius: 4px;
  background: #273142;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.darkmessage-modal .dark-body .hello {
  border: 1px solid #313D4F;
  border-radius: 4px;
  background: #273142;
  margin-top: 15px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.darkmessage-modal .dark-body .hello::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darkmessage-modal .dark-body .hello:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darkmessage-modal .dark-body .hello::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darkmessage-modal .dark-body .hello:-ms-input-placeholder {
  color: #7C8CA5;
}
.darkmessage-modal .dark-body .message-box {
  border: 1px solid #313D4F;
  border-radius: 4px;
  background: #273142;
  margin-top: 14px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.darkmessage-modal .dark-body .message-box::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darkmessage-modal .dark-body .message-box:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darkmessage-modal .dark-body .message-box::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darkmessage-modal .dark-body .message-box:-ms-input-placeholder {
  color: #7C8CA5;
}
.darkmessage-modal .dark-footer {
  background: #273142;
  border-radius: 4px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: -4px;
}
.darkmessage-modal .dark-footer .message {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}
.darkmessage-modal .dark-footer .btn-send {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  color: #FFFFFF;
  border-radius: 4px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.darkmessage-modal .dark-footer .check-message {
  line-height: 20px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
}
.darkmessage-modal .dark-footer .check-message input[type="checkbox"] {
  position: inherit;
  margin-right: 10px;
}
.createnew-modal .dark-body {
  font-family: 'Source Sans Pro', sans-serif;
  background: #222C3C;
  padding: 30px 30px 20px 30px;
}
.createnew-modal .dark-body .body-title {
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 400;
  margin-top: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 25px;
}
.createnew-modal .dark-body .dark-close {
  color: #636E7D;
  font-size: 12px;
  margin-top: 5px;
}
.createnew-modal .dark-body .create {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
.createnew-modal .dark-body .create-form {
  border: 1px solid #313D4F;
  border-radius: 4px;
  background: #273142;
  color: #FFFFFF;
}
.createnew-modal .dark-body .create-form::-webkit-input-placeholder {
  color: #7C8CA5;
}
.createnew-modal .dark-body .create-form:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.createnew-modal .dark-body .create-form::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.createnew-modal .dark-body .create-form:-ms-input-placeholder {
  color: #7C8CA5;
}
.createnew-modal .dark-body .rotate .potrait {
  border: 1px solid #313D4F;
  height: 146px;
  background: #273142;
  border-radius: 4px;
  text-align: center;
  line-height: 123px;
  cursor: pointer;
}
.createnew-modal .dark-body .rotate .potrait .selected {
  display: none;
  position: absolute;
  right: 25px;
  top: -43px;
}
.createnew-modal .dark-body .rotate .potrait .selected i {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  color: #1BB934;
  font-size: 12px;
  line-height: 18px;
}
.createnew-modal .dark-body .rotate .potrait .image-text {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 0px;
  font-family: 'Source Sans Pro', sans-serif;
}
.createnew-modal .dark-body .rotate .check-potrait {
  position: absolute;
}
.createnew-modal .dark-body .rotate .potrait.active {
  background: #1BB934;
  border: 1px solid #1BB934;
  border-radius: 4px;
}
.createnew-modal .dark-body .rotate .potrait.active .selected {
  display: block;
}
.createnew-modal .create-footer {
  text-align: center;
  background: #273142;
  margin-top: -4px;
  padding: 18px 30px;
}
.createnew-modal .create-footer .btn-finish {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}
.darksharing-modal .modal-body {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0px;
}
.darksharing-modal .modal-body .share-header {
  background-color: #2EA1F8;
}
.darksharing-modal .modal-body .share-header .share-list {
  margin-left: 0px;
  margin-bottom: 0px;
}
.darksharing-modal .modal-body .share-header .share-list li {
  padding-left: 35px;
  padding-right: 35px;
  text-align: center;
  border: 2px solid transparent;
}
.darksharing-modal .modal-body .share-header .share-list li:first-child {
  padding-left: 35px;
  margin-left: 10px;
}
.darksharing-modal .modal-body .share-header .share-list li a {
  font-size: 40px;
  text-decoration: none;
  color: #B5D7F0;
}
.darksharing-modal .modal-body .share-header .share-list li a .share-icon {
  font-size: 14px;
  color: #B5D7F0;
  text-align: center;
  text-transform: uppercase;
}
.darksharing-modal .modal-body .share-header .share-list li.active {
  border-bottom: 2px solid #FFFFFF;
}
.darksharing-modal .modal-body .share-header .share-list li.active a {
  color: #FFFFFF;
}
.darksharing-modal .modal-body .share-header .share-list li.active a .share-icon {
  color: #FFFFFF;
}
.darksharing-modal .modal-body .sharing-content {
  padding: 0px;
  margin-bottom: 0px;
  border: 0px;
  background: #222C3C;
  border-radius: 0px;
}
.darksharing-modal .modal-body .sharing-details {
  padding: 30px 30px 25px 30px;
}
.darksharing-modal .modal-body .sharing-details .share-title {
  font-size: 21px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin-top: 0px;
}
.darksharing-modal .modal-body .sharing-details .share-link {
  margin-top: 22px;
}
.darksharing-modal .modal-body .sharing-details .share-link .share {
  font-size: 14px;
  color: #7C8CA5;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
.darksharing-modal .modal-body .sharing-details .share-link .more a {
  font-size: 14px;
  color: #1A91EB;
  font-size: 400;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
}
.darksharing-modal .modal-body .sharing-details .address-group {
  margin-top: 5px;
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px;
}
.darksharing-modal .modal-body .sharing-details .address-group .share-address,
.darksharing-modal .modal-body .sharing-details .address-group .btn-cpy {
  background: #222C3C;
  border: 1px solid #313D4F;
  height: 46px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 0px;
  border-top-right-radius: 0px;
  border-radius: 4px 0px 0px 0px;
}
.darksharing-modal .modal-body .sharing-details .address-group .btn-cpy {
  width: 108px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.darksharing-modal .modal-body .sharing-details .url-bar {
  background: #273142;
  height: 46px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  border: 1px solid #313D4F;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-bottom: 25px;
}
.darksharing-modal .modal-body .sharing-details .url-bar::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .url-bar:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .url-bar::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .url-bar:-ms-input-placeholder {
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .control-group {
  margin-bottom: 0px;
}
.darksharing-modal .modal-body .sharing-details .control-group .mail-header {
  font-size: 14px;
  color: #7C8CA5;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
.darksharing-modal .modal-body .sharing-details .control-group .selectize-input {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
placeholder .darksharing-modal .modal-body .sharing-details .control-group .selectize-input::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .control-group .selectize-input:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .control-group .selectize-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .control-group .selectize-input:-ms-input-placeholder {
  color: #7C8CA5;
}
.darksharing-modal .modal-body .sharing-details .control-group .edit-button {
  background: #222C3C;
  border: 1px solid transparent;
  padding: 0px;
  padding-left: 10px;
}
.darksharing-modal .modal-body .sharing-details .control-group .edit-button .btn-edit {
  background: #273142;
  border: 1px solid #313D4F;
  border-radius: 4px;
  height: 46px;
  margin-top: 19px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.darksharing-modal .modal-body .sharing-details .control-group .edit-button .edit-dropdown {
  right: 0px;
  left: auto;
}
.darksharing-modal .share-footer {
  background: #273142;
  border-radius: 4px;
  margin-top: -4px;
  padding: 17px 30px;
}
.darksharing-modal .share-footer a {
  font-size: 14px;
  color: #7F8FA4;
  line-height: 34px;
  font-family: 'Source Sans Pro', sans-serif;
}
.darksharing-modal .share-footer .btn-share {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
  height: 36px;
}
.darkfilter-modal .filter-panel {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 30px;
  background: #222C3C;
}
.darkfilter-modal .filter-panel .filter-title .filter-header {
  margin: 0px;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 400;
  margin-top: -5px;
  font-family: 'Source Sans Pro', sans-serif;
}
.darkfilter-modal .filter-panel .filter-title .close-modal {
  margin-top: -6px;
  color: #636E7D;
}
.darkfilter-modal .filter-panel .filter-type {
  margin-top: 28px;
  font-family: 'Source Sans Pro', sans-serif;
}
.darkfilter-modal .filter-panel .filter-type label {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  padding-bottom: 3px;
}
.darkfilter-modal .filter-panel .filter-type .form-control {
  background: #273142;
  border: 1px solid #313D4F;
  border-radius: 4px;
  color: #7F8FA4;
  height: 36px;
  font-size: 14px;
}
.darkfilter-modal .filter-footer {
  background: #273142;
  border-radius: 4px;
  margin-top: -4px;
  padding: 20px 30px;
}
.darkfilter-modal .filter-footer a {
  font-size: 14px;
  color: #7F8FA4;
  line-height: 30px;
  font-family: 'Source Sans Pro', sans-serif;
}
.darkfilter-modal .filter-footer .btn-filter {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
}
.filterdata-modal .filter-data {
  padding: 0px;
  font-family: 'Source Sans Pro', sans-serif;
}
.filterdata-modal .filter-data .filter-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.filterdata-modal .filter-data .filter-header .close-modal i {
  color: #70BBFB;
}
.filterdata-modal .filter-data .filter-data {
  padding: 30px;
}
.filterdata-modal .filter-data .filter-data .data-type {
  margin-bottom: 20px;
}
.filterdata-modal .filter-data .filter-data .filter-type {
  font-family: 'Source Sans Pro', sans-serif;
}
.filterdata-modal .filter-data .filter-data .filter-type label {
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  padding-bottom: 3px;
}
.filterdata-modal .filter-data .filter-data .filter-type .form-control {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
  color: #354052;
  height: 36px;
  font-size: 14px;
}
.filterdata-modal .filter-data-footer {
  background: #F0F3F8;
  border-radius: 4px;
  border-color: #F0F3F8;
  padding: 13px 30px;
}
.filterdata-modal .filter-data-footer a {
  font-size: 14px;
  color: #7F8FA4;
  line-height: 30px;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
}
.filterdata-modal .filter-data-footer .btn-filter {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  border-radius: 4px;
}
.darknewimage-modal .dark-image {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 30px;
  background: #222C3C;
}
.darknewimage-modal .dark-image .image-title .image-header {
  margin: 0px;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 400;
  margin-top: -5px;
  font-family: 'Source Sans Pro', sans-serif;
}
.darknewimage-modal .dark-image .image-title .close-modal {
  margin-top: -6px;
  color: #636E7D;
}
.darknewimage-modal .dark-image .add-to {
  padding-top: 28px;
  font-family: 'Source Sans Pro', sans-serif;
}
.darknewimage-modal .dark-image .add-to label {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  padding-bottom: 3px;
}
.darknewimage-modal .dark-image .add-to .help-text a {
  color: #7F8FA4;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}
.darknewimage-modal .dark-image .add-to .form-control {
  background: #273142;
  border: 1px solid #313D4F;
  border-radius: 4px;
  color: #FFFFFF;
  height: 36px;
  font-size: 14px;
}
.darknewimage-modal .dark-image .add-to .form-control::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darknewimage-modal .dark-image .add-to .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darknewimage-modal .dark-image .add-to .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darknewimage-modal .dark-image .add-to .form-control:-ms-input-placeholder {
  color: #7C8CA5;
}
.darknewimage-modal .dark-image .add-to .btn-group-justified {
  background: #273142;
  border: 1px solid #313D4F;
  border-radius: 4px;
}
.darknewimage-modal .dark-image .add-to .btn-group-justified .btn-grp {
  background: #273142;
  border: 1px solid transparent;
  border-radius: 4px;
  border: 0px;
  color: #FFFFFF;
}
.darknewimage-modal .dark-image .add-to .btn-grp.active {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border: 1px solid #1A91EB;
  border-radius: 4px 0px 0px 4px;
}
.darknewimage-modal .select-image {
  padding: 20px 25px 0px 25px;
  margin-top: 20px;
  border-top: 1px solid #273142;
  margin-left: -30px;
  margin-right: -30px;
}
.darknewimage-modal .select-image .add-image {
  padding-right: 5px;
  padding-left: 5px;
}
.darknewimage-modal .select-image img {
  width: 100%;
}
.darknewimage-modal .image-footer {
  background: #273142;
  border-radius: 4px;
  margin-top: -4px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 18px 30px;
}
.darknewimage-modal .image-footer a {
  font-size: 14px;
  color: #7F8FA4;
  line-height: 30px;
  line-height: 34px;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
}
.darknewimage-modal .image-footer .btn-publish {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
}
.darkflightform-modal .darkflight {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 30px 30px 15px 30px;
  background: #222C3C;
}
.darkflightform-modal .darkflight .flight-title .flight-header {
  margin: 0px;
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 400;
  margin-top: -5px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-right: 25px;
}
.darkflightform-modal .darkflight .flight-title .arrival .journey {
  margin-top: 0px;
  float: left;
  color: #FFFFFF;
}
.darkflightform-modal .darkflight .flight-title .arrival .journey .check {
  margin-right: 15px;
  color: #7F8FA4;
  cursor: pointer;
}
.darkflightform-modal .darkflight .flight-title .arrival .journey .check .select {
  margin-right: 5px;
}
.darkflightform-modal .darkflight .flight-title .close-modal {
  margin-top: -6px;
  color: #636E7D;
}
.darkflightform-modal .darkflight .flights-available {
  margin-top: 23px;
  margin-bottom: 0px;
}
.darkflightform-modal .darkflight .flights-available .input-group {
  border: 1px solid #313D4F;
  margin-bottom: 10px;
  border-radius: 4px;
  height: 46px;
  background: #273142;
}
.darkflightform-modal .darkflight .flights-available .input-group .location {
  border: 0px solid transparent;
  background: #273142;
  border-right: 0px;
  height: 46px;
  color: #FFFFFF;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.darkflightform-modal .darkflight .flights-available .input-group .location::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .flights-available .input-group .location:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .flights-available .input-group .location::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .flights-available .input-group .location:-ms-input-placeholder {
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .flights-available .input-group .all {
  color: #FFFFFF;
  text-transform: capitalize;
  font-size: 14px;
}
.darkflightform-modal .darkflight .flights-available .input-group .input-group-addon {
  background: #273142;
  border: 0px;
  padding: 5px 12px;
}
.darkflightform-modal .darkflight .flights-available .input-group .submit {
  width: 90px;
  height: 36px;
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
  float: right;
  outline: none !important;
  border: 0px solid transparent;
}
.darkflightform-modal .darkflight .flights-available .input-group .submit .fa {
  color: #FFFFFF;
}
.darkflightform-modal .darkflight .preferences {
  font-size: 14px;
}
.darkflightform-modal .darkflight .preferences .checkbox {
  margin: 0px;
}
.darkflightform-modal .darkflight .preferences .checkbox .prefer {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 23px;
}
.darkflightform-modal .darkflight .preferences .checkbox .prefer input[type="checkbox"] {
  margin-right: 14px;
  height: 18px;
  width: 18px;
}
.darkflightform-modal .darkflight .preferences .check-route {
  color: #7F8FA4;
  font-size: 14px;
  text-decoration: none;
}
.darkflightform-modal .darkflight .check-in-section {
  margin-top: 25px;
}
.darkflightform-modal .darkflight .check-in-section .form-group {
  margin-bottom: 0px;
}
.darkflightform-modal .darkflight .check-in-section .form-group .cale {
  background: #273142;
  border: 1px solid #313D4F;
  border-radius: 4px;
  color: #FFFFFF;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
  height: 36px;
}
.darkflightform-modal .darkflight .check-in-section .form-group .cale::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .check-in-section .form-group .cale:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .check-in-section .form-group .cale::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .check-in-section .form-group .cale:-ms-input-placeholder {
  color: #7C8CA5;
}
.darkflightform-modal .darkflight .check-in-section .form-group .btn-calendar {
  background: #273142;
  border: 1px solid #313D4F;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;
  height: 36px;
  cursor: pointer;
}
.darkflightform-modal .darkflight .categories {
  margin-bottom: 0px;
  display: flex;
  justify-content: space-around;
}
.darkflightform-modal .darkflight .categories li {
  height: 36px;
}
.darkflightform-modal .darkflight .categories li:first-child {
  margin-left: -20px;
}
.darkflightform-modal .darkflight .categories li:last-child {
  margin-right: -25px;
}
.darkflightform-modal .darkflight .categories li .filter {
  background: #273142;
  border: 1px solid #313D4F;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}
.darkflightform-modal .flight-footer {
  text-align: center;
  background: #273142;
  margin-top: -4px;
  padding: 17px 30px;
}
.darkflightform-modal .flight-footer .btn-search {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
  width: 100%;
  color: #FFFFFF;
  font-size: 14px;
  padding: 8px 16px;
  outline: none !important;
}
.paymentwindow-modal .modal-body {
  padding: 0px;
}
.paymentwindow-modal .modal-body .image-section {
  padding-right: 0px;
}
.paymentwindow-modal .modal-body .rate-tag {
  position: absolute;
  bottom: 30px;
  border: 18px solid #1991EB;
  height: 36px;
  line-height: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  width: 96px;
  cursor: pointer;
  border-right: 13px solid transparent;
}
.paymentwindow-modal .modal-body .pay-for-image {
  width: 100%;
  height: auto;
}
.paymentwindow-modal .modal-body .pay-here {
  padding-left: 0px;
}
.paymentwindow-modal .modal-body .payment-section {
  padding: 30px;
}
.paymentwindow-modal .modal-body .payment-section .card-heading {
  font-size: 20px;
  font-weight: 400;
  margin-top: -8px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.paymentwindow-modal .modal-body .payment-section .close-modal {
  margin-top: -12px;
}
.paymentwindow-modal .modal-body .payment-section .close-modal i {
  color: #C5D0DE;
  font-size: 12px;
}
.paymentwindow-modal .modal-body .payment-section .input-group {
  margin-bottom: 15px;
}
.paymentwindow-modal .modal-body .payment-section .input-group .icon {
  height: 36px;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  border-right: 0px;
  box-shadow: none;
  font-size: 16px;
  color: #354052;
  background: #FFFFFF;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.paymentwindow-modal .modal-body .payment-section .input-group .icon .fa {
  opacity: 0.5;
}
.paymentwindow-modal .modal-body .payment-section .input-group .enter-details {
  height: 36px;
  padding: 0px;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  border-left: 0px;
  box-shadow: none;
  font-size: 14px;
  color: #354052;
  background: #FFFFFF;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.paymentwindow-modal .modal-body .payment-section .input-group .enter-card-details {
  height: 36px;
  padding: 0px;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  border-left: 0px;
  box-shadow: none;
  font-size: 14px;
  color: #354052;
  border-right: 0px;
  background: #FFFFFF;
  border-radius: 0px;
}
.paymentwindow-modal .modal-body .payment-section .input-group .card-image {
  width: 28px;
  height: 18px;
  border-color: #DFE3E9!important;
  border: 1px solid #DFE3E9;
  background: #FFFFFF;
  border-left: 0px;
}
.paymentwindow-modal .modal-body .payment-section .input-group .card-image a img {
  width: 28px;
  height: 18px;
  opacity: 1;
}
.paymentwindow-modal .modal-body .payment-section .input-group .btn-calendar {
  background: #FFFFFF;
}
.paymentwindow-modal .modal-body .payment-section .input-group .month,
.paymentwindow-modal .modal-body .payment-section .input-group .number {
  border: 1px solid #DFE3E9;
  height: 36px;
  border-left: 0px;
  padding-left: 0px;
}
.paymentwindow-modal .modal-body .payment-section .date {
  margin-bottom: 20px;
}
.paymentwindow-modal .modal-body .payment-section .payment .btn-pay {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  border-radius: 4px;
  padding: 7px 12px;
  font-size: 14px;
  margin-bottom: 20px;
}
.paymentwindow-modal .modal-body .payment-section .alternate {
  border-bottom: 1px solid #F2F3F5;
  position: relative;
}
.paymentwindow-modal .modal-body .payment-section .alternate .divide {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  margin-top: -8px;
  height: 13px;
  font-size: 14px;
  color: #354052;
  background-color: #FFFFFF;
}
.paymentwindow-modal .modal-body .payment-section .alternate .divide a {
  opacity: 0.5;
  color: #354052;
  text-decoration: none;
}
.paymentwindow-modal .modal-body .payment-section .alternate-payment {
  margin-top: 21px;
}
.paymentwindow-modal .modal-body .payment-section .alternate-payment .pay-alter {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border: 1px solid #1585D8;
  border-radius: 4px;
  padding: 7px 12px;
  font-size: 14px;
}
.paymentwindow-modal .modal-body .payment-section .alternate-payment .pay-alter img {
  width: auto;
  margin-left: 4px;
  height: 16px;
}
.profile-modal .modal-dialog {
  width: 630px;
}
.profile-modal .modal-body {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0px;
}
.profile-modal .modal-body .user-profile-pic {
  padding-right: 0px;
}
.profile-modal .modal-body .user-profile-pic a img {
  width: 100%;
  height: auto;
}
.profile-modal .modal-body .user-profile-pic .user-profile-name {
  position: absolute;
  bottom: 35px;
  left: 50%;
  margin-left: -50px;
}
.profile-modal .modal-body .user-profile-pic .user-profile-name .name {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 400;
}
.profile-modal .modal-body .user-profile-pic .user-profile-name .designation {
  margin-top: 5px;
}
.profile-modal .modal-body .user-profile-pic .user-profile-name .designation .circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #1A91EB;
  float: left;
  margin-top: 4px;
  margin-right: 8px;
}
.profile-modal .modal-body .user-profile-pic .user-profile-name .designation span {
  font-size: 14px;
  color: #FFFFFF;
}
.profile-modal .modal-body .user-all-activities {
  padding-left: 0px;
}
.profile-modal .modal-body .user-activities {
  padding-right: 0px;
  padding: 28px 30px 30px 30px;
}
.profile-modal .modal-body .user-activities .user-name {
  margin-top: -3px;
  font-size: 20px;
  font-weight: 400;
  color: #354052;
}
.profile-modal .modal-body .user-activities .activity-header {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}
.profile-modal .modal-body .user-activities .activity-pic {
  float: left;
  margin-right: 15px;
}
.profile-modal .modal-body .user-activities .activity-comments {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  margin-top: -5px;
}
.profile-modal .modal-body .user-activities .separate {
  border: 1px solid #F2F3F5;
  margin-top: 10px;
  margin-bottom: 12px;
}
.profile-modal .modal-body .user-activities .sub-details {
  margin-bottom: 13px;
}
.profile-modal .modal-body .user-activities .user-sub-details {
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  color: #354052;
}
.profile-modal .modal-body .user-activities .help-text {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: #354052;
}
.profile-modal .modal-body .user-activities .add-user {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
}
.profile-modal .modal-body .user-activities .select-activity {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
  height: 36px;
  margin-top: 3px;
}
.profile-modal .modal-body .user-activities .well {
  color: #7F8FA4;
  letter-spacing: 0.4px;
  font-size: 14px;
  background: #F0F3F8;
  border: 1px solid #DCE1EA;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.profile-modal .modal-body .user-activities .well .protected-user {
  margin: 0px;
  color: #354052;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.profile-modal .modal-body .user-activities .well .protected-user .fa {
  font-size: 16px;
  margin-right: 7px;
}
.darksignup-modal .modal-dialog {
  width: 750px;
}
.darksignup-modal .modal-body {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0px;
}
.darksignup-modal .modal-body .create-account {
  margin-right: 0px;
  margin-left: 0px;
  display: table;
}
.darksignup-modal .modal-body .create-account .welcome-section {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  padding: 30px 30px 23px 30px;
  height: 100%;
  float: none;
  display: table-cell;
}
.darksignup-modal .modal-body .create-account .welcome-section .welcome-icon a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  padding: 17px;
  background-image: linear-gradient(-1deg, #1267A7 3%, #1268A7 100%);
}
.darksignup-modal .modal-body .create-account .welcome-section .welcome-icon a i {
  font-size: 16px;
  color: #FFFFFF;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .welcome {
  font-size: 22px;
  color: #FFFFFF;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-content {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  opacity: 0.6;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .buyer {
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 400;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .benefits {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 100px;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom {
  margin-bottom: 0px;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li {
  border-right: 1px solid #FFFFFF;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li:last-child {
  border-right: 0px;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li:last-child a {
  margin-right: 0px;
}
.darksignup-modal .modal-body .create-account .welcome-section .dashboard-section .dashboard-bottom li a {
  color: #FFFFFF;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.6;
  text-decoration: none;
}
.darksignup-modal .modal-body .create-account .account-section {
  background: #222C3C;
  height: 100%;
  width: 100%;
  float: none;
  display: table-cell;
  padding: 30px 30px 0px 30px;
}
.darksignup-modal .modal-body .create-account .account-section .create {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #FFFFFF;
  font-weight: 400;
}
.darksignup-modal .modal-body .create-account .account-section .authenticate {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}
.darksignup-modal .modal-body .create-account .account-section .authentication-form {
  background: #273142;
  height: 36px;
  border: 1px solid #313D4F;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.darksignup-modal .modal-body .create-account .account-section .authentication-form::-webkit-input-placeholder {
  color: #7C8CA5;
}
.darksignup-modal .modal-body .create-account .account-section .authentication-form:-moz-placeholder {
  /* Firefox 18- */
  color: #7C8CA5;
}
.darksignup-modal .modal-body .create-account .account-section .authentication-form::-moz-placeholder {
  /* Firefox 19+ */
  color: #7C8CA5;
}
.darksignup-modal .modal-body .create-account .account-section .authentication-form:-ms-input-placeholder {
  color: #7C8CA5;
}
.darksignup-modal .modal-body .create-account .account-section .password-form {
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.darksignup-modal .modal-body .create-account .account-section .secure {
  height: 36px;
  background: #273142;
  border: 1px solid #313D4F;
  border-left: 0px;
  cursor: pointer;
}
.darksignup-modal .modal-body .create-account .account-section .sign-up {
  margin-bottom: 20px;
}
.darksignup-modal .modal-body .create-account .account-section .btn-continue {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
}
.darksignup-modal .modal-body .create-account .account-section .darksign-footer {
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 20px;
  text-align: center;
  background: #273142;
  padding: 20px 30px;
  color: #7F8FA4;
  font-size: 14px;
}
.darksignup-modal .modal-body .create-account .account-section .darksign-footer a {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.darkphonemanager-modal .modal-content {
  font-family: 'Source Sans Pro', sans-serif;
}
.darkphonemanager-modal .modal-content .modal-header {
  background: #273142;
  margin-left: 0px;
  padding: 17px 23px 17px 21px;
  display: flex;
  position: relative;
  justify-content: space-around;
}
.darkphonemanager-modal .modal-content .modal-header li a {
  font-size: 12px;
  color: #7F8FA4;
  padding: 0px;
  text-transform: uppercase;
}
.darkphonemanager-modal .modal-content .modal-header li a .fa {
  margin-right: 9px;
}
.darkphonemanager-modal .modal-content .modal-header li.active a {
  color: #ffffff;
}
.darkphonemanager-modal .modal-content .modal-header:before {
  content: "\A";
  border-style: solid;
  border-width: 0px 15px 15px 15px;
  border-color: #273142 transparent;
  position: absolute;
  left: 50%;
  margin-top: -30px;
}
.darkphonemanager-modal .modal-content .dark-phone {
  background: #222C3C;
  margin-top: -2px;
  padding: 20px;
}
.darkphonemanager-modal .modal-content .dark-phone .gallery {
  margin-left: 2px;
}
.darkphonemanager-modal .modal-content .dark-phone .gallery li img {
  border-radius: 4px;
  width: 98px;
  height: 98px;
}
.darkphonemanager-modal .modal-content .dark-phone-footer {
  background: #273142;
  padding: 14px 20px 16px 20px;
  margin-top: -2px;
  text-align: left;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list {
  margin-bottom: 0px;
  margin-left: 2px;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list li a {
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 10px;
  text-decoration: none;
  font-weight: 400;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .app {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px 0px 0px 4px;
  text-align: center;
  height: 20px;
  width: 110px;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .audio {
  background-image: linear-gradient(-1deg, #26B10F 2%, #59DA44 98%);
  border-radius: 0px;
  height: 20px;
  width: 131px;
  text-align: center;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .photo {
  background-image: linear-gradient(-1deg, #805CE6 2%, #9D8FE3 98%);
  border-radius: 0px;
  height: 20px;
  width: 89px;
  text-align: center;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .other {
  background-image: linear-gradient(-1deg, #FDBB02 2%, #F8CF5E 98%);
  border-radius: 0px 4px 4px 0px;
  text-align: center;
  height: 20px;
  width: 100px;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .percentage {
  border-radius: 0px 4px 4px 0px;
  background: #222C3C;
  text-align: center;
  height: 20px;
  width: 100px;
}
.darkphonemanager-modal .modal-content .dark-phone-footer .phone-manager-list .percentage a {
  font-size: 10px;
  color: #566378;
  text-decoration: none;
  font-weight: 400;
}
.setnewproject-modal .modal-body {
  padding: 0px;
  font-family: 'Source Sans Pro', sans-serif;
}
.setnewproject-modal .modal-body .newproject-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.setnewproject-modal .modal-body .newproject-header .close-modal i {
  color: #70BBFB;
}
.setnewproject-modal .modal-body .upload-section {
  padding: 30px;
}
.setnewproject-modal .modal-body .upload-section .outer-box {
  padding: 6px;
  height: 162px;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  cursor: pointer;
}
.setnewproject-modal .modal-body .upload-section .outer-box .drop-box {
  border: 3px dashed #CED0DA;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  text-decoration: none;
}
.setnewproject-modal .modal-body .upload-section .outer-box .drop-box i {
  color: #E2E3E9;
  font-size: 40px;
  margin-top: 31px;
}
.setnewproject-modal .modal-body .upload-section .outer-box .drop-box .cloud-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  text-decoration: none;
  width: 220px;
  margin: 0px auto;
}
.setnewproject-modal .modal-body .upload-section .outer-box .drop-box #my_project {
  display: none;
}
.setnewproject-modal .modal-body .upload-section .tooltip {
  width: 290px;
}
.setnewproject-modal .modal-body .upload-section .tooltip .right-tip {
  color: red;
}
.setnewproject-modal .modal-body .upload-section .right-tip + .tooltip > .tooltip-inner {
  color: red;
}
.setnewproject-modal .modal-body .upload-section .set-up {
  margin-top: 20px;
  margin-bottom: 14px;
}
.setnewproject-modal .modal-body .upload-section .set-up .set-name {
  color: #7F8FA4;
  font-size: 14px;
}
.setnewproject-modal .modal-body .upload-section .set-up .enter-form {
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  height: 36px;
}
.setnewproject-modal .modal-body .upload-section .selection {
  margin-bottom: 0px;
}
.setnewproject-modal .modal-body .upload-section .selection .select-project {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
  height: 46px;
}
.setnewproject-modal .new-project-footer {
  text-align: center;
  background: #F0F3F8;
  padding: 12px 30px;
}
.setnewproject-modal .new-project-footer .btn-create {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  border-radius: 4px;
  height: 36px;
}
.selectlayout-modal .modal-body {
  padding: 0px;
  font-family: 'Source Sans Pro', sans-serif;
}
.selectlayout-modal .modal-body .layout-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.selectlayout-modal .modal-body .layout-header .close-modal i {
  color: #70BBFB;
}
.selectlayout-modal .modal-body .layout-section {
  padding: 20px 30px 5px 30px;
}
.selectlayout-modal .modal-body .layout-section .layout-text {
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  margin-bottom: 20px;
  letter-spacing: 0.4px;
}
.selectlayout-modal .modal-body .layout-section .layout-img {
  margin-bottom: 15px;
}
.selectlayout-modal .modal-body .layout-section .layout-img img {
  width: 160px;
  height: 110px;
}
.selectlayout-modal .modal-body .layout-section .layout-img .selected {
  position: absolute;
  left: 50%;
  margin-left: -28px;
  top: 50%;
  margin-top: -28px;
}
.selectlayout-modal .modal-body .layout-section .layout-img .selected i {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  color: #1BB934;
  font-size: 24px;
  line-height: 56px;
  text-align: center;
}
.selectlayout-modal .layout-footer {
  background: #F0F3F8;
  padding: 12px 30px;
}
.selectlayout-modal .layout-footer .btn-cancel {
  background-image: linear-gradient(-180deg, #FFFFFF 3%, #F2F4F7 98%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
  height: 36px;
  color: #7383A1;
}
.selectlayout-modal .layout-footer .btn-confirm {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  border-radius: 4px;
  height: 36px;
  color: #FFFFFF;
}
.filemanager-modal .manager-panel {
  width: 830px;
}
.filemanager-modal .manage-data {
  padding: 0px;
}
.filemanager-modal .manage-data .manage-header {
  background: #2EA1F8;
  text-transform: capitalize;
  padding: 12px 12px 12px 30px;
}
.filemanager-modal .manage-data .manage-header .manage-text {
  font-size: 18px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.filemanager-modal .manage-data .manage-header .search-form {
  width: 35%;
  margin-bottom: 0px;
}
.filemanager-modal .manage-data .manage-header .search-form .search {
  height: 36px;
  background: #1E8FE4;
  border: 1px solid #1A84D3;
  border-radius: 4px;
  color: #FFFFFF;
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.filemanager-modal .manage-data .manage-header .search-form .search-icon {
  background: #1E8FE4;
  border: 1px solid #1A84D3;
  border-radius: 4px;
  color: #FFFFFF;
  height: 36px;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.filemanager-modal .manage-data .manage-section {
  margin-left: 0px;
  margin-right: 0px;
  display: table;
}
.filemanager-modal .manage-data .manage-section .file-manage-list {
  padding-left: 0px;
  padding-right: 0px;
  background: #222C3C;
  height: 100%;
  display: table-cell;
  float: none;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li {
  color: #848C98;
  font-size: 14px;
  height: 36px;
  border-bottom: 1px solid #2A3546;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 8px;
  cursor: pointer;
  border-left: 2px solid transparent;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li a {
  color: #848C98;
  font-size: 14px;
  text-decoration: none;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li a .badge {
  background: #323C4E;
  border-radius: 50px;
  margin-top: 5px;
  color: #848C98;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li:hover {
  border-left: 2px solid #1A91EB;
  background: #2A3546;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li:hover a {
  color: #FFFFFF;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .manage-list li:hover .badge {
  color: #FFFFFF;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .bottom-icons {
  position: absolute;
  width: 100%;
  bottom: 15px;
  padding-left: 20px;
  padding-right: 8px;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .bottom-icons .btn-bottom {
  background: #222C3C;
  border: 1px solid #2A3546;
  border-radius: 4px;
}
.filemanager-modal .manage-data .manage-section .file-manage-list .bottom-icons .btn-bottom i {
  color: #848C98;
}
.filemanager-modal .manage-data .manage-section .manage-table {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: -15px;
  height: 100%;
  width: 100%;
  float: none;
  display: table-cell;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table {
  margin-bottom: 0px;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table thead .table-heading th {
  font-size: 14px;
  color: #7F8FA4;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file td .media .media-left img {
  width: 84px;
  height: 62px;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file td .media .media-body .media-heading {
  color: #354052;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file td .media .media-body .media-heading .sub-text {
  color: #7F8FA4;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file .created {
  font-size: 12px;
  color: #7F8FA4;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file .quality i {
  color: #F8CD51;
  font-size: 12px;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-table .manage-file .quality .no-color {
  color: #BBBFC5;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-footer {
  background: #F0F3F8;
  padding: 12px 30px;
  border-top: 0px;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-footer .btn-cancel {
  background-image: linear-gradient(-180deg, #FFFFFF 3%, #F2F4F7 98%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
  height: 36px;
  color: #7383A1;
}
.filemanager-modal .manage-data .manage-section .manage-table .file-manage-footer .btn-confirm {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  border-radius: 4px;
  height: 36px;
  color: #FFFFFF;
}
.activityprofile-modal .activity-panel {
  width: 830px;
  font-family: 'Source Sans Pro', sans-serif;
}
.activityprofile-modal .user-activities {
  padding: 0px;
}
.activityprofile-modal .user-activities .activity-block {
  background: #2EA1F8;
  padding: 17px 30px;
}
.activityprofile-modal .user-activities .activity-block img {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -17px;
  border-radius: 50%;
}
.activityprofile-modal .user-activities .activity-block span {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  margin-left: 85px;
}
.activityprofile-modal .user-activities .activity-block .close-modal i {
  color: #70BBFB;
}
.activityprofile-modal .user-activities .activities-list {
  background-color: #222C3C;
  margin-left: 0px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0px;
}
.activityprofile-modal .user-activities .activities-list li {
  margin-left: 15px;
  margin-right: 15px;
  text-transform: capitalize;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 3px solid transparent;
}
.activityprofile-modal .user-activities .activities-list li:last-child {
  margin-right: 7px;
}
.activityprofile-modal .user-activities .activities-list li a {
  color: #848C98;
  padding: 0px;
  border-radius: 0px;
  padding-top: 14px;
  padding-bottom: 11px;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 400;
}
.activityprofile-modal .user-activities .activities-list .dots i {
  line-height: 47px;
  font-size: 12px;
  color: #848C98;
  margin-right: 15px;
  text-decoration: none;
}
.activityprofile-modal .user-activities .activities-list li.active {
  border-bottom: 3px solid #2EA1F8;
}
.activityprofile-modal .user-activities .activities-list li.active a {
  border-radius: 0px;
  color: #FFFFFF;
}
.activityprofile-modal .user-activities .activities-list .options-menu {
  right: 0px;
  left: auto;
  top: 103px;
}
.activityprofile-modal .user-activities .user-overview {
  margin-left: 0px;
  margin-right: 0px;
  background: #F4F6FA;
  padding: 30px 30px 20px 30px;
  display: table;
}
.activityprofile-modal .user-activities .user-overview .activities-section .left-panel {
  height: 100%;
  display: table-cell;
  float: none;
  padding-left: 0px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .right-panel {
  height: 100%;
  display: table-cell;
  float: none;
  padding-right: 0px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-left-panel {
  max-height: 505px;
  overflow-y: auto;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  padding: 15px 20px;
  background-image: linear-gradient(0deg, #F4F7FA 0%, #FFFFFF 100%);
  border-radius: 0px 0px 4px 4px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading .follow {
  margin-top: -9px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading .follow a {
  color: #7F8FA4;
  text-decoration: none;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-heading .follow li.active a {
  color: #354052;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .activity-content {
  padding: 0px;
  margin-bottom: 0px;
  border: 0px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list {
  padding: 0px 20px;
  margin-bottom: 0px;
  background: #FFFFFF;
  border-radius: 0px 0px 4px 4px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list li:last-child {
  border-bottom: 0px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item {
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent;
  border-bottom: 2px solid #F2F3F5;
  padding-left: 0px;
  padding-right: 0px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-left a img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 7px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body .media-heading {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  margin-bottom: 15px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body .user-post-pics {
  margin-bottom: 0px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .media .media-body .user-post-pics li img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .panel-icon {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  text-decoration: none;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .panel-icon i {
  margin-right: 15px;
  color: #C2C2C2;
  font-size: 19px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .integrated {
  text-decoration: none;
  font-size: 14px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item .public {
  color: #36AF47;
  font-size: 14px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-twitter {
  color: #00AAFF;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-instagram {
  color: #125688;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-spotify {
  color: #2ebd59;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .panel-group-item:hover .panel-icon .icon-apple {
  color: #464646;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox {
  margin-top: 0px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox input[type="radio"],
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox input[type="checkbox"] {
  margin-top: 2px;
  margin-right: 4px;
}
.activityprofile-modal .user-activities .user-overview .activities-section .user-activity-panel .panel-posts-list .checkbox .appear {
  font-size: 14px;
  font-weight: 400;
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
}
.teamcommunication-modal .team-panel {
  width: 870px;
}
.teamcommunication-modal .team-data {
  padding: 0px;
}
.teamcommunication-modal .team-data .team-section {
  background: #2EA1F8;
  margin-left: 0px;
  margin-right: 0px;
}
.teamcommunication-modal .team-data .team-section .team-communicate {
  text-transform: capitalize;
  padding: 20px 30px;
  border-right: 1px solid #40A8F5;
}
.teamcommunication-modal .team-data .team-section .team-communicate .team-header {
  color: #FFFFFF;
}
.teamcommunication-modal .team-data .team-section .team-communicate .team-header .team-text {
  font-size: 18px;
  font-weight: 400;
}
.teamcommunication-modal .team-data .team-section .team-communicate .team-header .contact {
  margin-bottom: 0px;
}
.teamcommunication-modal .team-data .team-section .team-communicate .team-header .contact li a i {
  margin-left: 20px;
  color: #FFFFFF;
  font-size: 16px;
}
.teamcommunication-modal .team-data .team-section .team-members {
  padding: 20px;
  text-transform: capitalize;
}
.teamcommunication-modal .team-data .team-section .team-members .team-header {
  color: #FFFFFF;
}
.teamcommunication-modal .team-data .team-section .team-members .team-header .team-text {
  font-size: 18px;
  font-weight: 400;
}
.teamcommunication-modal .team-data .team-section .team-members .team-header .close-modal i {
  color: #70BBFB;
}
.teamcommunication-modal .team-data .communication-body {
  margin-left: 0px;
  margin-right: 0px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate {
  padding-left: 0px;
  padding-right: 0px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel {
  border-right: 1px solid #F0F3F8;
  margin-bottom: 0px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list {
  border-radius: 0px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item {
  border: 1px solid #F0F3F8;
  border-right: 0px;
  padding: 20px 30px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-left a img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 4px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body {
  color: #7F8FA4;
  font-size: 14px;
  font-weight: 400;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .media-heading {
  color: #354052;
  text-transform: capitalize;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .media-heading .time {
  color: #7F8FA4;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default {
  background: #F0F3F8;
  margin-top: 10px;
  height: 30px;
  font-size: 14px;
  border-radius: 4px;
  padding: 6px 9px 6px 10px;
  margin-bottom: 0px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default i {
  margin-right: 9px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default .remove .close {
  line-height: 0px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-posts-list .team-group-item .media .media-body .alert-default .remove .close i {
  color: #7F8FA4;
  font-size: 14px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer {
  background: #F0F3F8;
  padding: 21px 20px 19px 20px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group {
  margin-bottom: 0px;
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  background: #FFFFFF;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .msg-form {
  border-right: 0px;
  border: 1px solid #DFE3E9;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .btn-link {
  border-left: 0px!important;
  border: 1px solid #DFE3E9;
  background: #FFFFFF;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .btn-link i {
  color: #B4BFD4;
}
.teamcommunication-modal .team-data .communication-body .left-communicate .left-communicate-panel .team-footer .input-group .btn-link #my_file {
  display: none;
}
.teamcommunication-modal .team-data .communication-body .right-team {
  padding-left: 0px;
  padding-right: 0px;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list {
  margin-bottom: 0px;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list {
  border: 1px solid #F0F3F8;
  border-left: 0px;
  padding: 11px 15px;
  cursor: pointer;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list:hover {
  background: #F0F3F8;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left a img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 3px;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .user-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: #7E8CA0;
  float: left;
  position: absolute;
  left: 45px;
  bottom: 10px;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .online {
  background: #39B54A;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .busy {
  background: #FF7800;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-left .idle {
  background: #4FB7C5;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-body {
  padding-top: 10px;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-body .media-heading {
  color: #354052;
  font-size: 14px;
  font-weight: 400;
}
.teamcommunication-modal .team-data .communication-body .right-team .right-team-list .right-group-list .media .media-body .media-heading .team-posts {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #FE7800;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#ebebeb), to(#f5f5f5));
  background-image: -webkit-linear-gradient(top, #ebebeb 0, #f5f5f5 100%);
  background-image: -moz-linear-gradient(top, #ebebeb 0, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
}
.progress {
  height: 12px;
  background-color: #ebeef1;
  background-image: none;
  box-shadow: none;
}
.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width .6s ease;
  transition: width .6s ease;
}
.progress-bar {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#428bca), to(#3071a9));
  background-image: -webkit-linear-gradient(top, #428bca 0, #3071a9 100%);
  background-image: -moz-linear-gradient(top, #428bca 0, #3071a9 100%);
  background-image: linear-gradient(to bottom, #428bca 0, #3071a9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff3071a9', GradientType=0);
}
.progress-bar {
  box-shadow: none;
  border-radius: 3px;
  background-color: #0090D9;
  background-image: none;
  -webkit-transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.progress-bar-success {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#5cb85c), to(#449d44));
  background-image: -webkit-linear-gradient(top, #5cb85c 0, #449d44 100%);
  background-image: -moz-linear-gradient(top, #5cb85c 0, #449d44 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0, #449d44 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
}
.progress-bar-success {
  background-color: #0AA699;
  background-image: none;
}
.progress-bar-info {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#5bc0de), to(#31b0d5));
  background-image: -webkit-linear-gradient(top, #5bc0de 0, #31b0d5 100%);
  background-image: -moz-linear-gradient(top, #5bc0de 0, #31b0d5 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0, #31b0d5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
}
.progress-bar-info {
  background-color: #0090D9;
  background-image: none;
}
.progress-bar-warning {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#f0ad4e), to(#ec971f));
  background-image: -webkit-linear-gradient(top, #f0ad4e 0, #ec971f 100%);
  background-image: -moz-linear-gradient(top, #f0ad4e 0, #ec971f 100%);
  background-image: linear-gradient(to bottom, #f0ad4e 0, #ec971f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
}
.progress-bar-warning {
  background-color: #FDD01C;
  background-image: none;
}
.progress-bar-danger {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#d9534f), to(#c9302c));
  background-image: -webkit-linear-gradient(top, #d9534f 0, #c9302c 100%);
  background-image: -moz-linear-gradient(top, #d9534f 0, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0, #c9302c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
}
.progress-bar-danger {
  background-color: #F35958;
  background-image: none;
}
.progress-mini {
  height: 4px !important;
}
.progress-mini .progress-bar {
  border-radius: 1px;
}
.img-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.list-group-navigation .list-group-item {
  border: 1px solid #E6EAEE;
}
.list-group-navigation .list-group-item .list-icon {
  width: 40px;
  height: 40px;
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
  font-size: 16px;
  color: white;
  text-align: center;
  line-height: 40px;
  float: left;
}
.list-group-navigation .list-group-item .list-text {
  margin-left: 60px;
  color: #354052;
  font-weight: 600;
  text-transform: capitalize;
}
.list-group-navigation .list-group-item .list-text .text-muted {
  font-weight: 400;
}
.list-group-navigation .list-group-item:hover {
  background-color: #F2F4F7;
}
.user-follow .btn {
  padding: 6px 15px;
}
.user-follow .media-heading {
  font-size: 16px;
  margin-bottom: 0px;
  display: inline-block;
}
.user-follow.socialite .media-left img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.user-follow.socialite .media-body .media-heading {
  max-width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 398px) {
  .user-follow.socialite .media-body .media-heading {
    max-width: 120px;
  }
}
@media (max-width: 350px) {
  .user-follow.socialite .media-body .media-heading {
    max-width: 110px;
  }
}
@media (max-width: 398px) {
  .user-follow.socialite .media-body .btn.unfollow {
    padding: 4px 7px;
  }
}
.p-t-30 {
  padding-top: 30px;
}
.panel-dark {
  background-color: #222C3C;
  border-radius: 4px;
}
.sort-panel {
  border: 0px;
}
.sort-panel .search-form-box {
  padding: 5px;
}
.sort-panel .search-form-box form .radio-inline {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
  margin-top: -13px;
  font-family: 'Source Sans Pro', sans-serif !important;
  letter-spacing: 0.6px;
  text-transform: capitalize;
}
.sort-panel .search-form-box form .radio-inline .select {
  margin-top: 0px;
}
.sort-panel .search-form-box form .sort-form {
  margin-bottom: 10px;
}
.sort-panel .search-form-box form .sort-form .sort-radio {
  margin: 0px;
}
.sort-panel .search-form-box form .sort-form .sort-check {
  margin: 0px;
}
.sort-panel .search-form-box form .sort-form .sort-check .sort-select {
  font-size: 14px;
  color: #FFFFFF;
  text-transform: none;
  font-weight: 400;
}
.sort-panel .search-form-box form .sort-form .sort-radio {
  margin: 0px;
}
.sort-panel .search-form-box form .sort-form .sort-radio .sort-check {
  font-size: 14px;
  margin-right: 17px;
  color: #FFFFFF;
  text-transform: none;
  font-weight: 400;
}
.sort-panel .search-form-box form .form-group .selection {
  margin-bottom: 0px;
}
.sort-panel .search-form-box form .form-group .search-group {
  margin-bottom: 1px;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
}
.sort-panel .search-form-box form .form-group .search-group .form-icon {
  border-radius: 4px;
  height: 36px;
  border-color: #FFFFFF;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background: #FFFFFF;
  border-right: 0px;
}
.sort-panel .search-form-box form .form-group .search-group .form-icon i {
  color: #BBC5D5;
}
.sort-panel .search-form-box form .form-group .search-group .search-form {
  height: 36px;
  border-color: #FFFFFF;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 0px;
  border-left: 0px;
  background: #FFFFFF;
}
.sort-panel .search-form-box .select-option {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif !important;
  text-transform: capitalize;
  letter-spacing: 0.6px;
  cursor: pointer;
}
.sort-panel .search-form-box .select-option input[type="checkbox"] {
  margin-right: 9px;
}
.sort-panel .search-form-box .btn-primary {
  height: 36px;
  width: 100%;
  margin-top: 3px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.sort-panel .search-form-box .btn-primary i {
  font-size: 16px;
  margin-right: 7px;
  color: #FFFFFF;
}
.sort-panel .search-form-box .btn-primary span {
  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: 0.6px;
  font-family: 'Source Sans Pro', sans-serif;
}
.revenue-panel {
  padding-bottom: 0px;
}
.revenue-block {
  padding-right: 14px;
  padding-left: 14px;
  background-color: #FFFFFF;
}
.revenue-block .monthly-revenue {
  position: relative;
}
.revenue-block .monthly-revenue .revenue-head {
  color: #354052;
  font-size: 24px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin-top: 10px;
}
.revenue-block .monthly-revenue .btn-month {
  margin-top: 7px;
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 6px 8px 7px 16px;
  font-weight: 400;
  margin-right: 3px;
  color: #354052;
}
.revenue-block .monthly-revenue .btn-month span {
  margin-left: 8px;
}
.revenue-block .monthly-revenue .btn-month span i {
  color: #A8AAB7;
}
.revenue-block .monthly-revenue .month-dropdown {
  right: 0px;
  top: 40px;
  left: auto;
}
.revenue-block .revenue-spending .revenue {
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.6px;
  font-weight: 400;
  margin-top: 15px;
}
.revenue-block .revenue-spending .revenue .income {
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #354052;
  font-weight: 400;
  display: block;
}
.revenue-block .revenue_progress {
  margin-top: 8px;
  height: 36px;
  border-radius: 4px;
  background-image: linear-gradient(-1deg, #445C83 2%, #5684AF 98%);
}
.revenue-block .revenue_progress .progress-bar {
  background-image: linear-gradient(-1deg, #2BB415 2%, #5AD946 98%);
  border-radius: 4px 0px 0px 4px;
}
.revenue-block .budget-spending {
  border-top: 1px solid #E6EAEE;
  padding-top: 14px;
}
.revenue-block .budget-spending .budget {
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #7F8FA4;
  text-transform: capitalize;
  letter-spacing: 0.6px;
  font-weight: 400;
}
.revenue-block .budget-spending .budget .income {
  font-size: 18px;
  display: block;
  color: #354052;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
.revenue-block .budget_progress {
  margin-top: 8px;
  height: 36px;
  border-radius: 4px;
  background: #E2E7EE;
}
.revenue-block .budget_progress .budget-bar {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px 0px 0px 4px;
}
.last-month-income {
  background-color: #FAFBFC;
  border-top: 1px solid #E6EAEE;
}
.previous-month-details {
  padding: 13px 15px 20px 15px;
}
.previous-month-details .previous-month {
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.6px;
  font-weight: 400;
  margin-top: 4px;
}
.previous-month-details .previous-month-income {
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 5px;
  display: flex;
  margin-left: 0px;
  justify-content: space-between;
}
.previous-month-details .previous-month-income .month_income {
  font-size: 14px;
  color: #354052;
  text-transform: capitalize;
  letter-spacing: 0.6px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.previous-month-details .previous-month-income .income-details {
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  letter-spacing: 0.6px;
  font-weight: 400;
  text-align: center;
}
.previous-month-details .exmp {
  width: 100%;
}
.previous-month-details .last-month-progress {
  height: 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: -6px;
  background-image: linear-gradient(-1deg, #445C83 2%, #5684AF 98%);
}
.previous-month-details .last-month-progress .previous-month-bar {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px 0px 0px 4px;
}
.previous-month-details .last-month-budget-progress {
  height: 8px;
  border-radius: 4px;
  margin-bottom: 10px;
  background: #E2E7EE;
  margin-top: -4px;
}
.previous-month-details .last-month-budget-progress .previous-month-bar {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px 0px 0px 4px;
}
.product-sales-panel {
  padding: 27px 30px 30px 30px;
  border-top: 3px solid #1A91EB;
}
.product-sales-panel .product-sales .sales-header {
  color: #354052;
  font-size: 24px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  margin-top: -5px;
  margin-bottom: 22px;
  text-transform: capitalize;
}
.product-sales-panel .product-sales .product-sales-list {
  border-bottom: 1px solid #E6EAEE;
  margin-top: 11px;
}
.product-sales-panel .product-sales .product-sales-list li {
  margin-top: -11px;
}
.product-sales-panel .product-sales .product-sales-list li:last-child .progress {
  margin-bottom: 15px;
}
.product-sales-panel .product-sales .product-sales-list .product-name {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 7px;
  color: #354052;
}
.product-sales-panel .product-sales .product-sales-list .product-name .circle-primary {
  height: 10px;
  width: 10px;
  float: left;
  border: 2px solid #1AA6D6;
  border-radius: 50%;
  margin-top: 2px;
}
.product-sales-panel .product-sales .product-sales-list .product-name .circle-purple {
  height: 10px;
  width: 10px;
  float: left;
  border: 2px solid #8E76E4;
  border-radius: 50%;
  margin-top: 2px;
}
.product-sales-panel .product-sales .product-sales-list .product-name .circle-success {
  height: 10px;
  width: 10px;
  float: left;
  border: 2px solid #49C834;
  border-radius: 50%;
  margin-top: 2px;
}
.product-sales-panel .product-sales .product-sales-list .product-name .circle-yellow {
  height: 10px;
  width: 10px;
  float: left;
  border: 2px solid #FAC942;
  border-radius: 50%;
  margin-top: 2px;
}
.product-sales-panel .product-sales .product-sales-list .product-name span {
  margin-left: 9px;
}
.product-sales-panel .product-sales .product-sales-list .sales-count {
  color: #7F8FA4;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 10px;
}
.product-sales-panel .product-sales .product-sales-list .progress {
  height: 18px;
  background: #E2E7EE;
  border-radius: 10px;
  margin-top: 3px;
}
.product-sales-panel .product-sales .product-sales-list .progress .progress-primary {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 10px;
}
.product-sales-panel .product-sales .product-sales-list .progress .progress-purple {
  background-image: linear-gradient(-180deg, #9D90E4 2%, #8261E6 98%);
  border-radius: 10px;
}
.product-sales-panel .product-sales .product-sales-list .progress .progress-success {
  background-image: linear-gradient(-1deg, #2BB415 2%, #5AD946 98%);
  border-radius: 10px;
}
.product-sales-panel .product-sales .product-sales-list .progress .progress-yellow {
  background-image: linear-gradient(-1deg, #FDC018 2%, #F8CF5D 98%);
  border-radius: 10px;
}
.product-sales-panel .product-sales .sales-range {
  display: flex;
  justify-content: space-around;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -5px;
}
.product-sales-panel .product-sales .sales-range li {
  text-align: justify;
  color: #354052;
  font-size: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.election-card {
  position: relative;
}
.election-card .customer-section {
  margin-bottom: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.election-card .customer-section .customers-panel {
  padding: 5px 20px 6px 20px;
}
.election-card .customer-section .customers-panel .customer-block .customer-head {
  font-size: 20px;
  color: #354052;
  font-weight: 400;
  margin-top: -9px;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.6px;
}
.election-card .customer-section .customers-panel .customer-block .customers-count {
  font-size: 30px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #354052;
  font-weight: 400;
  margin-top: -13px;
}
.election-card .customer-section .customers-panel .customer-block .customers-percentage {
  color: #3FC529;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: -27px;
  font-family: 'Source Sans Pro', sans-serif;
}
.election-card .customer-section .customers-panel .customer-block .customers-list {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 1px;
}
.election-card .customer-section .customers-panel .customer-block .customers-list li {
  text-align: justify;
}
.election-card .customer-section .customers-panel .customer-block .customers-list li .circle-new {
  border: 2px solid #1AA6D6;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.election-card .customer-section .customers-panel .customer-block .customers-list li .circle-return {
  border: 2px solid #40557D;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.election-card .customer-section .customers-panel .customer-block .customers-list li .circle-referal {
  border: 2px solid #FAC942;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.election-card .customer-section .customers-panel .customer-block .customers-list li span {
  font-size: 14px;
  font-weight: 600;
  color: #354052;
}
.election-card .customer-referrals {
  background-color: #FAFBFC;
  margin-bottom: 20px;
  padding: 11px 15px 15px 15px;
  border: 1px solid #e6eaee;
  border-top-width: 0px;
  position: relative;
}
.election-card .customer-referrals .referral-heading {
  margin-top: 0px;
  font-size: 15px;
  margin-bottom: 0px;
}
.election-card .customer-referrals .referral-heading b {
  text-transform: capitalize;
}
.election-card .customer-referrals .election-date {
  color: #7F8FA4;
  font-size: 13px;
  line-height: 23px;
}
.election-card .customer-referrals .election-date i {
  margin-right: 4px;
}
.election-card .customer-referrals .btn-default {
  padding: 1px 6px;
}
.election-card .customer-referrals .btn-default i {
  font-size: 12px;
}
.election-card .customer-referrals .btn-left,
.election-card .customer-referrals .btn-right {
  position: absolute;
  margin-bottom: -12px;
  bottom: 50%;
}
.election-card .customer-referrals .btn-left {
  left: 15px;
}
.election-card .customer-referrals .btn-right {
  right: 15px;
}
@media (min-width: 991px) and (max-width: 1200px) {
  .election-card .customer-referrals .btn-left {
    margin-left: -12px;
  }
  .election-card .customer-referrals .btn-right {
    margin-right: -12px;
  }
}
.election-card .electioncard-loader {
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  display: none;
  width: 100%;
  height: 100%;
}
.election-card .electioncard-loader .fa-spinner {
  position: absolute;
  top: 50%;
  margin-left: -17px;
  margin-top: -17px;
  font-size: 35px;
  animation: spin 2s linear infinite;
  color: #1991EB;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.election-card.loading .electioncard-loader {
  display: block;
}
.election-card.loading .customer-referrals {
  opacity: 0.4;
}
.election-card.loading .customer-section {
  opacity: 0.4;
}
.spending-panel {
  padding: 30px;
}
.spending-panel .spending-block .spending-head {
  font-size: 24px;
  color: #354052;
  font-weight: 400;
  margin-top: -6px;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.6px;
}
.spending-panel .spending-block .spenders-count {
  font-size: 24px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #1A91EB;
  font-weight: 400;
  margin-top: -9px;
}
.spending-panel .spending-block #char {
  margin-top: -21px;
}
.spending-panel .spending-block .spending-progress-table {
  margin-top: 10px;
  margin-bottom: -11px;
}
.spending-panel .spending-block .spending-progress-table .spend-for {
  font-size: 16px;
  color: #354052;
  font-weight: 600;
  padding-top: 7px;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: capitalize;
}
.spending-panel .spending-block .spending-progress-table .spend-for .food-share {
  border: 2px solid #40557D;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.spending-panel .spending-block .spending-progress-table .spend-for .family-share {
  border: 2px solid #FAC942;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.spending-panel .spending-block .spending-progress-table .spend-for .company-share {
  border: 2px solid #1AA6D6;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.spending-panel .spending-block .spending-progress-table .spend-for .stocks-share {
  border: 2px solid #F0B499;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.spending-panel .spending-block .spending-progress-table .spend-for .travel-share {
  border: 2px solid #72C4B9;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.spending-panel .spending-block .spending-progress-table .spend-for .other-share {
  border: 2px solid #289DF5;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.spending-panel .spending-block .spending-progress-table .spent-percentage {
  color: #7F8FA4;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: 13px;
  font-weight: 400;
  float: left;
  margin-bottom: 7px;
}
.daily-sales-section {
  margin-bottom: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.daily-sales-section .sales-panel {
  padding: 29px 29px 30px 29px;
}
.daily-sales-section .sales-panel .sales-block .sales-head {
  font-size: 20px;
  color: #354052;
  font-weight: 400;
  margin-top: -9px;
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.6px;
}
.daily-sales-section .sales-panel .sales-block .sales-count {
  font-size: 30px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #354052;
  font-weight: 400;
  margin-top: -13px;
}
.daily-sales-section .sales-panel .sales-block .sales-percentage {
  color: #3FC529;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: -27px;
  font-family: 'Source Sans Pro', sans-serif;
}
.daily-sales-section .sales-panel .sales-block .daily-sales-progress .progress-bar-vertical {
  width: 3px;
  min-height: 100px;
  display: flex;
  align-items: flex-end;
  margin-right: 5px;
  background: transparent;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  float: left;
}
.daily-sales-section .sales-panel .sales-block .daily-sales-progress .progress-bar-vertical .progress-bar {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.product-image img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.related-box {
  padding: 10px;
}
.related-box .productivity-heading h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 5px;
}
.related-box .productivity-sub {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #7F8FA4;
}
.related-box .productivity-text {
  margin-top: 6px;
  border-bottom: 1px solid #E6EAEE;
  margin-bottom: 15px;
}
.related-box .productivity-text p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  margin-bottom: 13px;
}
.related-box .btn-buy-class {
  text-transform: none;
  margin-top: 2px;
  padding: 7px 18px;
}
.mini-card {
  padding: 7px 5px 4px 5px;
}
.mini-card .media-actions {
  float: right;
}
.mini-card .media-actions li:last-child {
  padding-right: 10px;
}
.mini-card .media-actions li a {
  color: #CCD3DA;
  font-size: 16px;
}
.mini-card .media-actions li .dropdown-menu {
  right: -20px;
  left: initial;
}
.mini-card .media-left {
  padding-right: 20px;
}
.mini-card .media,
.mini-card .media-body {
  overflow: visible;
}
.mini-card .media-body .media-heading {
  text-transform: capitalize;
  margin-bottom: 1px;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #354052;
}
.mini-card .media-body .designation {
  font-size: 12px;
  color: #7F8FA4;
  line-height: 19px;
}
.mini-card .image-gallery {
  margin-top: 20px;
}
.mini-card .image-gallery .image-row {
  margin-left: -5px;
  margin-right: -5px;
}
.mini-card .image-gallery .image-col {
  padding-left: 5px;
  padding-right: 5px;
}
.mini-card .image-gallery .image-col img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.btn-setting {
  float: right;
  margin-top: 5px;
}
.btn-setting a i {
  font-size: 18px;
  color: #C5D0DE;
}
.btn-setting .dropdown-menu {
  left: auto;
  right: 0px;
}
.skill-col {
  border-left: 1px solid #E6EAEE;
}
.list-group-item.skill {
  border: 0px;
  border-radius: 0px;
  padding-top: 5px;
}
.list-group-item.skill:first-child {
  padding-top: 17px;
}
.list-group-item.skill:last-child {
  padding-bottom: 22px;
}
.list-group-item.skill .left-side {
  float: left;
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  font-weight: 600;
}
.list-group-item.skill .left-side span {
  display: block;
  font-size: 18px;
  color: #354052;
  margin-top: -5px;
}
.list-group-item.skill .right-side {
  margin-left: 100px;
  position: relative;
  margin-top: 15px;
}
.list-group-item.skill .right-side .progress-tool {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  border: 2px solid #fff;
  top: 50%;
  margin-top: -18px;
  left: -19px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}
.list-group-item.skill .right-side .progress {
  margin-bottom: 0px;
  height: 14px;
  background-color: #E2E7EE;
  border-radius: 7px;
}
.list-group-item.skill .right-side .progress .progress-bar {
  border-radius: 7px;
}
.list-group-item.skill .right-side .blue {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
}
.list-group-item.skill .right-side .purple {
  background-image: linear-gradient(-180deg, #9D90E4 2%, #8261E6 98%);
}
.list-group-item.skill .right-side .green {
  background-image: linear-gradient(-1deg, #2BB415 2%, #5AD946 98%);
}
.list-group-item.skill .right-side .yellow {
  background-image: linear-gradient(-1deg, #FDC018 2%, #F8CF5D 98%);
}
.list-group-item.skill .right-side .voilet {
  background-image: linear-gradient(-1deg, #445C83 2%, #5684AF 98%);
}
.skills-list .list-group-item {
  border: 0px;
  border-radius: 0px;
  border-top: 1px solid #E6EAEE;
  margin-left: 15px;
  margin-right: 15px;
  text-transform: capitalize;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 600;
  color: #354052;
}
.skills-list .list-group-item span {
  float: right;
  color: #7F8FA4;
  font-size: 14px;
}
.skills-list .list-group-item:first-child {
  border-top: 0px;
}
.skills-list .list-group-item:last-child {
  padding-bottom: 22px;
}
.skill-panel .panel-heading {
  height: auto;
  padding-top: 17px;
  padding-bottom: 6px;
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  font-weight: 600;
}
.media.post-list {
  border-bottom: 1px solid #E3E8EF;
  padding: 15px;
  padding-left: 0px;
  margin: 0px;
  overflow: visible;
}
.media.post-list .post-time {
  float: right;
  font-size: 14px;
  color: #7F8FA4;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.media.post-list .media-body {
  padding-left: 5px;
  overflow: visible;
}
.media.post-list .media-body .media-heading {
  margin-bottom: 0px;
}
.media.post-list .media-body .media-heading a {
  color: #354052;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
}
.media.post-list .media-body .media-heading .text-muted {
  margin-left: 5px;
  color: #7F8FA4;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}
.media.post-list .media-body .post-text {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
.media.post-list .media-body .post-pictures {
  margin-top: 12px;
  margin-bottom: 15px;
}
.media.post-list .media-body .post-pictures .post-row {
  margin-left: -5px;
  margin-right: -3px;
}
.media.post-list .media-body .post-pictures .post-row .image-col {
  padding-left: 5px;
  padding-right: 5px;
}
.media.post-list .media-body .post-pictures .post-row .image-col img {
  width: 100%;
  height: auto;
}
.media.post-list .media-body .post-actions {
  margin-top: 5px;
}
.media.post-list .media-body .post-actions .leftside-list {
  float: left;
  margin-bottom: 0px;
}
.media.post-list .media-body .post-actions .leftside-list li {
  padding-left: 15px;
  padding-right: 15px;
}
.media.post-list .media-body .post-actions .leftside-list li a {
  text-decoration: none;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #7F8FA4;
}
.media.post-list .media-body .post-actions .leftside-list li a span {
  font-size: 16px;
  color: #AEB6C5;
  margin-right: 3px;
}
.media.post-list .media-body .post-actions .leftside-list li a span .fa-heart {
  color: #DF0C0C;
}
.media.post-list .media-body .post-actions .leftside-list li.active .favourite {
  color: #DF0C0C;
}
.media.post-list .media-body .post-actions .leftside-list li.active .favourite span {
  color: #DF0C0C;
}
.media.post-list .media-body .post-actions .leftside-list li.active .share {
  color: #1BB934;
}
.media.post-list .media-body .post-actions .leftside-list li.active .share span {
  color: #1BB934;
}
.media.post-list .media-body .post-actions .rightside-action {
  float: right;
  margin-top: 4px;
  margin-right: 5px;
}
.media.post-list .media-body .post-actions .rightside-action i {
  font-size: 16px;
  color: #CCD3DA;
}
.media.post-list .media-body .post-actions .rightside-action .dropdown-menu {
  left: auto;
  right: 0px;
}
.cover-section {
  padding: 10px;
  text-align: center;
}
.cover-section .cover-image {
  overflow: hidden;
  height: 120px;
  width: 100%;
  position: relative;
}
.cover-section .cover-image img {
  width: 100%;
  height: auto;
}
.cover-section .user-avatar {
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -78px;
}
.cover-section .user-avatar img {
  height: 156px;
  width: 156px;
}
.cover-section .user-name {
  padding-top: 91px;
}
.cover-section .user-name a {
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #354052;
  text-decoration: none;
}
.cover-section .user-name .user-details-list {
  margin-top: 5px;
}
.cover-section .user-name .user-details-list li {
  line-height: 15px;
}
.cover-section .user-name .user-details-list li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.cover-section .user-name .user-details-list li:first-child {
  border-right: 2px solid #7F8FA4;
  margin-right: -4px;
}
.user-information-section {
  padding: 30px;
  padding-top: 6px;
}
.user-information-section .detail-box {
  border-top: 1px solid #E6EAEE;
  padding-top: 22px;
}
.user-information-section .detail-box .user-identity {
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #7F8FA4;
}
.user-information-section .detail-box .user-detail {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #354052;
  line-height: 19px;
  margin-bottom: 8px;
  margin-top: 1px;
}
.user-information-section .detail-box .user-bio {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  line-height: 19px;
  margin-bottom: 15px;
  margin-top: 5px;
}
.user-information-section .detail-box .paid-status {
  margin-top: 5px;
}
.user-information-section .detail-box .paid-status .progress {
  height: 4px;
  margin-right: 79px;
  background-color: #DFE4EE;
  border-radius: 18.62px;
  margin-bottom: 17px;
}
.user-information-section .detail-box .paid-status .progress .progress-bar {
  background-color: #1A91EB;
  border-radius: 18.62px;
}
.user-information-section .detail-box .paid-status .paid-money {
  float: right;
  margin-top: -28px;
  font-size: 14px;
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.user-information-section .detail-box .paid-status .paid-money span {
  color: #1A91EB;
}
.user-information-section .detail-box .skills-list {
  margin-top: 5px;
  margin-bottom: 0px;
}
.user-information-section .detail-box .skills-list li {
  padding-left: 3px;
  padding-right: 3px;
}
.user-information-section .detail-box .skills-list li:first-child {
  padding-left: 0px;
}
.user-information-section .detail-box .skills-list li:last-child {
  padding-right: 0px;
}
.user-information-section .detail-box .skills-list li .label {
  border-radius: 3px;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.products-table {
  margin-bottom: 0px;
}
.products-table tr .bullet {
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
  margin-top: -3px;
}
.products-table tr .light-blue {
  background-color: #2297F0;
}
.products-table tr .dark-blue {
  background-image: linear-gradient(-180deg, #337BBF 0%, #34659F 100%);
}
.products-table tr:first-child td {
  border-top: 0px;
}
.products-table tr td {
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding-top: 13px !important;
  padding-bottom: 17px !important;
}
.products-table tr td a {
  color: #354052;
  text-decoration: none;
}
.products-table tr td span {
  display: block;
  font-weight: 400;
  color: #7F8FA4;
  margin-top: 3px;
}
.products-table tr td .target {
  float: left;
}
.products-table tr td .count {
  float: right;
  color: #354052;
  font-weight: 600;
}
.products-table tr td .progress {
  margin: 0px;
  height: 4px;
  margin-top: 11px;
  margin-bottom: 5px;
  background-color: #DFE4EE;
  border-radius: 2px;
}
.products-table tr td .progress .progress-bar {
  background-color: #1A91EB;
  border-radius: 2px;
}
.heading-list {
  margin: 0px;
  padding: 0px 0px 0px 0px;
}
.heading-list li {
  line-height: 23px;
}
.heading-list li:first-child a {
  padding-left: 30px;
}
.heading-list li a {
  padding: 27px 30px 20px  25px;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
}
.heading-list li a span {
  font-size: 14px;
  color: #7F8FA4;
  margin-left: 10px;
}
.heading-list .divider {
  border-left: 1px solid #E6EAEE;
  margin-top: 30px;
  height: 35px;
}
.heading-list li.active,
.heading-list li:hover {
  border-bottom: 3px solid #2EA2F8;
}
.heading-list li.active a,
.heading-list li:hover a {
  color: #354052 !important;
}
.messages-page .panel-heading.user-pages {
  border-bottom-width: 0px !important;
}
.messages-page .panel-body .message-col-4 {
  padding-right: 0px;
  border-right: 1px solid #E6EAEE;
}
.messages-page .panel-body .message-col-4 ul {
  margin-bottom: 0px;
}
.messages-page .panel-body .message-col-4 .media {
  overflow: hidden;
}
.messages-page .panel-body .message-col-4 .media .media-body {
  overflow: hidden;
}
.messages-page .panel-body .message-col-4 .input-group {
  padding: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #DFE3E9 !important;
  margin-bottom: 0px;
}
.messages-page .panel-body .message-col-4 .input-group .form-control {
  height: 36px;
  border-right: 0px;
  box-shadow: none;
  outline: none;
  border-color: #DFE3E9;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 0px;
}
.messages-page .panel-body .message-col-4 .input-group .input-group-btn .btn {
  height: 36px;
  border-left: 0px;
  background: none;
  box-shadow: none;
  outline: none;
  border-color: #DFE3E9;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.messages-page .panel-body .message-col-4 .input-group .input-group-btn .btn i {
  color: #7F8FA4;
  font-size: 16px;
}
.messages-page .panel-body .message-col-8 {
  padding-left: 0px;
}
.messages-page .panel-body .message-col-8 .conversation .left-side {
  float: left;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 18px;
  color: #354052;
  padding-top: 21px;
  padding-left: 20px;
}
.messages-page .panel-body .message-col-8 .conversation .left-side .chat-status {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #1BB934;
  display: inline-block;
  margin-left: 4px;
}
.messages-page .panel-body .message-col-8 .conversation .right-side {
  float: right;
  padding-top: 19px;
}
.messages-page .panel-body .message-col-8 .conversation .right-side .primary-list > li {
  padding-left: 20px;
  padding-right: 18px;
}
.messages-page .panel-body .message-col-8 .conversation .right-side ul {
  margin-bottom: 0px;
  margin-top: 0px;
}
.messages-page .panel-body .message-col-8 .conversation .right-side .pagination li a {
  background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
}
.messages-page .panel-body .message-col-8 .conversation .right-side .pagination li a i {
  color: #7F8FA4;
  font-size: 18px;
  vertical-align: middle;
}
.messages-page .panel-body .message-col-8 .conversation .right-side .pagination.latest li a i {
  color: #CED0DA;
}
.messages-page .panel-body .message-col-8 .conversation .right-side .divider {
  background: #E6EAEE;
  width: 1px;
  height: 34px;
  padding: 0px !important;
  vertical-align: top;
  margin-left: 4px;
}
.messages-page .panel-body .message-col-8 .conversation .right-side .page-number {
  vertical-align: top;
  margin-top: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #7F8FA4;
  font-weight: 600;
}
.messages-page .panel-body .coversations-thread {
  height: 607px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
}
.messages-page .panel-body .message-conversation {
  border-bottom: 0px;
  padding: 0px 30px 0px 15px;
}
.messages-page .panel-body .message-conversation .media.post-list {
  border-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 10px;
}
.messages-page .panel-body .message-conversation .post-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  line-height: 19px;
}
.messages-page .panel-body .message-conversation:first-child {
  padding-top: 5px;
}
.messages-page .panel-body .message-conversation .media-body {
  padding-left: 10px;
}
.messages-page .panel-body .message-conversation .media-body .media-heading a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}
.messages-page .panel-body .message-conversation .media-body .media-heading .text-muted {
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: 11px;
  float: right;
}
.messages-page .panel-body .message-conversation .media-body .media-heading .text-muted .timeago {
  font-size: 11px;
}
.messages-page .panel-body .message-conversation .media-body .post-text {
  font-family: 'Source Sans Pro', sans-serif;
}
.messages-page .panel-body .message-conversation .post-pictures {
  margin-bottom: 0px;
  padding-top: 5px;
  padding-left: 90px;
  padding-right: 30px;
}
.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col {
  position: relative;
  padding-left: 7px;
  padding-right: 7px;
}
.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-holder {
  background-color: #000;
  border-radius: 4px;
}
.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-holder img {
  width: 100%;
  height: auto;
}
.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-search {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 50%;
  margin-top: -25px;
  left: 50%;
  margin-left: -25px;
  text-align: center;
  line-height: 55px;
  display: none;
}
.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col .img-search i {
  color: #FFFFFF;
  font-size: 20px;
}
.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col:hover img {
  opacity: 0.5;
}
.messages-page .panel-body .message-conversation .post-pictures .post-row .image-col:hover .img-search {
  display: block;
}
.messages-page .panel-body .message-holder {
  padding: 15px 5px 15px 15px;
  position: relative;
  border-bottom: 1px solid #E6EAEE;
}
.messages-page .panel-body .message-holder:hover {
  background-color: #FAFBFC;
}
.messages-page .panel-body .message-holder .show-conversation {
  text-decoration: none;
}
.messages-page .panel-body .message-holder:last-child {
  border-bottom: 1px solid transparent;
}
.messages-page .panel-body .message-holder .media.post-list {
  border-bottom-width: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.messages-page .panel-body .message-holder .media.post-list .post-time {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: capitalize;
}
.messages-page .panel-body .message-holder .media.post-list .media-heading {
  font-family: 'Source Sans Pro', sans-serif;
  display: block;
  height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #00AAFF;
}
.messages-page .panel-body .message-holder .media.post-list .media-heading .chat-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00AAFF;
  margin-right: 5px;
  display: inline-block;
}
.messages-page .panel-body .message-holder .media.post-list .media-heading .text-muted {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: -4px;
}
.messages-page .panel-body .message-holder .media.post-list .media-heading span {
  margin-left: 0px;
}
.messages-page .panel-body .message-holder .post-text {
  padding-left: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  color: #354052;
  font-weight: 400;
  max-height: 42px;
  overflow: hidden;
  width: 180px;
  text-overflow: ellipsis;
  line-height: 20px;
  position: relative;
}
.messages-page .panel-body .unseen-messages {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  color: #fff;
  text-align: center;
  line-height: 24px;
  display: none;
  position: absolute;
  bottom: 27px;
  right: 20px;
}
.messages-page .panel-body .directing-div {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
}
.messages-page .panel-body .message-holder.active {
  border-left: 3px solid #2399F1;
  position: relative;
}
.messages-page .panel-body .message-holder.active .media-heading a {
  color: #00AAFF;
}
.messages-page .panel-body .message-holder.unseen-message .unseen-messages {
  display: inline-block;
}
.messages-page .panel-body .message-divider {
  border-bottom: 1px solid #E3E8EF;
  position: relative;
  margin-top: 26px;
  margin-bottom: 6px;
}
.messages-page .panel-body .message-divider .day {
  padding: 0px 13px 0px 14px;
  height: 52px;
  background-color: red;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #7F8FA4;
  line-height: 52px;
  text-align: center;
  position: absolute;
  top: -26px;
  left: 50%;
  margin-left: -43px;
  background-color: #fff;
}
.messages-page .panel-body .message-divider.blue {
  border-bottom: 1px solid #1A91EB;
}
.messages-page .panel-body .message-divider.blue .day {
  color: #1A91EB;
}
.messages-page .panel-body .new-message {
  padding: 14px 30px 20px 30px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background: white;
}
.messages-page .panel-body .new-message .input-group-btn .btn {
  height: 56px;
  width: 56px;
  line-height: 18px;
  border: 1px solid #CED0DA;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
  outline: none;
  font-size: 30px;
  padding: 0px;
  padding-bottom: 4px;
  color: #fff;
}
.messages-page .panel-body .new-message .input-group-btn .btn i {
  font-size: 20px;
}
.messages-page .panel-body .new-message .form-control {
  height: 56px;
  padding-top: 10px;
}
.messages-page .panel-body .new-message .form-control:focus {
  border: 1px solid #DFE3E9;
}
.messages-page .panel-body .message-box {
  border-top: 1px solid #E6EAEE;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin: 0px !important;
  width: 100%;
}
.messages-page .panel-body .message-box .message-col-4 {
  padding-left: 0px;
}
.messages-page .panel-body .message-box .message-col-4 .coversations-list {
  height: 597px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.panel-body.ongoing-projects {
  padding: 3px 30px 15px 29px;
}
.panel-body.ongoing-projects .table-heading {
  text-transform: capitalize;
  float: left;
}
.panel-body.ongoing-projects .projects-download {
  float: right;
  margin-top: 27px;
}
.panel-body.ongoing-projects .projects-download i {
  color: #AFB4BB;
  font-size: 16px;
}
.tabel-panel {
  margin: -20px;
}
.table.course-table {
  margin: 0px;
}
.table.course-table thead tr th {
  padding-top: 15px;
  padding-bottom: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
  background-color: #fff;
}
.table.course-table thead tr th:first-child {
  padding-left: 30px;
}
.table.course-table tbody tr td {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.table.course-table tbody tr td:first-child {
  padding-left: 30px;
}
.table.course-table tbody tr td a {
  color: #354052;
  text-decoration: none;
}
.table.course-table tbody tr td .percent {
  float: left;
  vertical-align: middle;
  margin-right: 11px;
}
.table.course-table tbody tr td .progress {
  height: 4px;
  margin-top: 7px;
  margin-bottom: 0px;
  background-color: #DFE4EE;
  border-radius: 2px;
}
.table.course-table tbody tr td .progress .progress-bar {
  background-color: #1A91EB;
  border-radius: 2px;
}
.table.course-table tbody tr td ul {
  margin-bottom: 0px;
}
.table.course-table tbody tr td ul li a i {
  color: #C5D0DE;
  font-size: 16px;
}
.table.course-table tbody tr td ul li .dropdown-menu {
  left: auto;
  right: 0px;
}
.table.course-table tbody tr .status .status-circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  margin-right: 9px;
  vertical-align: text-top;
  margin-top: 3px;
}
.table.course-table tbody tr .status.blue .status-circle {
  border: 2px solid #1A91EB;
}
.table.course-table tbody tr .status.green .status-circle {
  border: 2px solid #84B800;
}
.table.course-table tbody tr .status.purple .status-circle {
  border: 2px solid #BF8FE1;
}
.table.course-table tbody tr .status.red .status-circle {
  border: 2px solid#F86B67;
}
.table.course-table tbody tr .report a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.table.course-table tbody tr .report a i {
  color: #C5D0DE;
  font-size: 16px;
  margin-right: 8px;
}
.table.course-table tbody tr .regular-font {
  font-weight: 400;
}
.image-detail-page .heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400 !important;
}
.image-detail-page .user-details .user-name {
  font-family: 'Source Sans Pro', sans-serif;
}
.image-detail-page .user-details .user-role {
  font-family: 'Source Sans Pro', sans-serif;
}
.image-detail-page .image-detail-section {
  background-color: #EFF3F6;
}
.image-detail-page .image-detail-section .images-block {
  padding: 15px 15px 4px 15px;
}
.image-detail-page .image-detail-section .images-block .col-md-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.imagedetail-cover {
  width: 100%;
  height: 380px;
  padding: 0px;
}
.imagedetail-cover .cover-pic {
  width: 100%;
  height: 320px;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 52%, #000000 100%);
  position: relative;
}
.imagedetail-cover .cover-pic img {
  width: 100%;
  height: 100%;
}
.imagedetail-cover .cover-pic .label {
  color: #fff;
  padding: 6px 12px;
  position: absolute;
  top: 21px;
  right: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.imagedetail-cover .cover-pic .bottom-cover-pic {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .leftside {
  float: left;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 26px;
  color: #FFFFFF;
  text-transform: capitalize;
  margin-left: 15px;
  margin-top: 26px;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside {
  float: right;
  text-align: right;
  margin-right: 15px;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside span {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  color: #FFFFFF;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list {
  margin-bottom: 19px;
  margin-top: 2px;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li {
  padding-left: 2px;
  padding-right: 2px;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li:first-child {
  margin-right: 12px;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li .btn-success {
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li .btn-friends {
  width: 40px;
  padding: 0px;
  height: 40px;
  border-radius: 50%;
}
.imagedetail-cover .cover-pic .bottom-cover-pic .rightside .rightside-list li .btn-friends img {
  width: 100%;
  height: 100%;
}
.navbar-tabs {
  background-color: #fff;
  margin-bottom: 20px;
}
.navbar-tabs .btn-group {
  float: left;
  margin: 15px 20px 0px 15px;
}
.navbar-tabs .btn-group .btn {
  line-height: 18px;
}
.navbar-tabs .btn-group .btn i {
  font-size: 16px;
  color: #CED0DA;
}
.navbar-tabs .left-list {
  margin: 0px;
  margin-left: 10px;
}
.navbar-tabs .left-list li {
  border-right: 1px solid #DFE3E9;
  margin-top: 15px;
  padding: 5px 14px 3px 14px;
}
.navbar-tabs .left-list li a i {
  color: #CED0DA;
  font-size: 25px;
}
.navbar-tabs .left-list li:first-child {
  padding-left: 8px;
}
.navbar-tabs .left-list li:last-child {
  border-right: 0px;
}
.navbar-tabs .left-list li .nstSlider {
  height: 2px;
  background-color: #CED0DA;
  border-radius: 1px;
  width: 96px;
  margin-top: -6px;
}
.navbar-tabs .left-list li .nstSlider .leftGrip {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  height: 10px;
  width: 10px;
  bottom: -4px;
}
.navbar-tabs .mid-list {
  margin: 0px;
}
.navbar-tabs .mid-list li a {
  padding: 19px 15px 18px 15px;
  text-decoration: none;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.navbar-tabs .mid-list li.active {
  border-bottom: 3px solid #1A91EB;
}
.navbar-tabs .mid-list li.active a {
  color: #354052;
}
.navbar-tabs .btn-newproject {
  margin-top: 15px;
  margin-right: 15px;
}
.navbar-tabs .input-group {
  padding-right: 15px;
  padding-top: 15px;
  margin-bottom: 0px;
  width: 270px;
  float: right;
}
.navbar-tabs .input-group .form-control {
  height: 36px;
  border-right: 0px;
  box-shadow: none;
  outline: none;
  border-color: #DFE3E9;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.navbar-tabs .input-group .input-group-btn .btn {
  height: 36px;
  border-left: 0px;
  background: none;
  box-shadow: none;
  outline: none;
  border-color: #DFE3E9;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.navbar-tabs .input-group .input-group-btn .btn i {
  color: #7F8FA4;
  font-size: 16px;
}
.navbar-tabs.navbars .btn-group {
  margin-top: 19px;
  margin-bottom: 19px;
}
.navbar-tabs.navbars .left-list {
  margin-top: 5px;
}
.navbar-tabs.navbars .mid-list {
  margin-top: 7px;
}
.navbar-tabs.navbars .mid-list li a {
  padding: 19px 15px 21px 15px;
}
.navbar-tabs.navbars .btn-newproject {
  margin-top: 17px;
}
.video-detail .panel-heading {
  height: auto;
}
.video-detail .post-list {
  margin-top: -6px;
  margin-bottom: 0px;
}
.video-detail .post-list li {
  border-bottom: 3px solid transparent;
  transition: 0.5s ease;
}
.video-detail .post-list li:first-child {
  margin-left: 27px;
  padding-left: 7px;
}
.video-detail .post-list li:first-child a {
  padding-left: 0px;
}
.video-detail .post-list li a {
  padding: 10px 12px 11px 12px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
  text-decoration: none;
  text-transform: capitalize;
}
.video-detail .post-list li.active,
.video-detail .post-list li:hover {
  border-bottom: 3px solid #1A91EB;
}
.video-detail .post-list li.active a,
.video-detail .post-list li:hover a {
  color: #354052;
}
.video-detail .video-details {
  border-top: 1px solid #E6EAEE;
  padding-left: 28px;
}
.video-detail .video-details .left-col {
  border-right: 1px solid #E6EAEE;
}
.video-detail .video-details .right-col {
  padding-left: 24px;
}
.video-detail .video-details .right-col p {
  margin-top: 2px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
}
.video-detail .video-details .right-col .btn-success {
  padding: 7px 19px;
  margin-top: 10px;
  margin-bottom: 21px;
}
.video-detail .video-details .right-col .btn-success span {
  margin: 0px;
  color: #fff;
}
.video-detail .video-details .sub-heading {
  padding-top: 15px;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #354052;
}
.video-detail .video-details .source-list {
  margin-bottom: 23px;
}
.video-detail .video-details .source-list li {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  line-height: 26px;
}
.video-detail .video-details .source-list li:first-child {
  margin-top: 7px;
}
.video-detail .video-details .source-list li i {
  color: #F86B67;
  font-size: 16px;
  margin-right: 10px;
}
.panel-body.video-panel .media {
  border-bottom: 0px;
  padding-bottom: 5px;
}
.panel-body.video-panel .media.forum {
  padding: 30px 30px 20px 30px;
}
.panel-body.video-panel .time {
  float: right;
  margin-right: -5px;
}
.panel-body.video-panel .views-count {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.panel-body.video-panel .views-count i {
  color: #C5D0DE;
  font-size: 16px;
  margin-right: 10px;
}
.panel-body.video-panel .media-body .media-heading span {
  margin-left: 0px;
}
.panel-body.video-panel .media-body .media-heading .border {
  border-left: 2px solid #7F8FA4;
  margin-left: 5px;
  margin-right: 10px;
}
.panel-body.video-panel .media-body .media-heading .text-muted {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.panel-body.video-panel .media-body .media-heading .text-muted .author a {
  color: #1A91EB;
  font-size: 'Source Sans Pro', sans-serif;
}
.panel-body.video-panel .media-body .media-heading .text-muted.forum {
  line-height: 40px;
  margin-left: 0px;
}
.panel-body.video-panel .post-text {
  margin-top: 15px;
  font-family: 'Source Sans Pro', sans-serif;
}
.panel-body.video-panel .post-text.forum {
  margin-top: 4px;
  margin-bottom: 20px;
}
.panel-body.video-panel .label-holder {
  margin-top: 12px;
}
.panel-body.video-panel .label-holder .label {
  font-size: 10px;
  padding: 3px 8px 3px 9px;
  font-family: 'Source Sans Pro', sans-serif;
}
.panel-body.video-panel .query-details {
  padding: 10px 15px 0px 28px;
}
.panel-body.video-panel .query-details .ans-cnt {
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 5px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  color: #354052;
}
.panel-body.video-panel .query-details .query-list li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  border-radius: 0px;
}
.panel-body.video-panel .query-details .query-list li.active a {
  color: #354052;
  border-bottom: 3px solid #1A91EB;
}
.panel-body.video-panel .divider {
  border-bottom: 1px solid #E6EAEE;
}
.panel-body.video-panel .media.answers {
  padding: 13px 30px 13px 30px;
}
.panel-body.video-panel .media.answers .post-text {
  font-family: 'Source Sans Pro', sans-serif;
  padding-left: 5px;
  margin-top: 10px;
  margin-bottom: 19px;
}
.panel-body.video-panel .margin-top {
  margin-top: 5px;
}
.panel-body.video-panel .reply-section {
  padding: 0px 30px 0px 30px;
}
.panel-body.video-panel .reply-section label {
  text-transform: capitalize;
  font-size: 14px;
}
.panel-body.video-panel .reply-section p {
  margin-top: 21px;
  margin-bottom: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #7F8FA4;
}
.panel-body.video-panel .reply-section p a {
  color: #1A91EB;
}
.panel-body.video-panel .likes-count .btn-group-vertical .btn {
  box-shadow: none;
  outline: none;
  border: 1px solid #CED0DA;
  text-shadow: none;
  margin-top: 0px;
}
.panel-body.video-panel .likes-count .btn-group-vertical .btn i {
  line-height: 6px;
  font-size: 16px;
  color: #CED0DA;
}
.panel-body.video-panel .likes-count .btn-group-vertical .btn:first-child {
  border-bottom-width: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  padding-bottom: 0px;
  padding-top: 4px;
  line-height: 10px;
}
.panel-body.video-panel .likes-count .btn-group-vertical .btn:last-child {
  background: #fff;
  color: red;
  border-width: 0px;
  padding-top: 0px;
}
.panel-body.video-panel .likes-count .btn-group-vertical .btn-counter {
  padding-top: 1px;
  border-top-width: 0px;
  border-radius: 0px 0px 4px 4px;
  background: linear-gradient(0deg, #FFFFFF 0%, #F2F4F7 100%);
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #354052;
  cursor: default;
}
.adding-product {
  margin-bottom: 20px;
}
.adding-product .left-side {
  float: left;
}
.adding-product .btn-primary {
  font-weight: 600;
}
.adding-product .btn-primary span {
  margin-right: 12px;
  font-size: 16px;
  color: #fff;
}
.adding-new-product {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}
.adding-new-product label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  margin-bottom: 7px;
}
.adding-new-product input.form-control {
  height: 36px;
}
.rightside-box {
  padding-left: 15px;
}
.publish-section {
  padding-top: 3px;
}
.publish-section label {
  width: 100%;
}
.publish-section .form-group {
  margin-bottom: 14px;
}
.publish-section .form-group .input-group {
  float: left;
  width: 141px;
  margin-bottom: 0px;
}
.publish-section .form-group .input-group .form-control {
  width: 105px;
}
.publish-section .btn-success {
  font-weight: 600;
  color: #FFFFFF;
  padding: 7px 14px;
  margin-top: 24px;
}
@media (max-width: 401px) {
  .publish-section .btn-success {
    padding: 7px 13px;
  }
}
.border-right {
  border-right: 1px solid #E6EAEE;
}
@media (max-width: 991px) {
  .border-right {
    border-right: 0px;
  }
}
.existing-products .left-col {
  padding-right: 5px;
}
.existing-products .right-col {
  padding-left: 5px;
}
@media (max-width: 991px) {
  .existing-products .clear-fix {
    clear: both;
  }
}
.existing-products .product-text {
  margin-top: 13px;
  margin-bottom: 25px;
}
.news-help-block {
  border: 1px solid #E6EAEE;
  border-radius: 4px;
}
.help-block {
  background-color: #fff;
  margin: 0px;
  padding: 24px 30px 26px 30px;
  border-radius: 0px 0px 4px 4px;
}
.help-block p {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  margin-bottom: 5px;
}
.help-block a {
  color: #1A91EB;
}
.news-block {
  background-color: #FAFBFC;
  padding: 30px;
  padding-bottom: 0px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #E6EAEE;
}
.news-block .news-holder {
  border-bottom: 1px solid #E6EAEE;
}
.news-block .news-holder:last-child {
  border-bottom: 0px;
  margin-top: 18px;
}
.news-block .news-holder .news-heading {
  margin-bottom: 8px;
}
.news-block .news-holder .news-heading .left {
  float: left;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #354052;
  text-transform: capitalize;
}
.news-block .news-holder .news-heading .left img {
  width: 16px;
  height: 16px;
  margin-right: 9px;
  margin-top: -3px;
}
.news-block .news-holder .news-heading .right {
  float: right;
}
.news-block .news-holder .news-heading .right i {
  color: #AEB6C5;
  font-size: 16px;
}
.news-block .news-holder .news-text {
  padding-bottom: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #7F8FA4;
}
.place-detail {
  background-color: #fff;
}
.placedetail-cover {
  width: 100%;
  height: 415px;
  position: relative;
}
.placedetail-cover .cover-pic {
  width: 100%;
  height: 415px;
}
.placedetail-cover .cover-pic img {
  width: 100%;
  height: 100%;
}
.placedetail-cover .overlay {
  width: 100%;
  height: 415px;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 52%, #000000 100%);
  opacity: 0.5;
  position: absolute;
  top: 0px;
}
.placedetail-cover .cover-content {
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 100%;
}
.placedetail-cover .cover-content .left {
  float: left;
}
.placedetail-cover .cover-content .left .media .btn-count {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid #FFFFFF;
  padding: 0px;
  line-height: 34px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-top: 5px;
  color: #FFFFFF;
}
.placedetail-cover .cover-content .left .media .media-body {
  width: auto;
}
.placedetail-cover .cover-content .left .media .media-body .media-heading a {
  color: #fff;
  font-size: 40px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 30px;
  color: #FFFFFF;
  text-decoration: none;
}
.placedetail-cover .cover-content .left .media .media-body .media-heading .text-muted {
  display: block;
  text-align: left;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #BFC7D8;
  margin-left: 3px;
}
.placedetail-cover .cover-content .right {
  float: right;
}
.placedetail-cover .cover-content .right ul {
  margin: 0px;
  margin-right: 15px;
}
.placedetail-cover .cover-content .right ul li {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  padding-left: 20px;
  padding-right: 20px;
}
.placedetail-cover .cover-content .right ul li .btn {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin-right: 14px;
  padding: 0px;
  line-height: 65px;
}
.placedetail-cover .cover-content .right ul li .btn i {
  font-size: 25px;
  color: #fff;
}
.placedetail-cover .cover-content .right ul li .btn-favourite {
  background-image: linear-gradient(-1deg, #DC893E 2%, #ED9A50 100%);
}
.place-detail-section {
  border-top: 1px solid #E6EAEE;
  margin-top: 6px;
  margin-bottom: 69px;
}
.place-detail-section p {
  margin-top: 18px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
}
.place-detail-section img {
  width: 257px;
  height: 160px;
}
.place-detail-section .sub-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #7F8FA4;
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 17px;
}
.placedetail-list {
  margin: 0px;
  text-align: center;
  margin-top: 10px;
}
.placedetail-list li .image-hover .image-holder img {
  width: 156px;
  height: 100px;
  border-radius: 4px;
}
.details-aboutplace .sub-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #7F8FA4;
  text-transform: uppercase;
  margin-top: 27px;
  margin-bottom: 17px;
}
.details-aboutplace .social-list li {
  padding-left: 2px;
  padding-right: 2px;
}
.details-aboutplace .social-list li .btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #BFC7D8;
  background-color: transparent;
  padding: 0px;
  line-height: 34px;
}
.details-aboutplace .social-list li .btn i {
  color: #BFC7D8;
  font-size: 16px;
}
.details-aboutplace .list-friends li {
  padding-left: 2px;
  padding-right: 2px;
}
.details-aboutplace .list-friends li .btn-friends {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0px;
}
.details-aboutplace .list-friends li .btn-friends img {
  width: 100%;
  height: 100%;
  margin-right: 0px;
}
.details-aboutplace .weather-list li {
  padding-left: 10px;
  padding-right: 10px;
  color: #4E4E5B;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 36px;
}
.details-aboutplace .weather-list li img {
  width: 44px;
  height: 44px;
  margin-right: 12px;
  vertical-align: top;
}
.invoice-panel {
  border: 0px;
  border-left: 4px solid #1A91EB;
  border-radius: 4px 0px 0px 4px;
}
.invoice-panel .panel-heading {
  border-color: #E6EAEE;
}
.teamlead-info {
  float: left;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  min-width: 230px;
}
.teamlead-info .user-img {
  float: left;
  margin-right: 12px;
  position: relative;
}
.teamlead-info .user-img .status-list {
  position: absolute;
  margin-bottom: 0px;
  left: 40px;
  bottom: -5px;
}
.teamlead-info .user-img .status-list li {
  padding: 0px;
  margin-right: -8px;
}
.teamlead-info .user-img .status-list li .status-circle {
  display: block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
}
.teamlead-info .user-img .status-list li .status-circle.light-blue {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
}
.teamlead-info .user-img .status-list li .status-circle.yellow {
  background-image: linear-gradient(-180deg, #F8CD51 0%, #FDBC0E 98%);
}
.teamlead-info .user-img .status-list li .status-circle.purple {
  background-image: linear-gradient(-1deg, #7D5BE2 0%, #9D8FE3 99%);
}
.teamlead-info .user-img .status-list li .status-circle.red {
  background-image: linear-gradient(-1deg, #F75954 2%, #F86F6B 98%);
}
.teamlead-info .user-img .status-list li .status-circle.green {
  background-image: linear-gradient(-180deg, #5DDA47 0%, #2AB414 98%);
}
.teamlead-info .user-img .status-list li .status-circle.blue {
  background-image: linear-gradient(-180deg, #3278BB 2%, #3869A2 98%);
}
.teamlead-info .user-details {
  margin-left: 37px;
  padding-left: 40px;
  margin-top: 2px;
}
.teamlead-info .user-details .user-name {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}
.teamlead-info .user-details .user-name a {
  color: #354052;
  text-decoration: none;
}
.teamlead-info .user-details .user-role {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  font-size: #354052;
  color: #7F8FA4;
}
.teamlead-statisctics {
  float: right;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
}
@media (max-width: 700px) {
  .teamlead-statisctics {
    float: none;
  }
}
.teamlead-statisctics .detail-list {
  margin-bottom: 0px;
  float: left;
  margin-top: 1px;
}
.teamlead-statisctics .detail-list li {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #354052;
  padding-left: 24px;
  padding-right: 24px;
}
@media (max-width: 768px) {
  .teamlead-statisctics .detail-list li {
    padding-left: 8px;
    padding-right: 8px;
  }
  .teamlead-statisctics .detail-list li:first-child {
    padding-left: 20px;
  }
  .teamlead-statisctics .detail-list li:last-child {
    padding-right: 0px;
  }
}
.teamlead-statisctics .detail-list li .text-muted {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  display: block;
}
.teamlead-statisctics .btn-collapsable {
  float: right;
  background-color: transparent;
  margin: 9px 8px 0px 9px;
  box-shadow: none;
}
@media (max-width: 666px) {
  .teamlead-statisctics .btn-collapsable {
    margin-left: 0px;
    margin-right: 0px;
    float: none;
  }
}
.teamlead-statisctics .btn-collapsable .fa {
  color: #CED0DA;
  font-size: 20px;
}
.teamlead-statisctics .btn-collapsable:focus {
  color: transparent;
}
.red.invoice-panel {
  border-left: 4px solid #DD3B1E;
}
.orange.invoice-panel {
  border-left: 4px solid #FF7800;
}
.green.invoice-panel {
  border-left: 4px solid #41C72A;
}
.yellow.invoice-panel {
  border-left: 4px solid #FAC634;
}
.timeline .timeline-panel .panel-heading {
  height: auto;
  padding: 20px;
  border-bottom: 1px solid #E6EAEE;
}
.timeline .timeline-panel .panel-heading .media.main {
  margin-top: 0px;
}
.timeline .timeline-panel .panel-heading .media.main .media-left {
  padding-left: 4px;
  padding-right: 15px;
}
.timeline .timeline-panel .panel-heading .media.main .media-body {
  padding-left: 15px;
}
.timeline .timeline-panel .panel-heading .media.main .media-body .media-heading {
  text-transform: capitalize;
  margin-top: 1px;
}
.timeline .timeline-panel .panel-heading .media.main .media-body .media-heading a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #354052;
}
.timeline .timeline-panel .panel-heading .media.main .media-body .media-heading .text-muted {
  display: block;
  line-height: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.timeline .timeline-panel .panel-body {
  border-radius: 0px 0px 4px 4px;
}
.timeline .timeline-panel .panel-body .list-group {
  margin-bottom: 0px;
  border-radius: 4px;
}
.timeline .timeline-panel .panel-body .list-group .list-group-item {
  padding-left: 20px;
  padding-right: 25px;
  border-top: 1px solid #E6EAEE;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-radius: 0px 0px 4px 4px;
  background-color: #FAFBFC;
}
.timeline .timeline-panel .panel-body .list-group .list-group-item:first-child {
  border-top: 0px;
}
.timeline .event-panel .panel-heading {
  height: auto;
  padding-left: 20px;
  padding-right: 25px;
  border-bottom: 1px solid #E6EAEE;
}
.timeline .event-panel .panel-heading .event-details {
  line-height: 20px;
}
.timeline .event-panel .panel-heading .event.fs-16 {
  font-size: 16px;
  margin-right: 10px;
}
.timeline .event-panel .panel-heading .comments-list {
  line-height: 20px;
  margin-bottom: 0px;
}
.timeline .event-panel .panel-heading .comments-list li {
  margin-top: 8px;
}
.timeline .event-panel .panel-form {
  padding-left: 23px;
  padding-top: 6px;
  padding-bottom: 8px;
}
.timeline .event-panel .panel-form .radio input[type="radio"],
.timeline .event-panel .panel-form .radio-inline input[type="radio"],
.timeline .event-panel .panel-form .checkbox input[type="checkbox"],
.timeline .event-panel .panel-form .checkbox-inline input[type="checkbox"] {
  margin-left: -23px;
  margin-top: 2px;
}
.timeline .event-panel .panel-form label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  text-transform: none;
}
.timeline .event-panel .panel-form .fa {
  color: #7C8CA5;
  font-size: 16px;
  margin-left: 6px;
  margin-right: 5px;
}
.timeline .event-panel .panel-form .check-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.timeline .timeline-panel,
.timeline .event-panel {
  position: relative;
}
.timeline .timeline-panel .panel-heading,
.timeline .event-panel .panel-heading {
  position: relative;
}
.timeline .timeline-panel .panel-heading .btn-timeline-staging,
.timeline .event-panel .panel-heading .btn-timeline-staging {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  background-color: red;
  margin-top: -17px;
  top: 50%;
  left: -50px;
  border: 0px !important;
  padding: 0px;
  line-height: 34px;
}
.timeline .timeline-panel .panel-heading .btn-timeline-staging i,
.timeline .event-panel .panel-heading .btn-timeline-staging i {
  font-size: 16px;
}
.timeline .timeline-panel .panel-heading .btn-purple,
.timeline .event-panel .panel-heading .btn-purple {
  background-image: linear-gradient(-1deg, #7D5BE2 0%, #9D8FE3 99%);
}
.timeline .timeline-panel .panel-heading:before,
.timeline .event-panel .panel-heading:before,
.timeline .timeline-panel .panel-heading:after,
.timeline .event-panel .panel-heading:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border-style: solid;
}
.timeline .timeline-panel .panel-heading:after,
.timeline .event-panel .panel-heading:after {
  border-color: transparent #fff transparent transparent;
  border-width: 7px;
  margin-top: -7px;
  left: -15px;
}
.timeline .timeline-panel .panel-heading:before,
.timeline .event-panel .panel-heading:before {
  border-color: transparent #E6EAEE transparent transparent;
  border-width: 8px;
  margin-top: -8px;
  left: -17px;
}
.timeline .events-details {
  float: left;
  line-height: 36px;
}
.timeline .event-time {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
  margin-right: 18px;
}
.timeline .event {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #354052;
}
.timeline .event img {
  margin-right: 13px;
}
.timeline .timeline-comments-list {
  float: right;
  line-height: 36px;
  min-width: 100px;
}
.timeline .timeline-comments-list li {
  vertical-align: middle;
}
.timeline .timeline-comments-list li:last-child {
  float: right;
  margin-top: 3px;
}
.timeline .timeline-comments-list li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.timeline .timeline-comments-list li i {
  margin-right: 3px;
  font-size: 16px;
  color: #7F8FA4;
}
.login-block {
  width: 100%;
  margin: 0px auto;
  /*position: relative;*/
  /*top: 50%;*/
}
@media (max-width: 500px) {
  .login-block {
    width: 340px;
  }
  .login-block .social-connect li {
    padding-right: 6px;
    padding-left: 6px;
  }
  .login-block .social-connect li .btn-social {
    height: 50px;
    width: 50px;
    padding: 0px;
  }
  .login-block .social-connect li .btn-social .social-circle {
    width: 28px;
    height: 28px;
    margin-top: 10px;
    line-height: 31px;
  }
  .login-block .social-connect li .btn-social .social-circle i {
    font-size: 15px;
  }
  .login-block .problem-login {
    font-size: 11px;
  }
}
@media (max-width: 500px) and (max-width: 350px) {
  .login-block {
    width: 300px;
  }
  .login-block .social-connect {
    padding-left: 25px;
    padding-right: 25px;
  }
  .login-block .social-connect li {
    padding-right: 2px;
    padding-left: 2px;
  }
}
.login-block.password {
  width: 450px;
  padding-top: 150px;
}
.p-t-70 {
  padding-top: 70px;
}
.navbar.guest-nav {
  min-height: 72px !important;
  height: auto !important;
  position: static !important;
}
.navbar.guest-nav .navbar-brand.socialite {
  padding-top: 12px;
}
.navbar.guest-nav .navbar-form {
  margin-top: 10px;
}
.navbar.guest-nav .navbar-form .form-group {
  vertical-align: top;
}
.navbar.guest-nav .navbar-form .form-group .form-control {
  background: #F8FAFC;
}
@media (max-width: 768px) {
  .navbar.guest-nav .navbar-form .form-group .checkbox input[type="checkbox"] {
    margin-left: 0px;
  }
}
.navbar.guest-nav .navbar-form .mail-form {
  margin-right: 7px;
}
.navbar.guest-nav .navbar-form .checkbox,
.navbar.guest-nav .navbar-form .forgot-password {
  display: block;
}
.navbar.guest-nav .navbar-form .btn-submit {
  padding: 6px 12px;
}
.navbar.guest-nav .navbar-form .forgot-password {
  font-size: 11px;
  padding-top: 5px;
  text-align: right;
}
.large-text {
  font-size: 32px;
}
.login-head {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #354052;
  text-align: center;
  background: #F8FAFC;
  border: 1px solid #E6EAEE;
  border-radius: 4px 4px 0px 0px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 30px;
}
.login-head .header-circle {
  background-image: linear-gradient(-180deg, #2EA1F8 0%, #1A91EB 100%);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 50%;
  margin-left: -35px;
  text-align: center;
  line-height: 65px;
}
.login-head .header-circle i {
  color: #fff;
  direction: rtl;
}
.login-bottom {
  padding: 22px 30px;
}
.login-bottom .form-group label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  color: #7F8FA4;
  margin-top: 2px;
  margin-bottom: 8px;
}
.login-bottom .form-group .form-control {
  height: 46px;
  background: #F8FAFC;
}
.login-bottom .btn-success {
  width: 100%;
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  color: #FFFFFF;
  margin-top: 2px;
  padding: 12px 12px;
}
.divider-login {
  border-bottom: 1px solid #E6EAEE;
  position: relative;
  margin-top: 30px;
}
.divider-login .divider-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
  background-color: #fff;
  width: 210px;
  text-align: center;
  left: 50%;
  margin-left: -105px;
  margin-top: -10px;
  position: absolute;
}
.social-connect {
  text-align: center;
  margin-bottom: 32px;
  margin-left: 0px;
  padding: 0px;
  margin-top: 25px !important;
}
.social-connect li {
  padding-left: 16px;
  padding-right: 16px;
}
.social-connect li:first-child {
  padding-left: 0px;
}
.social-connect li:last-child {
  padding-right: 0px;
}
.social-connect li .btn-social {
  height: 70px;
  width: 70px;
  border-radius: 4px;
  background: red;
}
.social-connect li .btn-social .social-circle {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #fff;
  display: block;
  margin: 0px auto;
  margin-top: 10px;
  line-height: 40px;
}
.social-connect li .btn-social .social-circle i {
  color: green;
  font-size: 18px;
}
.social-connect li .btn-social .social-circle .fa-twitter {
  color: #00AAFF;
}
.social-connect li .btn-social .social-circle .fa-facebook {
  color: #2492E4;
}
.social-connect li .btn-social .social-circle .fa-google-plus {
  color: #d34836;
}
.social-connect li .btn-social .social-circle .fa-linkedin {
  color: #4875B4;
}
.social-connect li .btn-social.fb {
  background-image: linear-gradient(-180deg, #2492E4 2%, #1180D3 97%);
}
.social-connect li .btn-social.tw {
  background: linear-gradient(-1deg, #00AAFF 3%, #4CC3FF 100%);
}
.social-connect li .btn-social.google-plus {
  background-image: linear-gradient(-180deg, #F45438 0%, #D53E23 97%);
}
.social-connect li .btn-social.linkedin {
  background-color: #0077B5;
}
.problem-login {
  margin-top: -5px;
  color: #7F8FA4;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.problem-login a {
  color: #7F8FA4;
  text-transform: capitalize;
}
.bg-dargrey {
  background-color: #FAFBFC;
}
.login-two {
  width: 350px;
  margin: 0px auto;
  position: relative;
  top: 50%;
  margin-top: -175px;
}
.login-two .login-bottom.small {
  position: relative;
  padding: 32px 30px 21px 30px;
}
.login-two .login-bottom.small .form-group {
  margin-bottom: 13px;
}
.login-two .login-bottom.small .form-group .form-control {
  height: 36px;
}
.login-two .login-bottom.small .form-group .mail-form {
  margin-bottom: -3px;
}
.login-two .login-bottom.small .btn-success {
  margin-top: 8px;
  padding: 8px 12px 7px 12px;
  text-transform: capitalize;
}
.login-two .problem-login.small {
  text-align: center;
  background-color: #F8FAFC;
  margin-top: 0px;
  height: 49px;
  line-height: 49px;
  box-shadow: 0px -1px 0px 0px #E7E9ED;
  font-weight: 600;
  border-radius: 0px 0px 4px 4px;
}
.login-two .problem-login.small a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
}
.login-two .account-signup {
  padding-top: 15px;
  opacity: 0.5;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}
.login-two .account-signup a {
  color: #FFFFFF;
  text-transform: capitalize;
}
body,
html {
  height: 100%;
}
.main-login {
  height: 100%;
}
.main-login .row {
  height: 100%;
  margin: 0px;
}
.main-login .row .main-col {
  height: 100%;
  padding: 0px;
}
.main-login .row .main-col .left-col {
  height: 100%;
  background-color: #212531;
  padding: 0px;
}
.main-login .row .main-col .left-col .left-box {
  height: 100%;
  padding-right: 0px;
}
.main-login .row .main-col .left-col .left-box .black-box {
  position: relative;
  width: 100%;
  background: #1E212C;
  border: 1px solid #242835;
  border-radius: 4px 0px 0px 4px;
  top: 50%;
  padding: 50px 50px 50px 50px;
  margin-top: -101px;
}
.main-login .row .main-col .left-col .left-box .black-box .header-circle {
  left: 50px;
  margin-left: 0px;
}
.main-login .row .main-col .left-col .left-box .black-box .heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 26px;
  color: #FFFFFF;
}
.main-login .row .main-col .left-col .left-box .black-box .box-text {
  opacity: 0.6;
  margin-top: 7px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 28px;
}
.main-login .row .main-col .right-col {
  height: 100%;
  background-image: linear-gradient(-180deg, #2EA1F8 0%, #1990EA 99%);
  padding: 0px;
}
.main-login .row .main-col .right-col .right-box {
  position: relative;
  top: 50%;
  margin-top: -99px;
  padding-left: 0px;
}
.main-login .row .main-col .right-col .right-box .login-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  padding-left: 40px;
  font-size: 26px;
  color: #FFFFFF;
  position: absolute;
  top: -55px;
}
.main-login .row .main-col .right-col .right-box .white-box {
  background: #FFFFFF;
  padding-left: 40px;
  padding-bottom: 20px;
  padding-top: 30px;
  padding-right: 70px;
  border-radius: 0px 4px 4px 0px;
}
.main-login .row .main-col .right-col .right-box .white-box .form-group label {
  text-transform: none;
}
.main-login .row .main-col .right-col .right-box .white-box .form-group .form-control {
  height: 36px;
  background: #F8FAFC;
  margin-top: 1px;
}
.main-login .row .main-col .right-col .right-box .white-box .mail-form {
  margin-bottom: 9px;
}
.main-login .row .main-col .right-col .right-box .signin-divison {
  padding-left: 40px;
  position: absolute;
  bottom: -60px;
}
.main-login .row .main-col .right-col .right-box .signin-divison .btn-signin {
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  background: #1F5480;
  border-radius: 4px;
  padding: 8px 35px;
}
.main-login .row .main-col .right-col .right-box .signin-divison span a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-left: 21px;
  vertical-align: middle;
}
.main-login .row .main-col .footer-section {
  opacity: 0.5;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  position: absolute;
  bottom: 26px;
}
.main-login .row .main-col .footer-section ul {
  margin-bottom: 0px;
}
.main-login .row .main-col .footer-section ul li {
  padding-left: 0px;
  padding-right: 0px;
}
.main-login .row .main-col .footer-section ul li:last-child a {
  border-right: 0px;
}
.main-login .row .main-col .footer-section ul li a {
  padding-right: 10px;
  padding-left: 8px;
  border-right: 1px solid #E6EAEE;
  text-transform: capitalize;
  color: #fff;
}
.main-login .row .main-col .footer-section.right {
  right: 30px;
}
.main-login .row .main-col .footer-section.left {
  left: 46px;
}
.main-login .row .main-col .footer-section.left a {
  padding-right: 10px;
  color: #fff;
  text-transform: capitalize;
}
.no-border {
  border-width: 0px !important;
}
.bg-lightgrey {
  background-color: #FAFBFC;
}
.no-padding {
  padding: 0px !important;
}
.nopadding-top-bottom {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.no-paddingbottom {
  padding-bottom: 0px;
}
.no-texttransform {
  text-transform: none !important;
}
.btn-edit-details {
  width: 40px;
  height: 40px;
  background-image: linear-gradient(0deg, #F4F7F9 0%, #F4F7F9 100%);
  border: 1px solid #E7EAEE;
  position: absolute;
  border-radius: 50%;
  top: -10px;
  color: #354052;
  font-size: 18px;
  right: 2px;
}
.nav.nav-pills li:hover a {
  background-color: transparent;
}
.nav.nav-pills li.active a {
  background-color: transparent;
}
.tab-content.nopadding {
  border: 0px;
  margin: 0px;
  padding: 0px;
}
.tab-content.noborder {
  border: 0px;
  margin: 0px;
}
.panel-heading.user-pages {
  border-bottom: 1px solid #E6EAEE !important;
  height: auto;
  background-color: #fff;
}
.panel-heading.user-pages.navbars .btn-downloadreport {
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 24px;
  padding: 7px 12px;
}
.panel-heading.user-pages.navbars .divider {
  border-top: 1px solid red;
  margin-left: 14px;
  margin-right: 14px;
  border-top: 1px solid #E6EAEE;
}
.panel-heading.user-pages.navbars .performance-list li {
  padding-left: 13px;
  padding-right: 13px;
}
.panel-heading.user-pages.navbars .performance-list li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
  color: #7F8FA4;
  padding-left: 0px;
  padding-top: 16px;
  padding-right: 0px;
  padding-bottom: 21px;
}
.panel-heading.user-pages.navbars .performance-list li.active a {
  color: #354052;
  border-bottom: 3px solid #2EA2F8;
  border-radius: 0px;
}
.panel-heading.user-pages.navbars .datepicker-list {
  margin-top: 6px;
}
.panel-heading.user-pages.navbars .datepicker-list li {
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px;
}
.panel-heading.user-pages.navbars .datepicker-list .date-separator {
  width: 8px;
  height: 2px;
  background: #C5D0DE;
}
.panel-heading.user-pages.navbars .datepicker-list .form-group {
  margin-bottom: 0px;
}
.panel-heading.user-pages.navbars .datepicker-list .form-group .input-group {
  width: 140px;
  margin-bottom: 0px;
}
.panel-heading.user-pages.navbars .datepicker-list .form-group .form-control {
  background-image: -webkit-linear-gradient(bottom, #F2F4F7 0%, #FFFFFF 100%);
  background-image: -o-linear-gradient(bottom, #F2F4F7 0%, #FFFFFF 100%);
  background-image: linear-gradient(to top, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-left: 0px;
  border-right: 0px;
  height: 36px;
  padding-left: 6px;
  text-transform: capitalize;
  padding-right: 0px;
}
.panel-heading.user-pages.navbars .datepicker-list .form-group .input-group-addon {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  box-shadow: none;
  height: 36px;
  border-color: #CED0DA;
}
.panel-heading.user-pages.navbars .datepicker-list .form-group .input-group-addon:focus {
  border-color: #CED0DA;
}
.panel-heading.user-pages.navbars .datepicker-list .form-group .calendar-addon {
  padding-right: 6px;
}
.panel-heading.user-pages.navbars .datepicker-list .form-group .angle-addon {
  border-left: 0px;
  padding-left: 2px;
}
.invoice-filters {
  padding-left: 30px;
  padding-right: 30px;
}
.invoice-filters .select-forms .form-group {
  display: inline;
  margin-right: 7px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.invoice-filters .select-forms .form-group select.form-control {
  min-width: 140px;
  text-transform: capitalize;
  height: 36px;
}
.invoice-filters .navbar-form {
  margin-top: 14px;
  margin-bottom: 0px;
}
.page-heading {
  float: left;
  padding: 12px 12px 14px 12px;
}
.user-info-bk {
  float: right;
  margin-top: 5px;
}
.user-info-bk .user-img {
  position: relative;
  float: left;
  margin-right: 7px;
}
.user-info-bk .user-img .status {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  bottom: -1px;
  right: 3px;
}
.user-info-bk .user-img .status .status-circle {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 3px solid #38A76A;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  left: 50%;
  margin-left: -7px;
}
.user-info-bk .user-details {
  float: right;
  margin-left: 8px;
}
.user-info-bk .user-details .user-name {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-top: 2px;
  text-transform: capitalize;
}
.user-info-bk .user-details .user-name a {
  color: #1A91EB;
  text-decoration: none;
}
.user-info-bk .user-details .user-role {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  color: #7F8FA4;
  margin-top: 4px;
}
.user-info-bk .user-details .dropdown {
  float: right;
  margin-right: 1px;
}
.user-info-bk .user-details .dropdown .btn {
  background-color: transparent;
  box-shadow: none;
  outline: none;
  margin-left: 20px;
  margin-top: 8px;
}
.user-info-bk .user-details .dropdown .btn i {
  font-size: 16px;
  color: #A1A7AF;
}
.user-info-bk .user-details .dropdown .dropdown-menu {
  left: auto;
  right: 0px;
}
.friends-list {
  margin-top: -10px;
  height: 45px;
}
.friends-list li {
  margin-left: 5px;
}
.friends-list li:first-child {
  margin-left: 0px;
}
.friends-list li a {
  position: absolute;
}
.friends-list li .btn-more-users {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0px;
  line-height: 29px;
  font-size: 14px;
  color: #FFFFFF;
}
.header-circle {
  background-image: linear-gradient(-180deg, #2EA1F8 0%, #1A91EB 100%);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  position: absolute;
  top: -35px;
  left: 50%;
  margin-left: -35px;
  text-align: center;
  line-height: 65px;
}
.header-circle i {
  color: #fff;
  direction: rtl;
  font-size: 20px;
}
.dark-bg {
  background-color: #212531;
}
.image-hover .image-holder {
  position: relative;
  background-color: #28303C;
  display: block;
  border-radius: 4px;
  min-height: 105px;
  overflow: hidden;
}
.image-hover .image-holder img {
  border-radius: 4px;
  min-height: 105px;
  height: auto;
}
.image-hover .image-holder .search {
  position: absolute;
  height: 50px;
  width: 50px;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 50%;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  display: none;
}
.image-hover .image-holder .fa {
  color: #fff;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  margin-left: -9px;
  display: none;
}
.image-hover:hover .image-holder img {
  opacity: 0.25;
}
.image-hover:hover .image-holder .search {
  display: block;
}
.image-hover:hover .image-holder .fa {
  display: block;
}
.upload-box {
  padding-right: 15px;
}
.upload-box select.form-control {
  height: 36px;
}
.upload-box .image-details {
  padding-top: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #7F8FA4;
}
.upload-box .image-details i {
  color: #C5D0DE;
  font-size: 16px;
  margin-right: 3px;
}
.drop-box {
  padding-right: 0px;
}
.drop-box a {
  text-decoration: none;
}
.drop-box a .outer-box {
  padding: 6px;
  height: 162px;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
}
.drop-box a .outer-box .inner-box {
  border: 3px dashed #CED0DA;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  text-decoration: none;
}
.drop-box a .outer-box .inner-box i {
  color: #E2E3E9;
  font-size: 40px;
  margin-top: 31px;
}
.drop-box a .outer-box .inner-box .cloud-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  text-decoration: none;
  width: 220px;
  margin: 0px auto;
}
@media (max-width: 460px) {
  .drop-box a .outer-box .inner-box .cloud-text {
    width: auto;
    font-size: 10px;
  }
}
@media (min-width: 991px) and (max-width: 1560px) {
  .drop-box a .outer-box .inner-box .cloud-text {
    width: auto;
    font-size: 10px;
  }
}
.drag-drop-box {
  margin-bottom: 8px;
}
.selected-box {
  padding-left: 5px;
  max-height: 162px;
  overflow: auto;
}
.selected-box .selected-image {
  margin-bottom: 6px;
  width: 73px;
  height: 50px;
}
.selected-box .selected-image:last-child {
  margin-bottom: 0px;
}
.selected-box .selected-image img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.selected-box .selected-image.single {
  width: 162px;
  height: 162px;
}
.selected-box .selected-image.single img {
  height: 100%;
  width: 100%;
}
.table.existing-products-table {
  border-radius: 4px;
}
.table.existing-products-table .actions-list {
  padding-right: 0px;
}
.table.existing-products-table thead th {
  background-color: #fff;
  height: 50px;
  padding-bottom: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.table.existing-products-table thead th:first-child {
  border-top-left-radius: 4px;
  padding-left: 18px;
}
.table.existing-products-table thead th:nth-child(2) {
  padding-left: 0px;
}
.table.existing-products-table thead th:last-child {
  border-top-right-radius: 4px;
  padding-right: 18px;
}
.table.existing-products-table tbody tr td {
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}
.table.existing-products-table tbody tr td:first-child {
  padding-left: 10px;
  border-top: 0px;
  border-bottom: 0px;
}
.table.existing-products-table tbody tr td:nth-child(2) {
  padding-left: 0px;
}
.table.existing-products-table tbody tr td:last-child {
  padding-right: 10px;
  border-top: 0px;
  border-bottom: 0px;
}
.table.existing-products-table tbody tr td img {
  width: 60px;
  height: 40px;
  border-radius: 4px;
}
.table.existing-products-table tbody tr td p {
  color: #354052;
  line-height: 20px;
}
.table.existing-products-table tbody tr td a {
  color: #354052;
  text-decoration: none;
}
.table.existing-products-table tbody tr td ul {
  margin-bottom: 0px;
}
.table.existing-products-table tbody tr td ul li a i {
  /*color: #C8D2DF;*/
  font-size: 16px;
}
.table.existing-products-table.socialite tbody tr td img {
  width: 40px !important;
}
.table.panel-table {
  margin-bottom: 0px;
}
.table.panel-table thead tr th {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.table.panel-table thead tr th .sort {
  margin-top: 5px;
  float: none;
  margin-left: 10px;
}
.table.panel-table thead tr th.active {
  color: #1A91EB;
}
.table.panel-table tbody tr td {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  position: relative;
}
.table.panel-table tbody tr td .friends-list li {
  padding-right: 8px;
}
.table.panel-table tbody tr td .label {
  padding: 4px 8px 3px 8px;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
}
.table.panel-table tbody tr td .btn-default {
  border: 1px solid #CED0DA;
  color: #354052;
  padding: 7px 14px;
}
.table.panel-table tbody tr td .btn-default i {
  margin-left: 22px;
}
.table.projects-table th {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.table.projects-table th i {
  margin-top: 4px;
}
.table.projects-table th:first-child {
  padding-left: 30px;
}
.table.projects-table tbody tr td {
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
}
.table.projects-table tbody tr td a {
  color: #354052;
  text-decoration: none;
}
.table.projects-table tbody tr td:first-child {
  padding-left: 30px;
}
.table.projects-table tbody tr td .overdue {
  color: #D35847;
}
.table.projects-table tbody tr td .media .media-left {
  padding-right: 16px;
}
.table.projects-table tbody tr td .media .media-body {
  max-width: 150px;
}
.table.projects-table tbody tr td .media .media-body .media-heading {
  margin-bottom: 0px;
  line-height: 16px !important;
}
.table.projects-table tbody tr td .media .media-body .media-heading a {
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.table.projects-table tbody tr td .media .media-body .media-heading .text-muted {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-top: 3px;
}
.table.projects-table tbody tr td .btn-default {
  box-shadow: none;
  font-weight: 600;
  color: #354052;
  position: relative;
  padding: 7px 13px 8px 12px;
}
.table.projects-table tbody tr td .btn-default i {
  color: #A8AAB7;
  font-size: 16px;
  margin-left: 22px;
}
.table.projects-table tbody tr .status {
  font-size: 12px;
}
.table.projects-table tbody tr .status .status-circle {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 9px;
  vertical-align: middle;
}
.table.projects-table tbody tr .status.blue .status-circle {
  border: 3px solid  #19A5D6;
}
.table.projects-table tbody tr .status.gold .status-circle {
  border: 3px solid #DA932C;
}
.table.projects-table tbody tr .status.maroon .status-circle {
  border: 3px solid #636363;
}
.table.projects-table tbody tr .status.green .status-circle {
  border: 3px solid #66B92E;
}
.table.projects-table tbody tr.overdue td:first-child {
  border-left: 3px solid #D35847;
}
.table.apps-table {
  margin-bottom: 0px;
}
.table.apps-table thead tr th:first-child,
.table.apps-table tbody tr th:first-child,
.table.apps-table thead tr td:first-child,
.table.apps-table tbody tr td:first-child {
  padding-left: 30px;
}
.table.apps-table tbody td {
  text-transform: capitalize;
}
.table.apps-table tbody td img {
  width: 58px;
  height: 58px;
  margin-right: 15px;
}
.table.apps-table tbody td .app-details {
  display: inline-block;
  vertical-align: middle;
}
.table.apps-table tbody td .app-details .app-name {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.table.apps-table tbody td .app-details .app-name a {
  color: #354052;
  text-decoration: none;
}
.table.apps-table tbody td .text-secondary {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #7F8FA4;
}
.table.apps-table tbody td .label {
  padding: 5px 10px;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.table.apps-table tbody td ul {
  margin-bottom: 0px;
}
.table.apps-table tbody td ul li {
  padding-left: 13px;
  padding-right: 13px;
}
.table.apps-table tbody td ul li a i {
  /*color: #BBC5D5;*/
  font-size: 18px;
}
.table.apps-table tbody td ul li a .fa-play-circle-o {
  display: none;
}
.table.apps-table tbody td ul li.active a i {
  color: #1bb934;
}
.table.apps-table tbody td ul li.active a .fa-play-circle-o {
  display: inline-block;
}
.table.apps-table tbody td ul li.active a .fa-pause-circle-o {
  display: none;
}
.table.apps-table.socialite tbody tr img {
  width: 40px!important;
  height: 40px!important;
  border-radius: 4px;
}
.label {
  text-transform: uppercase;
  border-radius: 4px;
}
.label.label-info {
  background: #18A5D6;
}
.label.label-warning {
  background: #DA932C;
}
.label.label-success {
  background: #1BB934;
}
.label.label-default {
  background-color: #9299A7;
}
.label.label-primary {
  background-color: #0474C8;
}
.label.label-danger {
  background-color: #F86B67;
}
.img-46 {
  width: 46px;
  height: 46px;
}
.img-radius {
  border-radius: 4px;
}
.img-50 {
  width: 50px;
  height: 50px;
}
.img-30 {
  width: 30px;
  height: 30px;
}
.img-32 {
  width: 32px;
  height: 32px;
}
.img-36 {
  width: 36px;
  height: 36px;
}
.img-38 {
  width: 38px;
  height: 38px;
}
.img-40 {
  width: 40px;
  height: 40px;
}
.header-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 24px;
  text-transform: capitalize;
  color: #354052;
}
#mce_0_ifr {
  height: 82px !important;
  border-radius: 0px 0px 4px 4px !important;
}
.mce-edit-area {
  border-radius: 0px 0px 4px 4px !important;
}
.mce-toolbar-grp {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%) !important;
  border-radius: 4px  !important;
}
.mce-tinymce {
  border: 1px solid #CED0DA !important;
  border-radius: 4px !important;
  margin-bottom: 13px !important;
}
.mce-btn.mce-active,
.mce-btn.mce-active:hover,
.mce-btn {
  background-color: transparent !important;
  border-color: transparent !important;
}
.mce-btn button {
  padding: 4px 5px !important;
  margin-left: 0px !important;
}
@media (max-width: 500px) {
  .mce-btn button {
    padding-left: 4px 5px !important;
  }
}
.mce-ico {
  color: #aaa !important;
  font-size: 16px !important;
}
.mce-btn-group:not(:first-child) {
  border-left: 0px !important;
  margin-left: 30px !important;
}
.mce-caret {
  border-top: 4px solid #aaa !important;
}
.best-pics {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
.mini-profile {
  padding: 10px;
}
.mini-profile .background {
  position: relative;
}
.mini-profile .background .widget-bg {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.mini-profile .background .widget-bg img {
  width: 100%;
  height: auto;
}
.mini-profile .background .label {
  padding: 6.5px 12px;
  background: rgba(49, 53, 62, 0.25);
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin-top: 10px;
  margin-right: 10px;
  letter-spacing: 0.5px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.mini-profile .background .avatar-img {
  position: absolute;
  left: 8px;
  bottom: -45px;
  border-radius: 50%;
  text-align: -webkit-center;
  line-height: 82px;
}
.mini-profile .background .avatar-img img {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  border: 4px solid #ffffff;
}
.mini-profile .avatar-profile .avatar-details {
  padding-left: 94px;
}
.mini-profile .avatar-profile .avatar-details .avatar-name {
  margin: 0px;
}
.mini-profile .avatar-profile .avatar-details .avatar-name a {
  color: #354052;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
}
.mini-profile .avatar-profile .avatar-details .avatar-mail {
  color: #7F8FA4;
  font-size: 14px;
  margin-top: -4px;
}
.mini-profile .social-links {
  padding-top: 6px;
  border-bottom: 1px solid #E6EAEE;
  margin-left: 13px;
  margin-right: 13px;
}
.mini-profile .address {
  padding-top: 10px;
}
.mini-profile .address ul {
  margin-bottom: 4px;
  padding-left: 18px;
}
.mini-profile .address ul li {
  line-height: 25px;
  font-size: 14px;
  color: #354052;
}
.mini-profile .address ul li i {
  padding-right: 12px;
  color: #BBC5D5;
  font-size: 14px;
}
.mini-profile .activity-list {
  margin-top: 13px;
  margin-bottom: 0px;
  padding: 0px 10px;
}
.mini-profile .activity-list li {
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 350px) {
  .mini-profile .activity-list li {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.mini-profile .activity-list .activity-name {
  font-size: 12px;
  color: #7F8FA4;
  text-transform: uppercase;
  border: 0px;
  text-align: left;
  padding-bottom: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.mini-profile .activity-list .activity-count {
  border: 0px;
  padding-bottom: 5px;
  text-align: left;
  font-size: 22px;
  color: #354052;
  padding-top: 0px;
  font-weight: 600;
}
.mini-profile.dark-profile .background .avatar-img img {
  border: 4px solid #273142;
}
.mini-profile.dark-profile .avatar-profile .avatar-details .avatar-name a {
  font-size: 16px;
  color: #FFFFFF;
}
.mini-profile.dark-profile .avatar-profile .avatar-details .avatar-mail {
  font-size: 14px;
  color: #7C8CA5;
}
.mini-profile.dark-profile .social-links {
  border-bottom: 1px solid #313D4F;
}
.mini-profile.dark-profile .address li {
  font-size: 14px;
  color: #FFFFFF;
}
.mini-profile.dark-profile .address li i {
  color: #BBC5D5;
}
.mini-profile.widget-candidates .avatar-profile.avatar-candidates {
  border-bottom: 1px solid #F2F4F6;
  margin-right: 20px;
  margin-left: 11px;
}
.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-details {
  padding-left: 80px;
}
.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-details .avatar-mail i {
  padding-right: 5px;
  font-size: 15px;
}
.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons {
  padding-top: 20px;
}
.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons a i {
  color: #C8D2DF;
  font-size: 20px;
}
.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons a:nth-child(1) {
  padding-right: 6px;
}
.mini-profile.widget-candidates .avatar-profile.avatar-candidates .avatar-icons .btn-setting.btn-candidate {
  margin-top: 0px;
}
.mini-profile.widget-candidates .avatar-prefers {
  padding-left: 12px;
  padding-right: 20px;
  padding-top: 14px;
}
@media (max-width: 415px) {
  .mini-profile.widget-candidates .avatar-prefers .avatar-message .btn-primary.btn-chat {
    padding: 7px 7.5px;
  }
}
.mini-profile.widget-candidates .avatar-prefers .avatar-hire {
  padding-top: 10px;
  padding-right: 10px;
}
.mini-profile.widget-candidates .avatar-prefers .avatar-hire .btn-tick {
  width: 18px;
  height: 18px;
  background-image: linear-gradient(0deg, #2AB314 0%, #5EDB49 100%);
  border-radius: 50%;
  padding: 0px;
  line-height: 15px;
  vertical-align: text-bottom;
  margin-right: 5px;
}
.mini-profile.widget-candidates .avatar-prefers .avatar-hire .btn-tick i {
  color: #FFFFFF;
  font-size: 12px;
}
.mini-profile.widget-candidates .avatar-prefers .avatar-hire a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #2FB818;
  font-weight: 400;
  text-decoration: none;
}
@media (max-width: 415px) {
  .mini-profile.widget-candidates .avatar-prefers .avatar-hire.recent-contact {
    padding-right: 0px;
  }
}
.mini-profile.widget-candidates .avatar-prefers .avatar-hire.recent-contact a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #335980;
  font-weight: 400;
}
.mini-profile.widget-candidates .avatar-prefers .avatar-hire.recent-contact a i {
  color: #384B78;
  font-size: 18px;
  padding-right: 5px;
}
.mini-profile.widget-candidates .avatar-contact {
  padding-top: 24px;
  padding-bottom: 11px;
}
.mini-profile.widget-candidates .avatar-contact a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #C5D0DE;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.mini-profile.widget-candidates .avatar-contact a i {
  color: #C5D0DE;
  font-size: 18px;
  padding-right: 10px;
}
.panel-body.panel-post {
  padding: 0px;
  border: 1px solid #E6EAEE;
  border-radius: 4px 4px 0px 0px;
}
.my-post .form-group .form-control {
  border-radius: 4px 4px 0px 0px;
  padding: 11px 18px;
}
.my-post .post-block {
  background: #FAFBFC;
  padding: 12px 12px;
  border-radius: 0px 0px 4px 4px;
}
.my-post .post-block .left .btn-image {
  padding: 7px 9.5px;
  margin-right: 10px;
}
.my-post .post-block .left a {
  font-size: 14px;
  color: #1A91EB;
  text-decoration: none;
  border-left: 1px solid #E6EAEE;
  padding-top: 19px;
  padding-bottom: 23px;
}
.my-post .post-block .left a i {
  color: #1A91EB;
  padding-right: 15px;
  padding-left: 20px;
}
@media (max-width: 450px) {
  .my-post .post-block .left a {
    font-size: 12px;
  }
  .my-post .post-block .left a i {
    padding-right: 5px;
    padding-left: 11px;
  }
}
.my-post .post-block .right .btn-success.btn-post {
  padding: 7px 16px;
}
.widget-overview.my-post .form-group:after,
.widget-overview.my-post .form-group:before {
  content: '';
  display: block;
  position: absolute;
  top: -7px;
  width: 0;
  height: 0;
  border-style: solid;
}
.widget-overview.my-post .form-group:after {
  border-color: transparent transparent #fff transparent;
  border-width: 7px;
  margin-top: -5px;
  left: 40px;
}
.widget-overview.my-post .form-group:before {
  border-color: transparent transparent #DFE3E9 transparent;
  border-width: 8px;
  margin-top: -8px;
  left: 39px;
}
.widget-overview.my-post .btn-primary.btn-image {
  margin-right: 4px;
}
.widget-jobs .job-heading {
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 3px;
  font-size: 16px;
  color: #354052;
  font-weight: 600;
}
.widget-jobs .job-process {
  border-bottom: 1px solid #E6EAEE;
  margin-left: 14px;
  margin-right: 24px;
  padding-bottom: 20px;
}
.widget-jobs .job-process .avilable-jobs {
  font-size: 14px;
  color: #354052;
  padding-top: 15px;
  font-weight: 600;
}
@media (max-width: 990px) {
  .widget-jobs .job-process .avilable-jobs {
    padding-bottom: 10px;
  }
}
.widget-jobs .job-process .choose-jobs .btn-group .btn-green {
  background-color: #FAFBFC;
  padding: 11px 0px;
  box-shadow: none;
  font-size: 14px;
  color: #354052;
  font-weight: 600;
  border: 1px solid #E7E9ED;
}
.widget-jobs .job-process .choose-jobs .btn-group .btn-green.active {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  color: #FFFFFF;
  border: 1px solid #249533;
}
.widget-jobs .job-checking {
  padding-top: 16px;
  margin-left: 14px;
}
.widget-jobs .job-checking .question {
  font-size: 14px;
  color: #354052;
  line-height: 19px;
  font-weight: 600;
}
.widget-jobs .job-checking .employee-review {
  padding-left: 14px;
  padding-right: 44px;
  font-size: 14px;
  color: #354052;
  line-height: 19px;
  letter-spacing: 0.2px;
  font-weight: 600;
}
.widget-recently .table {
  margin-bottom: 0px;
  width: 100%;
}
.widget-recently .table tr td:first-child {
  padding-left: 15px;
  padding-bottom: 14px;
  padding-top: 14px;
}
.widget-recently .table tr td {
  border-top: 0px;
}
.widget-recently .table tr td a {
  text-decoration: none;
  font-size: 16px;
  color: #354052;
}
.widget-recently .table tr td a i {
  padding-right: 7px;
  color: #F86B67;
}
.widget-recently .table tr td i.fa-file-text {
  color: #00AAFF;
}
.widget-recently .table tr td .doc-icon {
  padding-right: 7px;
  margin-top: -5px;
}
.widget-recently .table tr td .files-edit {
  margin-bottom: 1px;
  padding-left: 42px;
}
.widget-recently .table tr td .files-edit li {
  font-size: 14px;
  color: #7F8FA4;
}
.widget-recently .table tr td .files-edit li:first-child {
  border-right: 1px solid #7F8FA4;
  padding-right: 8px;
}
.widget-recently .table tr:last-child td {
  border-bottom-width: 0px;
}
.widget-pictures {
  padding-top: 16px;
  padding-bottom: 12px;
}
.widget-pictures .picture {
  font-size: 16px;
  color: #354052;
  font-weight: 600;
  padding-bottom: 11px;
  padding-left: 17px;
}
.widget-pictures .btn-setting.btn-picture {
  padding-right: 18px;
}
.widget-pictures .btn-setting.btn-picture a i {
  color: #CED0DA;
}
.widget-pictures .best-pictures {
  padding-left: 4px;
  padding-right: 4px;
}
.widget-pictures .best-pictures img {
  width: 100%;
  height: auto;
}
.widget-pictures .best-pictures .row {
  margin-right: 0px;
  margin-left: 0px;
}
.widget-pictures .best-pictures .row .left-pic {
  padding-right: 5px;
  margin-bottom: 9px;
}
.widget-pictures .best-pictures .row .right-pic {
  padding-left: 5px;
  margin-bottom: 9px;
}
.panel-default .widget-frequently {
  background-color: #FAFBFC;
  border: 1px solid #E6EAEE;
  border-radius: 4px 4px 0px 0px;
  padding-left: 20px;
  padding-right: 26px;
  padding-top: 0px;
  padding-bottom: 3px;
}
.panel-default .widget-frequently .question-tips {
  font-size: 16px;
  color: #354052;
  font-weight: 600;
  padding-bottom: 11px;
  padding-top: 11px;
}
.panel-default .widget-frequently .question-tips i {
  color: #354052;
  padding-right: 8px;
}
.panel-default .widget-frequently .question-passage {
  border-bottom: 1px solid #E6EAEE;
}
.panel-default .widget-frequently .question-passage p {
  font-size: 14px;
  color: #7F8FA4;
  line-height: 23px;
  font-weight: 400;
}
.panel-default .contact-support {
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid #E6EAEE;
  border-radius: 0px 0px 4px 4px;
  background-color: #ffffff;
  text-align: center;
}
.panel-default .contact-support a {
  font-size: 14px;
  color: #1A91EB;
  font-weight: 600;
}
.widget-card {
  padding: 10px;
}
.widget-card .widget-card-bg img {
  width: 100%;
  height: auto;
}
.widget-card .widget-card-project {
  padding-left: 10px;
}
.widget-card .widget-card-project .bridge-text {
  padding-top: 7px;
}
.widget-card .widget-card-project .bridge-text a {
  font-size: 16px;
  color: #354052;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
}
.widget-card .widget-card-project .upadate-project {
  font-size: 12px;
  color: #7F8FA4;
  font-weight: 400;
  letter-spacing: 0.3px;
  padding-top: 2px;
}
.widget-card .widget-card-project .upadate-project span {
  font-weight: 600;
}
.widget-card .widget-card-project .add-images {
  padding-top: 14px;
}
.widget-card .widget-card-project .add-images ul li {
  padding-right: 1px;
  position: relative;
}
.widget-card .widget-card-project .add-images ul li .btn-plus {
  width: 32px;
  height: 32px;
  background: #E8EAF1;
  border-radius: 50%;
  padding: 5px 6px;
  box-shadow: none;
}
.widget-card .widget-card-project .add-images ul li .btn-plus i {
  color: #8D909F;
}
.widget-card .widget-card-project .add-images ul li .btn-plus:hover {
  background-image: linear-gradient(-180deg, #37AC48 0%, #3EB74F 100%);
}
.widget-card .widget-card-project .add-images ul li .btn-plus:hover i {
  color: #FFFFFF;
}
.widget-card .widget-card-project .add-images ul li .btn-plus.btn-num {
  background: #E8EAF1;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
}
.widget-card .widget-card-project .add-images ul li .btn-plus.btn-num:hover {
  background-image: linear-gradient(-180deg, #37AC48 0%, #3EB74F 100%);
  color: #FFFFFF;
}
.widget-card .widget-card-project .add-images ul li a img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.widget-card .widget-card-project .add-images ul li .tooltip {
  margin-top: -6px;
  margin-left: -1px;
}
.widget-card .widget-card-project .add-images ul li .tooltip .tooltip-arrow {
  border-top-color: #353C48;
}
.widget-card .widget-card-project .add-images ul li .tooltip .tooltip-inner {
  background: #353C48;
  width: 94px;
  height: 34px;
  line-height: 34px;
  padding: 0px;
  border-radius: 2px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
}
.widget-featured .background .widget-bg {
  height: 210px;
}
.widget-featured .featured-design .us-design {
  padding-top: 7px;
  padding-left: 20px;
  float: left;
}
.widget-featured .featured-design .us-design .design-heading {
  margin: 0px;
}
.widget-featured .featured-design .us-design .design-heading a {
  font-size: 16px;
  color: #354052;
  font-weight: 600;
  text-decoration: none;
}
.widget-featured .featured-design .us-design .design-sub-heading {
  margin-top: -9px;
}
.widget-featured .featured-design .us-design .design-sub-heading a {
  font-size: 12px;
  color: #7F8FA4;
  font-weight: 400;
  text-decoration: none;
  text-transform: capitalize;
}
.widget-featured .featured-design .design-price {
  margin-top: 16px;
  margin-bottom: 17px;
  padding-right: 10px;
  float: right;
}
.widget-featured .featured-design .design-price .btn {
  padding: 7px 13px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: none;
}
.widget-statistics .sales-increment {
  text-align: center;
  background: #F5F8FA;
  border-top: 1px solid #E6EAEE;
  border-radius: 0px 0px 4px 4px;
  padding-top: 13px;
  padding-bottom: 15px;
}
.widget-statistics .sales-increment .sales-icon {
  width: 15px;
  height: 15px;
  background: #1BB934;
  border-radius: 50%;
  margin: 0px auto;
  line-height: 11px;
  margin-right: 10px;
  padding: 0px;
}
.widget-statistics .sales-increment .sales-icon i {
  line-height: 11px;
  color: #FFFFFF;
}
.widget-statistics .sales-persentage {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #354052;
  font-weight: 600;
}
.widget-card.widget-card-dark .bridge-text a {
  color: #FFFFFF;
}
.widget-card.widget-card-dark .add-images ul li .btn-plus {
  background: #222C3C;
  border: 1px solid #313D4F;
}
.widget-card.widget-card-dark .add-images ul li .btn-plus i {
  color: #7C8CA5;
}
.panel-default.panel-blue {
  background-color: #1991EB;
}
.panel-default.panel-red {
  background-color: #D65B4A;
}
.panel-default.panel-green {
  background-color: #66B92E;
}
.panel-default.panel-yellow {
  background-color: #DA932C;
}
.widget-stats .company-profile {
  padding-left: 16px;
}
@media (max-width: 1235px) {
  .widget-stats .company-profile {
    padding-left: 7px;
  }
}
.widget-stats .company-profile .company-projects {
  padding-top: 7px;
}
.widget-stats .company-profile .company-projects a {
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
}
.widget-stats .company-profile .company-projects a:hover {
  opacity: 0.69;
}
.widget-stats .company-profile .no-of-projects {
  font-size: 36px;
  color: #FFFFFF;
  font-weight: 400;
  padding-bottom: 14px;
}
.widget-stats .sales-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 4px solid #FFFFFF;
  margin-right: 8px;
}
.widget-stats .sales-icon:hover {
  opacity: 0.2;
}
.widget-stats .sales-icon .btn-arrow {
  padding: 1px 4px;
}
.widget-stats .sales-icon .btn-arrow i {
  color: #FFFFFF;
}
.widget-stats .stats-percentage {
  padding-left: 16px;
  padding-top: 11px;
  border-top: 1px solid #FFFFFF;
}
.widget-stats .stats-percentage a {
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 600;
  text-decoration: none;
}
.widget-stats .stats-percentage a:hover {
  opacity: 0.69;
}
.icon-boxes {
  padding-top: 50px;
  padding-bottom: 38px;
  padding-left: 42px;
  padding-right: 42px;
  text-align: center;
}
.icon-boxes .application-image img {
  width: 146px;
  height: 118px;
}
.icon-boxes .app-files {
  padding-top: 16px;
  font-size: 16px;
  color: #354052;
  line-height: 22px;
  font-weight: 600;
}
.icon-boxes .new-campaign {
  padding-top: 16px;
}
.icon-boxes .new-campaign .app-btn {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: none;
  padding: 7px 23.5px;
}
.panel-heading.panel-calendar {
  padding: 15px 19px;
  color: #354052;
  font-weight: 400;
}
.widget-calendar {
  padding-left: 19px;
}
.widget-calendar .calendar-todo-list {
  padding-top: 20px;
  padding-bottom: 3px;
}
.widget-calendar .calendar-todo-list ul li {
  line-height: 29px;
}
.widget-calendar .calendar-todo-list ul li .square-shape {
  width: 16px;
  height: 16px;
  background-color: #00A8FF;
  border-radius: 3px;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
  margin-top: -3px;
}
.widget-calendar .calendar-todo-list ul li .square-shape.color-white {
  background: #D8DDE5;
}
.widget-calendar .calendar-todo-list ul li .square-shape.color-white {
  background: #D8DDE5;
}
.widget-calendar .calendar-todo-list ul li .square-shape.color-blue {
  background: #BF8FE1;
}
.widget-calendar .calendar-todo-list ul li .square-shape.color-green {
  background: #1BB934;
}
.widget-calendar .calendar-todo-list ul li .square-shape.color-orange {
  background: #FF7800;
}
.widget-calendar .calendar-todo-list ul li .square-shape.color-dark {
  background: #2097D4;
}
.widget-calendar .calendar-todo-list ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
}
.widget-calendar .import-head {
  font-size: 12px;
  color: #7F8FA4;
  font-weight: 400;
  text-transform: uppercase;
}
.widget-calendar .calendar-import-list.calendar-todo-list {
  padding-top: 3px;
}
.widget-calendar .calendar-import-list.calendar-todo-list .square-shape.color-orange {
  background: #F0B499;
}
.widget-searched .input-group {
  border-bottom: 1px solid #E6EAEE;
  padding: 10px 20px 10px 20px;
}
.widget-searched .input-group .form-control {
  background: #FFFFFF;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #e6eaee;
  border-right: none;
  box-shadow: none;
  outline: 0;
  height: 36px;
}
.widget-searched .input-group .btn-form {
  background: #FFFFFF;
  border: 1px solid #e6eaee;
  box-shadow: none;
  border-left: none;
  outline: none;
  height: 36px;
}
.widget-searched .input-group .btn-form i {
  color: #A1A7AF;
}
.widget-searched .search-list {
  padding-left: 20px;
}
.widget-searched .search-list li {
  line-height: 27px;
}
.widget-searched .search-list li a {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  text-decoration: none;
}
.widget-searched .search-list li a i {
  color: #B2B3BF;
  padding-left: 6px;
}
.calendar-visible .public-calendar {
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  text-transform: capitalize;
  padding-left: 27px;
}
.calendar-visible .public-calendar span {
  color: #36AF47;
}
@media (max-width: 1435px) {
  .calendar-visible .public-calendar {
    padding-left: 0px;
  }
}
.widget-left-panel .menu-heading {
  font-size: 12px;
  color: #9FA9BA;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 19px;
  padding-bottom: 4px;
  padding-top: 15px;
}
.widget-left-panel .menu-list {
  padding-bottom: 3px;
}
.widget-left-panel .menu-list li.active .menu-btn {
  background-color: #1991EB;
  color: #FFFFFF;
}
.widget-left-panel .menu-list li .menu-btn {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 4px 18px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 4px;
  font-size: 14px;
  color: #354052;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  display: inline-block;
  width: 100%;
}
.widget-left-panel .menu-list li .menu-btn:hover {
  background-color: #1991EB;
  color: #FFFFFF;
}
.widget-left-panel .menu-list li .menu-btn:hover i {
  color: #FFFFFF;
}
.widget-left-panel .categotry-list {
  padding-left: 19px;
}
.widget-left-panel .categotry-list li {
  line-height: 33px;
}
.widget-left-panel .categotry-list li .hash-icon {
  background-color: #1991EB;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 19px;
  margin-right: 5px;
}
.widget-left-panel .categotry-list li .hash-icon i {
  color: #FFFFFF;
  font-size: 11px;
}
.widget-left-panel .categotry-list li .round-shape {
  height: 14px;
  width: 14px;
  background-color: #FF4B64;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: -3px;
}
.widget-left-panel .categotry-list li .round-shape.dark-circle {
  background-color: #BF8FE1;
}
.widget-left-panel .categotry-list li .round-shape.blue-circle {
  background-color: #00AAFF;
}
.widget-left-panel .categotry-list li .round-shape.light-circle {
  background-color: #98D4E0;
}
.widget-left-panel .categotry-list li .round-shape.green-circle {
  background-color: #33AD00;
}
.widget-left-panel .categotry-list li .round-shape.white-circle {
  background-color: #8D909F;
  text-align: center;
  line-height: 0px;
}
.widget-left-panel .categotry-list li .round-shape.white-circle i {
  color: #FFFFFF;
  font-size: 10px;
}
.widget-left-panel .categotry-list li a {
  color: #354052;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  width: 79px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-left-panel .categotry-list li a:hover {
  color: #1991EB;
}
.widget-left-panel .categotry-list li:last-child {
  /*padding-top: 8px;*/
}
.widget-events.widget-left-panel .menu-list li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #9FA9BA;
}
.widget-events.widget-left-panel .menu-list li .event-circle {
  width: 20px;
  height: 20px;
  background-color: #FF7800;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  display: inline-block;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 400;
  margin-left: 8px;
  float: right;
}
.widget-mountain .mountain-bg img {
  width: 100%;
  height: auto;
}
.widget-mountain .mountain-bg .image-hover .image-holder.mountain-holder {
  border-radius: 4px 4px 0px 0px;
}
.widget-mountain .image-details {
  text-align: center;
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: #FFFFFF;
}
.widget-mountain .image-details .image-name {
  font-size: 16px;
  color: #354052;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.3px;
}
.widget-mountain .image-details .image-uploader {
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.3px;
}
.widget-mountain .image-details .image-uploader span {
  font-weight: 600;
}
.widget-profile {
  padding-left: 29px;
  padding-right: 29px;
  padding-bottom: 40px;
}
.widget-profile .profile-heading {
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: 'Source Sans Pro', sans-serif;
}
.widget-profile .avatar-img {
  margin-top: 4px;
  float: left;
}
.widget-profile .avatar-img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.widget-profile .form-group .avatar-details {
  padding-left: 23px;
}
.widget-profile .form-group .account-name {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  font-weight: 600;
}
.widget-profile .form-group .account-form.form-area {
  height: auto;
}
.widget-profile .form-group .account-form {
  height: 36px;
  font-family: 'Source Sans Pro', sans-serif;
}
.widget-profile .form-group .profile-btn {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.widget-profile .input-group-area .form-group {
  padding-right: 80px;
  margin-bottom: 0px;
}
.widget-profile .input-group-area .form-group .form-control {
  float: left;
  height: 36px;
}
.widget-profile .input-group-area .form-group .account-name.account-password {
  padding-top: 18px;
}
.widget-profile .input-group-area .btn-success {
  float: right;
  color: #FFFFFF;
  height: 36px;
  font-family: 'Source Sans Pro', sans-serif;
}
.widget-account {
  padding: 24px 30px 9px 27px;
}
.widget-account .profile-info {
  font-size: 16px;
  color: #354052;
  font-weight: 600;
  padding-bottom: 6px;
}
.widget-account .form-horizontal .form-group {
  padding-bottom: 5px;
  padding-top: 10px;
  border-bottom: 1px solid #E6EAEE;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.widget-account .form-horizontal .form-group .control-label {
  text-align: left;
  padding-left: 2px;
  font-size: 14px;
  color: #354052;
  text-transform: capitalize;
  font-weight: 600;
  padding-top: 12px;
}
.widget-account .form-horizontal .form-group .form-control-static {
  font-size: 14px;
  color: #354052;
  font-weight: 600;
}
.widget-account .form-horizontal .form-group .avatar-image {
  padding-left: 2px;
}
.widget-account .form-horizontal .form-group .avatar-image .avatar-name {
  font-size: 14px;
  color: #354052;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0px;
  padding-top: 22px;
}
.widget-account .form-horizontal .form-group .avatar-image .avatar-size {
  font-size: 12px;
  color: #7F8FA4;
  font-weight: 400;
  margin: 0px;
}
.widget-account .form-horizontal .form-group .avatar-body {
  width: 78px;
  height: 70px;
  background-color: #FAFBFC;
  border: 1px solid #E6EAEE;
  border-radius: 3px;
  margin-left: 30px;
  margin-top: 9px;
  margin-bottom: 9px;
}
.widget-account .form-horizontal .form-group .avatar-body img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  float: left;
  margin-left: -31px;
  margin-top: -1px;
}
.widget-account .form-horizontal .form-group .avatar-body .image-icon {
  padding-left: 51px;
  padding-top: 6px;
}
.widget-account .form-horizontal .form-group .avatar-body .image-icon a {
  border-bottom: 1px solid #E6EAEE;
  padding-bottom: 8px;
}
.widget-account .form-horizontal .form-group .avatar-body .image-icon a i {
  color: #C8D2DF;
  font-size: 16px;
}
.widget-account .form-horizontal .form-group .avatar-body .image-icon.delete-icon {
  padding-top: 15px;
}
.widget-account .form-horizontal .form-group .avatar-body .image-icon.delete-icon a {
  border-bottom: transparent;
}
.widget-account .form-horizontal .form-group .avatar-body .image-icon.delete-icon a i {
  font-size: 20px;
}
.widget-account .form-horizontal .form-group.favorite {
  border-bottom: none;
}
.widget-account .form-horizontal .form-group.favorite .control-label {
  padding: 12px 2px;
}
.widget-pagination-horizantal .pagination {
  background: #FFFFFF;
  border-radius: 4px;
}
.widget-pagination-horizantal .pagination li.active a {
  color: #354052;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: transparent;
}
.widget-pagination-horizantal .pagination li a {
  background: transparent;
  font-size: 14px;
  color: #CED0DA;
  font-weight: 600;
  font-family: 'Source Sans Pro', sans-serif;
}
.widget-pagination-horizantal .pagination li a:hover {
  color: #354052;
}
.widget-pagination .pagination li a {
  border-radius: 4px;
  margin-right: 20px;
  background: #273142;
  border: 1px solid #313D4F;
  color: #CED0DA;
}
.widget-pagination .pagination .page-change a {
  background-color: transparent;
  border: transparent;
  cursor: default;
}
.widget-post-block {
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-right: 20px;
}
.widget-post-block .widget-post {
  overflow: inherit;
  padding-bottom: 12px;
}
.widget-post-block .widget-post .post-dropdown {
  float: right;
}
.widget-post-block .widget-post .post-dropdown .dropdown-toggle {
  background: transparent;
  border: none;
}
.widget-post-block .widget-post .post-dropdown .dropdown-toggle i {
  color: #7F8FA4;
}
.widget-post-block .widget-post .post-dropdown .dropdown-menu {
  right: 0px;
  left: auto;
}
.widget-post-block .widget-post .post-block .post-img {
  vertical-align: middle;
  padding-right: 13px;
}
.widget-post-block .widget-post .post-block .post-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.widget-post-block .widget-post .post-block .post-details .post-pictures {
  margin: 0px;
}
.widget-post-block .widget-post .post-block .post-details .post-pictures a {
  font-size: 14px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-decoration: none;
}
.widget-post-block .widget-post .post-block .post-details .post-time {
  margin-top: -4px;
  margin-bottom: 0px;
  font-size: 12px;
  color: #7F8FA4;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.widget-post-block .post-passage {
  padding-bottom: 15px;
}
.widget-post-block .post-passage p {
  margin-bottom: 0px;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #FFFFFF;
  line-height: 19px;
  font-weight: 400;
}
.widget-post-block .post-images {
  border-bottom: 1px solid #313D4F;
}
.widget-post-block .post-images .row .post-img-holder {
  position: relative;
  margin-bottom: 15px;
}
.widget-post-block .post-images .row .post-img-holder:hover .overlay-container {
  position: absolute;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0px;
  left: 0px;
}
.widget-post-block .post-images .row .post-img-holder:hover .overlay-container .overlay {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-radius: 4px;
}
.widget-post-block .post-images .row .post-img-holder:hover .img-post-number {
  display: block;
}
.widget-post-block .post-images .row .post-img-holder .small-img {
  width: 100%;
  height: auto;
}
.widget-post-block .post-images .row .post-img-holder .img-post-number {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  text-align: center;
  display: none;
  margin-top: -17px;
}
.widget-post-block .post-images .row .post-img-holder .img-post-number a {
  font-size: 24px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}
.widget-post-block .post-images .row .post-middle.post-img-holder {
  padding-left: 0px;
  padding-right: 0px;
}
.widget-post-block .post-images .row .post-middle.post-img-holder .overlay-container {
  padding-left: 0px;
  padding-right: 0px;
}
.widget-post-block .post-images .row .post-middle.post-img-holder img {
  width: 100%;
  height: auto;
}
.widget-post-block .post-users {
  position: relative;
  margin-top: 9px;
}
.widget-post-block .post-users .post-icon a i {
  color: #AEB6C5;
  font-size: 14px;
  float: left;
  padding-top: 12px;
  padding-right: 6px;
}
.widget-post-block .post-users .friends-list.users-category {
  float: left;
  margin-bottom: 0px;
}
.widget-post-block .post-users .friends-list.users-category li a img {
  width: 28px;
  height: 28px;
}
.widget-post-block .post-users .friends-list.users-category li a i {
  color: #AEB6C5;
  font-size: 14px;
}
.widget-post-block .post-users .friends-list.users-category li .btn-more-users.btn-user {
  width: 37px;
  height: 28px;
  border-radius: 14px;
  line-height: 27px;
  font-size: 12px;
}
.widget-post-block .post-users .comment-category {
  float: right;
  padding-top: 10px;
  margin-bottom: 0px;
}
.widget-post-block .post-users .comment-category li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 600;
  text-decoration: none;
}
.widget-post-block .post-users .comment-category li a i {
  color: #AEB6C5;
  font-size: 15px;
  padding-right: 6px;
}
.sales-block {
  padding-top: 13px;
  padding-left: 30px;
  padding-right: 29px;
}
.sales-block .sales-update {
  border-bottom: 1px solid #E6EAEE;
  padding-bottom: 14px;
}
.sales-block .sales-update .update-heading {
  font-size: 16px;
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.sales-block .sales-update .show-sales .btn-sales {
  background-color: transparent;
  font-size: 12px;
  color: #1A91EB;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0px;
  box-shadow: none;
}
.sales-block .sales-dropdown .input-group {
  padding-top: 18px;
  padding-bottom: 20px;
  margin-bottom: 0px;
}
.sales-block .sales-dropdown .input-group .input-group-btn .btn-default {
  box-shadow: none;
  background: #F2F4F7;
  outline: 0;
  height: 36px;
  border: 1px solid #CED0DA;
}
.sales-block .sales-dropdown .input-group .input-group-btn .btn-default i {
  color: #CED0DA;
}
.sales-block .sales-dropdown .input-group .input-group-btn .btn-default.btn-form {
  border-radius: 0px;
  border-left: none;
}
.sales-block .sales-dropdown .input-group .input-group-btn .btn-default.btn-form i {
  color: #A8AAB7;
}
.sales-block .sales-dropdown .input-group .select-list {
  height: 36px;
  border: 1px solid #CED0DA;
  background: #F2F4F7;
  border-right: none;
}
.sales-block .sales-questions.news-block {
  padding: 0px;
  border-bottom: 0px;
  margin-top: 19px;
}
.sales-block .sales-questions.news-block .news-holder {
  margin-top: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 18px;
  padding-right: 18px;
}
.sales-block .sales-questions.news-block .news-holder .news-heading {
  margin-bottom: 0px;
  padding-bottom: 14px;
}
.sales-block .sales-questions.news-block .news-holder .news-text {
  padding-bottom: 0px;
}
.sales-block .sales-profit .sales-currency {
  float: left;
  font-size: 24px;
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.sales-block .sales-profit .sales-currency .sales-percent {
  border-left: 1px solid #E7E9ED;
  margin-left: 6px;
  padding-left: 8px;
  font-size: 24px;
  color: #299817;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.sales-block .sales-profit .total-sales {
  float: right;
  font-size: 14px;
  color: #7F8FA4;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  padding-top: 10px;
}
.sales-block .sales-profit .sales-progress {
  background-color: #DFE4EE;
  border-radius: 2px;
  height: 5px;
  margin-bottom: 0px;
  margin-top: 11px;
}
.sales-block .sales-profit .sales-progress .progress-bar {
  background-color: #38BA24;
  border-radius: 2px;
}
.sales-block .sales-table {
  padding-top: 21px;
  padding-bottom: 19px;
}
.sales-block .sales-table .table {
  background: transparent;
  margin-bottom: 0px;
}
.sales-block .sales-table .table tr td {
  padding: 0px;
  border-top: 0px;
  border-bottom: 0px;
  font-size: 14px;
  color: #7F8FA4;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  line-height: 30px;
}
.sales-block .sales-table .table tr td:last-child {
  padding-right: 84px;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
@media (max-width: 1410px) {
  .sales-block .sales-table .table tr td:last-child {
    padding-right: 0px;
  }
}
.sales-block .sales-links {
  padding-bottom: 21px;
}
@media (max-width: 1500px) {
  .sales-block .sales-links {
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .sales-block .sales-links {
    text-align: justify;
  }
}
@media (max-width: 1090px) {
  .sales-block .sales-links {
    text-align: center;
  }
}
@media (max-width: 990px) {
  .sales-block .sales-links {
    text-align: justify;
  }
}
@media (max-width: 836px) {
  .sales-block .sales-links {
    text-align: center;
  }
}
.sales-block .sales-links ul {
  margin-bottom: 0px;
}
.sales-block .sales-links ul li {
  padding-right: 0px;
  margin-bottom: 5px;
}
.sales-block .sales-links ul li .btn-chat.btn-success {
  padding: 7px 9px;
}
.sales-block .sales-links ul li .btn-chat.btn-success i {
  color: #B0EDB8;
}
.sales-block .notice i {
  color: #C5D0DE;
  float: left;
}
.sales-block .notice .notice-passage {
  padding-left: 21px;
  padding-right: 0px;
  padding-bottom: 12px;
  font-size: 13px;
  color: #7F8FA4;
  line-height: 19px;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}
.widget-faq .faq-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: #354052;
  font-weight: 400;
  padding-bottom: 6px;
  text-transform: uppercase;
}
.widget-faq .faq-passage {
  padding-bottom: 6px;
}
.widget-faq .faq-passage p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0px;
}
.widget-faq .faq-passage p:nth-child(2) {
  padding-bottom: 5px;
}
.widget-faq .faq-passage p:nth-child(3) {
  line-height: 18px;
  padding-bottom: 7px;
}
.widget-faq .faq-vist a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #1A91EB;
  font-weight: 400;
  text-transform: capitalize;
}
.widget-notifications {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 11px;
}
.widget-notifications .note-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: #354052;
  font-weight: 400;
  padding-top: 25px;
  padding-bottom: 12px;
  letter-spacing: 0.5px;
}
.widget-notifications .notification {
  border-bottom: 1px solid #E6EAEE;
  padding-bottom: 10px;
  padding-top: 13px;
}
.widget-notifications .notification .notification-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
}
.widget-notifications .notification .calendar-switch.notfication-switch {
  margin-top: 0px;
  padding-right: 0px;
}
.widget-notifications .notification .calendar-switch.notfication-switch label {
  margin-bottom: 0px;
}
.widget-connect {
  padding-top: 22px;
  padding-bottom: 9px;
}
.widget-connect .connect-header {
  padding-left: 30px;
  padding-bottom: 5px;
}
.widget-connect .connect-list {
  border-bottom: 1px solid #E6EAEE;
  padding-top: 12px;
  padding-bottom: 12px;
}
.widget-connect .connect-list .connect-link {
  padding-left: 30px;
}
.widget-connect .connect-list .connect-link a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  text-decoration: none;
}
.widget-connect .connect-list .connect-link a img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.widget-connect .connect-list .connect-mail {
  padding-right: 30px;
}
.widget-connect .connect-list .connect-mail a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  text-decoration: none;
}
.widget-connect .connect-list .connect-mail .btn-cross {
  padding: 0px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: linear-gradient(-1deg, #F3F5F6 0%, #FFFFFF 100%);
  border: 1px solid #D6D7E0;
  line-height: 13px;
  box-shadow: none;
  margin-left: 9px;
  vertical-align: text-bottom;
}
.widget-connect .connect-list .connect-mail .btn-cross i {
  color: #B2B3BF;
  font-size: 11px;
}
.widget-connect .connect-list .connect-mail .btn-chat.btn-connect {
  display: none;
  margin-right: 20px;
  color: #FFFFFF;
}
.widget-bank-account .card-details {
  background-color: #FFFFFF;
  border: 2px solid #2DA1F7;
  border-radius: 8px;
  width: 254px;
  height: 152px;
  position: relative;
  bottom: 37px;
  float: left;
  margin-left: 20px;
}
@media (max-width: 1878px) {
  .widget-bank-account .card-details {
    float: none;
    margin: 0px auto;
  }
}
.widget-bank-account .card-details .visa-form {
  padding-left: 28px;
}
.widget-bank-account .card-details .visa-form img {
  margin-top: 22px;
  width: 70px;
  height: 46px;
}
.widget-bank-account .card-details .visa-form .visa-password {
  padding-top: 9px;
}
.widget-bank-account .card-details .visa-form .visa-password i {
  vertical-align: text-top;
  font-size: 13px;
  margin-top: 1px;
}
.widget-bank-account .card-details .visa-form .visa-password span:nth-child(4) {
  padding-right: 5px;
}
.widget-bank-account .card-details .visa-form .visa-password span:nth-child(8) {
  padding-right: 5px;
}
.widget-bank-account .card-details .visa-form .visa-password span {
  font-size: 20px;
}
.widget-bank-account .card-details .visa-form .visa-password .account-no {
  vertical-align: super;
}
.widget-bank-account .card-details .visa-form .valid-text {
  margin-top: -4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.widget-bank-account .account-details {
  margin-top: 23px;
  float: right;
  padding-right: 46px;
}
@media (max-width: 1878px) {
  .widget-bank-account .account-details {
    float: none;
    text-align: center;
    margin-top: 0px;
    padding-bottom: 20px;
    padding-right: 0px;
  }
}
.widget-bank-account .account-details h4 {
  margin: 0px;
}
.widget-bank-account .account-details .account-header {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  color: #354052;
  font-weight: 400;
  text-transform: capitalize;
  padding-bottom: 15px;
  letter-spacing: 0.5px;
}
.widget-bank-account .account-details .user-num {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.widget-bank-account .account-details .user-num span {
  color: #354052;
}
.widget-lesson.related-lesson .purchased-friends {
  padding-top: 4px;
  margin-bottom: 8px;
}
.widget-lesson.related-lesson .purchased-friends li {
  padding-right: 1px;
}
.widget-new-lesson {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.widget-new-lesson .light-room {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: #354052;
  font-weight: 400;
  letter-spacing: 0.8px;
  text-transform: capitalize;
  padding-bottom: 5px;
}
.widget-new-lesson .famous-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #7F8FA4;
  font-weight: 400;
  letter-spacing: 0.8px;
  padding-bottom: 8px;
}
.widget-new-lesson .lesson-pagination {
  margin: 0px;
}
.widget-new-lesson .lesson-pagination li a {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
  margin-right: 11px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #354052;
  font-weight: 400;
  padding: 8.5px 15px;
}
.widget-new-lesson .lesson-pagination li a i {
  color: #CED0DA;
  font-size: 14px;
}
.widget-new-lesson .lesson-pagination li:nth-child(2) a {
  padding: 8.5px 37.5px;
  margin-right: 0px;
}
.widget-new-lesson .lesson-pagination li:nth-child(2) a i {
  padding-left: 16px;
}
.social-links-list {
  margin-top: 10px;
}
.social-links-list li {
  padding-right: 2px;
}
.social-links-list li .btn {
  height: 38px;
  width: 38px;
  background-color: #FF3700;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  box-shadow: none;
}
.social-links-list li .btn i {
  color: #ffffff;
  font-size: 14px;
}
.social-links-list li .btn.btn-twitter {
  background-color: #1da1f2;
}
.social-links-list li .btn.btn-behance {
  background-color: #187AC6;
}
.social-links-list li .btn.btn-youtube {
  background-color: #cd201f;
}
.social-links-list li .btn.btn-facebook {
  background-color: #3b5998;
}
.social-links-list li .btn.btn-linkedin {
  background-color: #0077b5;
}
.social-links-list li .btn.btn-dribbble {
  background-color: #ea4c89;
}
.social-links-list li .btn.btn-instagram {
  background-color: #f56040;
}
.social-links-list li:last-child {
  padding-top: 5px;
}
.social-links-list.dark .btn.btn-youtube {
  background-color: #313D4F;
}
.social-links-list.dark .btn.btn-linkedin {
  background-color: #313D4F;
}
.widget-sales {
  text-align: center;
  background: #FFFFFF;
  padding-top: 10px;
  padding-bottom: 18px;
  border-radius: 4px 4px 0px 0px;
}
.widget-sales .no-of-sales {
  font-size: 30px;
  color: #354052;
  font-weight: 400;
  padding-bottom: 6px;
}
.widget-sales .sales a {
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}
.tab-left-col {
  width: 100px;
  float: left;
}
@media (max-width: 992px) {
  .tab-left-col {
    width: 0px;
    display: inline;
    float: none;
  }
}
.tab-left-col .account-avatar {
  text-align: center;
}
.tab-left-col .account-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.tab-left-col .account-avatar .edit-text a {
  font-size: 14px;
  color: #354052;
  font-weight: 600;
}
.tab-right-col {
  margin-left: 138px;
}
@media (max-width: 992px) {
  .tab-right-col {
    margin-left: 0px;
  }
}
.tab-right-col .account-details {
  border-right: 1px solid #DFE3E9;
  padding-right: 30px;
}
.tab-right-col .account-details .form-group .account-name {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  text-transform: capitalize;
  font-weight: 600;
}
.tab-right-col .account-details .account-area {
  height: 0px;
}
.tab-right-col .account-details .account-form.form-area {
  height: auto;
}
.tab-right-col .account-details .account-form {
  height: 36px;
  font-family: 'Source Sans Pro', sans-serif;
}
@media (max-width: 992px) {
  .tab-right-col .account-details {
    border-right: none;
    padding-right: 15px;
  }
}
.tab-right-col .account-details.company-details {
  padding-right: 24px;
  border-right: none;
  padding-left: 30px;
}
.tab-right-col .account-details.company-details .fb-btn {
  padding: 10px 10.5px;
}
.tab-right-col .account-details.company-details .twitter-btn {
  padding: 10px 13px;
}
@media (max-width: 992px) {
  .tab-right-col .account-details.company-details {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.text-success {
  color: #1BB934 !important;
}
.text-info {
  color: #4695D9 !important;
}
.calendar-switch {
  margin-top: -3px;
  padding-right: 27px;
}
.calendar-switch input.cmn-toggle-round:checked + label:after {
  margin-left: 24px;
  width: 20px;
  height: 20px;
  margin-top: 1px;
}
.calendar-switch input.cmn-toggle-round:checked + label:before {
  background-color: #DFE1E5;
}
.calendar-switch input.cmn-toggle-round + label:after {
  width: 20px;
  height: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-left: 2px;
  margin-top: 1px;
}
.calendar-switch input.cmn-toggle-round + label:before {
  background-color: #36AF47;
}
@media (max-width: 1435px) {
  .calendar-switch {
    padding-right: 0px;
  }
}
.btn-chat {
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.5px;
  padding: 7px 14.5px;
  box-shadow: none;
}
.btn-chat i {
  color: #86CAFF;
  padding-right: 10px;
  font-size: 15px;
}
.panel-body.panel-account {
  max-height: 135px;
}
@media (max-width: 1878px) {
  .panel-body.panel-account {
    max-height: 299px;
  }
}
.left-sidebar-brandimage img {
  padding-top: 30px;
  padding-left: 30px;
  padding-bottom: 25px;
}
.left-sidebar-menu .badge {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 31px;
}
.sidebar-form {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 35px;
}
.sidebar-form .form-control {
  background: #222C3C;
  border: 1px solid #313D4F;
  border-radius: 4px;
  color: #7F8FA4;
  border-right-width: 0px;
  border-top-width: 0px;
  background-color: transparent;
  border-left-width: 0px;
  border-radius: 0px;
}
.sidebar-form .input-group-btn .btn,
.sidebar-form .input-group-btn a {
  background: #222C3C;
  border-color: #313D4F !important;
  color: #7F8FA4 !important;
  border-left-width: 0px;
  border-top-width: 0px;
  background-color: transparent;
  border-right-width: 0px;
  border-radius: 0px;
  padding-bottom: 7px;
}
.user-details-sidebar {
  padding-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
}
.user-details-sidebar .img-60 {
  margin-bottom: 17px;
}
.user-details-sidebar .user-name {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: capitalize;
}
.user-details-sidebar .user-list-group {
  margin-top: 10px;
}
.user-details-sidebar .user-list-group .list-group-item {
  background-color: transparent;
  border-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #7F8FA4;
  text-transform: uppercase;
}
.user-details-sidebar .user-list-group .list-group-item .pull-right {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.user-details-sidebar .btn {
  margin-bottom: 8px;
}
.following-group {
  margin-top: 15px;
}
.following-group .group-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.following-group .group-heading .btn-setting {
  margin-top: 0px;
}
.following-group .list-group-item {
  background-color: transparent;
  border-width: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 18px;
}
.following-group .list-group-item .media .media-left {
  padding-right: 12px;
}
.following-group .list-group-item .media .media-body .media-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  text-transform: capitalize;
  text-decoration: none;
  margin-bottom: -2px;
}
.following-group .list-group-item .media .media-body .active-ago {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  vertical-align: middle;
}
.following-group .list-group-item .media .media-body .text-muted {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #7F8FA4;
}
.chat-list {
  position: fixed;
  top: 60px;
  right: 0px;
  height: 100%;
  background-color: #fafbfc;
  border-left: 1px solid #E6EAEE;
  z-index: 9;
}
@media (max-width: 1325px) {
  .chat-list {
    display: none;
  }
}
.chat-list .left-sidebar.socialite {
  width: 240px;
  display: table;
}
@media (max-width: 1660px) {
  .chat-list .left-sidebar.socialite {
    transition: all 0.3s ease !important;
    /*margin-right: -183px;*/
  }
  .chat-list .left-sidebar.socialite .following-group {
    transition: all 0.3s ease !important;
  }
  .chat-list .left-sidebar.socialite:hover {
    transition: all 0.3s ease !important;
    margin-right: 0px;
  }
  .chat-list .left-sidebar.socialite:hover .following-group {
    transition: all 0.3s ease !important;
  }
}
.chat-list .left-sidebar.socialite .following-group {
  margin-top: 0px;
  height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.chat-list .left-sidebar.socialite .following-group .list-group-item {
  padding: 0px;
}
.chat-list .left-sidebar.socialite .following-group .list-group-item a {
  padding: 10px 15px;
  display: block;
}
.chat-list .left-sidebar.socialite .following-group .list-group-item a:hover {
  background-color: #EFF3F6;
}
.chat-list .left-sidebar.socialite .following-group .media-left img {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
.chat-list .left-sidebar.socialite .following-group .media-body {
  vertical-align: middle;
}
.chat-list .left-sidebar.socialite .following-group .media-body .active-ago {
  margin-top: 5px;
}
.chat-list .left-sidebar.socialite .following-group .media-heading {
  width: 120px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #354052;
}
.chat-list .left-sidebar.socialite .following-group .group-heading {
  padding: 15px 15px;
  padding-top: 26px;
  color: #354052;
  display: none;
}
.chat-list .left-sidebar.socialite .following-group .group-heading a {
  display: inline;
  padding: 0px;
  background-color: transparent;
}
.chat-list .left-sidebar.socialite .following-group .group-heading a:hover {
  background-color: transparent;
}
.chat-box {
  width: 260px;
  height: 304px;
  position: fixed;
  background-color: #fff;
  z-index: 9;
  bottom: 0px;
  right: 255px;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.chat-box .chat-box-header {
  height: 28px;
  background-color: #1991EB;
  border-bottom: 1px solid #E6EAEE;
  border-radius: 4px 4px 0px 0px;
}
.chat-box .chat-box-header:hover {
  background-color: #1489e1;
}
.chat-box .chat-box-header .side-left {
  text-transform: capitalize;
  line-height: 28px;
  padding-left: 10px;
}
.chat-box .chat-box-header .side-left a {
  color: #fff;
  text-decoration: none;
}
.chat-box .chat-box-header .list-inline {
  margin-bottom: 0px;
  padding-right: 5px;
}
.chat-box .chat-box-header .list-inline li a i {
  line-height: 28px;
  color: #fff;
}
.chat-box .chat-conversation {
  padding: 10px;
  height: 235px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-left: 1px solid #E6EAEE;
  border-right: 1px solid #E6EAEE;
}
.chat-box .chat-conversation .message-conversation {
  padding-bottom: 18px;
}
.chat-box .chat-conversation .message-conversation .media {
  position: relative;
}
.chat-box .chat-conversation .message-conversation .media .media-left {
  padding-right: 4px;
}
.chat-box .chat-conversation .message-conversation .media .media-left img {
  width: 28px;
  height: 28px;
  border-radius: 4px;
}
.chat-box .chat-conversation .message-conversation .media .media-body .post-text {
  color: #354052;
  font-size: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  line-height: 15px;
  border: 1px solid #E6EAEE;
  padding: 5px 8px;
  padding-right: 15px;
  background-color: #fafbfc;
  border-radius: 4px;
  margin-bottom: 0px;
  display: inline-block;
}
.chat-box .chat-conversation .message-conversation.current-user .media {
  direction: rtl;
}
.chat-box .chat-conversation .message-conversation.current-user .media .media-left {
  padding-right: 0px;
}
.chat-box .chat-conversation .message-conversation.current-user .media .media-body {
  text-align: right;
}
.chat-box .chat-conversation .message-conversation.current-user .media .media-body .post-text {
  text-align: left;
  padding-left: 15px;
  margin-right: 4px;
  padding-right: 4px;
}
.chat-box .message-input {
  border-top: 1px solid #E6EAEE;
  margin-top: 5px;
  border: 1px solid #E6EAEE;
}
.chat-box .message-input .form-group {
  margin-bottom: 4px;
}
.chat-box .message-input .form-group .form-control {
  border-width: 0px;
  font-size: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  padding-top: 4px;
}
.chat-box .message-input .list-inline {
  margin-left: 0px;
  padding-left: 5px;
}
.chat-box .message-input .list-inline li:first-child {
  padding-left: 10px;
}
.chat-box .message-input .list-inline li a i {
  font-size: 16px;
  color: #9FA9BA;
}
.chat-box:nth-child(2) {
  margin-right: 270px;
}
.chat-box:nth-child(3) {
  margin-right: 540px;
}
.chat-box:nth-child(4) {
  margin-right: 810px;
}
.chat-box:nth-child(5) {
  margin-right: 1080px;
}
.chat-box:nth-child(6) {
  margin-right: 1350px;
}
.chat-box-small {
  height: 28px;
}
.chat-box-small .chat-conversation {
  display: none;
}
.chat-box-small .message-input {
  display: none;
}
.privacy-question {
  padding-left: 16px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.privacy-question .list-group .list-group-item {
  padding: 10px 0px;
  border-left: transparent;
  border-right: transparent;
  border-radius: 0px;
}
.privacy-question .list-group .list-group-item .form-group {
  width: 100%;
}
.privacy-question .list-group .list-group-item .form-group label {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 0px;
  padding-top: 8px;
}
.privacy-question .list-group .list-group-item .form-group .form-control {
  border: 1px solid #CED0DA;
  border-radius: 4px;
  float: right;
  width: 130px;
}
.privacy-question .list-group .list-group-item:first-child {
  border-top: transparent;
  padding-top: 16px;
}
.privacy-question .btn-success {
  padding: 7px 12.5px;
}
.footer-description .socialite-terms {
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
@media (max-width: 440px) {
  .footer-description .socialite-terms {
    font-size: 11px;
  }
}
.footer-description .socialite-terms a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #249AF2;
  text-transform: capitalize;
  font-weight: 400;
  vertical-align: middle;
}
@media (max-width: 440px) {
  .footer-description .socialite-terms a {
    font-size: 11px;
  }
}
.footer-description .socialite-terms:nth-child(1) {
  padding: 0;
  margin-bottom: 10px;
}
.list-group.socialite-group {
  margin-bottom: 20px;
}
.list-group.socialite-group .list-group-item {
  padding: 6px 15px !important;
}
.list-group.socialite-group .list-group-item .badge {
  margin-top: 9px;
}
.list-group.socialite-group .list-group-item:hover .socialite-icon {
  background: #269BF3;
}
.list-group.socialite-group .list-group-item:hover .socialite-icon i {
  color: #FFFFFF;
}
.list-group.socialite-group .list-icon.socialite-icon {
  background: #E8EAF1;
}
.list-group.socialite-group .list-icon.socialite-icon i {
  color: #8D909F;
}
.list-group.socialite-group .list-icon.socialite-icon:hover {
  background: #269BF3;
}
.list-group.socialite-group .list-icon.socialite-icon:hover i {
  color: #ffffff;
}
.list-group.socialite-group .list-icon.active {
  background: #269BF3;
}
.list-group.socialite-group .list-icon.active i {
  color: #ffffff;
}
.panel-heading.panel-settings {
  padding-top: 24px;
  padding-left: 27px;
}
.panel-heading.panel-settings .panel-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  font-weight: 400;
}
.socialite-form {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
  padding-top: 29px;
}
.socialite-form .form-control {
  padding-right: 35px !important;
}
.socialite-form .help-block {
  padding: 10px 0px 0px 0px;
}
.socialite-form .avatar-details .avatar-pic {
  display: inline-block;
  padding-left: 17px;
}
.socialite-form .avatar-details .avatar-pic .avatar-name {
  font-size: 14px;
  color: #354052;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0px;
}
.socialite-form .avatar-details .avatar-pic .avatar-size {
  font-size: 12px;
  color: #7F8FA4;
  font-weight: 400;
  margin: 0px;
}
.socialite-form .form-group .input-group.gender-select {
  width: 100%;
}
.socialite-form .form-group .input-group.gender-select .form-control {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  border-radius: 4px;
}
.socialite-form .form-group .input-group.datepicker {
  padding: 0px;
}
.socialite-form .form-group .input-group.datepicker .calendar-addon {
  color: #C5D0DE;
  font-size: 16px;
}
.socialite-form .form-group .input-group.datepicker .form-control {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border-right: none;
  border-left: none;
}
.socialite-security {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 28px;
}
.socialite-security .btn-save-change {
  padding-top: 22px;
}
.affiliate-settings .affiliate-link {
  text-align: center;
  padding-bottom: 20px;
}
.affiliate-settings .affiliate-link .link-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 'Source Sans Pro', sans-serif;
  color: #354052;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 7px;
}
.affiliate-settings .affiliate-link a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 'Source Sans Pro', sans-serif;
  color: #789A6A;
  font-weight: 400;
}
.affiliate-settings .affiliate-link .affiliate-buttons {
  padding-top: 8px;
}
.affiliate-settings .affiliate-link .affiliate-buttons .btn-default {
  padding: 6px 15px;
  color: #354052;
  margin-right: 17px;
}
.affiliate-settings .affiliate-link .affiliate-buttons .btn-default i {
  color: #3B5998;
  font-size: 20px;
  padding-right: 9px;
}
.affiliate-settings .affiliate-link .affiliate-buttons .btn-default.btn-twitter {
  padding: 6px 25px;
}
.affiliate-settings .affiliate-link .affiliate-buttons .btn-default.btn-twitter i {
  color: #5EA9DD;
  font-size: 20px;
}
.affiliate-settings .affliate-followers .list-group-item {
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 15px 0px;
}
.affiliate-settings .affliate-followers .list-group-item .connect-link a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
}
.affiliate-settings .affliate-followers .list-group-item .connect-link a img {
  width: 40px;
  height: 40px;
  border-radius: 0px;
}
.affiliate-settings .affliate-followers .list-group-item .connect-link a .name {
  padding-left: 20px;
}
.affiliate-settings .affliate-followers .list-group-item .connect-mail .btn-default {
  padding: 7px 17px;
}
.affiliate-settings .affliate-followers .list-group-item .connect-mail .btn-default i {
  color: #1B92EC;
  font-size: 14px;
  padding-right: 9px;
}
.startpeep-services {
  padding-left: 13px;
  padding-right: 13px;
}
.startpeep-services .list-group-item {
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 15px 0px;
}
.startpeep-services .list-group-item .connect-list .connect-link a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.startpeep-services .list-group-item .connect-list .connect-link a img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.startpeep-services .list-group-item .connect-list .connect-mail a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 400;
  text-decoration: none;
}
.startpeep-services .list-group-item .connect-list .connect-mail .btn-cross {
  padding: 0px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: linear-gradient(-1deg, #F3F5F6 0%, #FFFFFF 100%);
  border: 1px solid #D6D7E0;
  line-height: 13px;
  box-shadow: none;
  margin-left: 9px;
  vertical-align: text-bottom;
}
.startpeep-services .list-group-item .connect-list .connect-mail .btn-cross i {
  color: #B2B3BF;
  font-size: 11px;
}
.startpeep-services .list-group-item .connect-list .connect-mail .btn-success {
  color: #FFFFFF;
}
.startpeep-services li:nth-child(1) {
  border-top: none;
}
.startpeep-services li:nth-child(2) {
  padding: 7px 0px;
}
.startpeep-services li:nth-child(2) .connect-link {
  margin-top: 7px;
}
.startpeep-services li:nth-child(5) {
  border-bottom: none;
}
.startpeep-services li:nth-child(4) {
  padding: 7px 0px;
}
.startpeep-services li:nth-child(4) .connect-link {
  margin-top: 7px;
}
.accout-deactivate {
  position: relative;
  text-align: center;
}
.accout-deactivate i {
  color: rgba(231, 104, 118, 0.07);
  font-size: 150px;
}
.accout-deactivate .delete-text {
  position: absolute;
  bottom: 50px;
  left: 0px;
  right: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: #354052;
  font-weight: 400;
}
.delete-btn {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 8px;
}
.delete-btn .btn-danger {
  background: #E76877;
  border: 1px solid #E76877;
}
.list-group.page-likes {
  padding-left: 13px;
  padding-right: 13px;
}
.list-group.page-likes .list-group-item {
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 15px 0px;
}
.list-group.page-likes .list-group-item .connect-link a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.list-group.page-likes .list-group-item .connect-link a img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.list-group.page-likes .list-group-item .pull-right {
  padding-top: 5px;
}
.list-group.page-likes .list-group-item .pull-right .btn-primary {
  color: #FFFFFF;
}
.list-group.page-likes .list-group-item .pull-right .btn-primary i {
  color: #FFFFFF;
  padding-right: 12px;
}
.list-group.page-likes li:nth-child(1) {
  border-top: 0px;
}
.list-group.page-likes li:nth-child(5) {
  border-bottom: 0px;
}
.privacy-question .calendar-switch {
  padding-right: 0px;
  margin-right: 0px;
  padding-top: 5px;
}
.list-group li:last-child {
  border-bottom: none;
}
.mini-profile .widget-bg img {
  border-radius: 4px 4px 0px 0px;
}
.mini-profile .avatar-name {
  margin-top: -8px !important;
}
.mini-profile .avatar-mail {
  margin-top: -8px !important;
}
.mini-profile .avatar-mail a {
  font-family: 'Source Sans Pro', sans-serif;
  color: #7F8FA4;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}
.mini-profile .activity-list {
  margin-top: 17px;
}
.mini-profile .activity-list .activity-count {
  text-align: center;
}
footer {
  /*background: rgba(0,0,0,0.2);*/
  overflow: hidden;
  padding: 20px 0;
}
.footer-description .socialite-terms a {
  color: #354052;
  text-decoration: none;
}
.footer-description .socialite-terms a:hover {
  color: #1991EB;
}
.footer-description .socialite-languages span {
  color: #354052;
}
.footer-description .socialite-languages a {
  color: #354052;
  text-decoration: none;
}
.footer-description .socialite-languages a:hover {
  color: #1991EB;
}
.socialite-form .radio label input {
  margin-top: 0px;
}
.socialite-form .radio p {
  text-transform: none;
}
.widget-left-panel .active .menu-btn i {
  color: #FFFFFF !important;
}
.socialite-form .form-group .youtube-input-group .youtube-btn {
  background: #cd201f;
  border-color: #cd201f;
}
.socialite-form .form-group .youtube-input-group .youtube-btn i {
  color: #FFFFFF;
}
.socialite-form .form-group .instagram-input-group .instagram-btn {
  background: #f56040;
  border-color: #f56040;
}
.socialite-form .form-group .instagram-input-group .instagram-btn i {
  color: #FFFFFF;
}
.socialite-form .form-group .dribbble-input-group .dribbble-btn {
  background: #ea4c89;
  border-color: #ea4c89;
}
.socialite-form .form-group .dribbble-input-group .dribbble-btn i {
  color: #FFFFFF;
}
.socialite-form .form-group .linkedin-input-group .linkedin-btn {
  background: #0077b5;
  border-color: #0077b5;
}
.socialite-form .form-group .linkedin-input-group .linkedin-btn i {
  color: #FFFFFF;
}
.socialite-form .form-group .mystore-input-group .mystore-btn {
  background: #1cbe71 !important;
  border-color: #1cbe71 !important;
  width: 38px;
  height: 35px;
  padding: 8px;
}
.socialite-form .form-group .mystore-input-group .mystore-btn img {
  width: 100%;
  height: 100%;
}
.socialite-form .form-group .mystore-input-group .mystore-btn i {
  color: #FFFFFF;
}
.socialite-form .form-group .mystore-input-group .mystore-btn.icon {
  width: 35px;
  height: 30px;
  padding-bottom: 9px;
}
.socialite-form .privacy-question {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.socialite-form .privacy-question .list-group-item .form-group {
  margin-bottom: 0px;
}
.timeline .manage-table td {
  font-weight: 300;
}
.timeline .manage-table td li {
  padding-left: 0px;
  padding-right: 0px;
}
.timeline .manage-table td li a {
  font-weight: 300;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding-left: 7px;
}
.timeline .manage-table td:nth-child(3) {
  padding-left: 0px;
}
.timeline .manage-table td:nth-child(5) {
  width: 100px;
}
.theme-block {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-bottom: 1px solid red;
}
.theme-block .theme-picture .theme-img {
  float: left;
}
.theme-block .theme-picture .theme-img img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}
.theme-block .theme-picture .theme-header {
  float: left;
}
.theme-block .theme-picture .theme-header .theme-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  margin-bottom: 0px;
}
.theme-block .theme-picture .theme-header h3:nth-child(1) {
  margin-top: -8px;
}
.theme-block .theme-picture .theme-header h3:nth-child(2) {
  margin-top: -10px;
}
.theme-block .theme-picture .theme-header h3:nth-child(3) {
  margin-top: -8px;
}
.theme-passage {
  padding-left: 27px;
  padding-top: 17px;
}
.theme-passage p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  font-weight: 400;
  margin-bottom: 0px;
}
.form-horizontal {
  padding-top: 29px;
}
.form-horizontal .form-group {
  margin-left: 12px;
  margin-right: 12px;
}
.form-horizontal .form-group:nth-child(1) {
  border-bottom: 1px solid #E6EAEE;
  padding-bottom: 37px;
  margin-bottom: 21px;
}
.form-horizontal .btn-success {
  padding: 6.5px 28px;
}
.page-header.news-heading {
  margin: 9px 0 17px;
  padding-left: 35px;
}
.announcements .datepicker {
  padding-left: 19px !important;
  padding-right: 19px !important;
}
.announcements .datepicker .form-control {
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border-left: 0px;
  border-right: 0px;
}
.announcements .form-group:nth-child(1) {
  padding-bottom: 0px;
  border-bottom: none;
}
.announcements .form-group label {
  padding-left: 0px;
  padding-right: 0px;
}
.announcements .form-group .upload-form input {
  margin-top: 3px;
}
.announcements .form-group .upload-form input:focus {
  outline: none;
}
.table.annual-statistics tr td:first-child {
  width: 200px;
  font-size: 15px;
}
.table.annual-statistics tr td {
  width: 150px;
  border-top: transparent;
  padding-bottom: 18px;
  padding-top: 18px;
}
.table.annual-statistics tr td .widget-sales {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: 1px solid #E6EAEE;
}
.table.annual-statistics tr td .widget-sales .no-of-sales {
  padding-bottom: 0px;
  padding-top: 6px;
  font-size: 25px;
}
.table.annual-statistics tr td .widget-sales .sales {
  padding-bottom: 10px;
}
.table.annual-statistics tr td .widget-sales .sales a {
  text-transform: uppercase;
  font-size: 12px;
}
.table.annual-statistics tr:last-child td {
  border-bottom: 0px;
}
@media (max-width: 700px) {
  .table.annual-statistics tr td {
    width: 0px;
  }
  .table.annual-statistics tr td .widget-sales {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.edit-form .form-group {
  padding-left: 29px;
  padding-right: 29px;
}
.edit-form .pull-right {
  padding-right: 29px;
}
@media (max-width: 620px) {
  .heading-list li a {
    font-size: 15px;
    padding: 33px 5px 8px 0px;
  }
  .heading-list li a span {
    font-size: 10px;
  }
}
.avatar-body {
  width: 78px;
  height: 70px;
  background-color: #FAFBFC;
  border: 1px solid #E6EAEE;
  border-radius: 3px;
  margin-left: 30px;
  margin-top: 9px;
  margin-bottom: 9px;
  display: inline-block;
}
.avatar-body img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  float: left;
  margin-left: -31px;
  margin-top: -1px;
}
.avatar-body .image-icon {
  padding-left: 51px;
  padding-top: 6px;
}
.avatar-body .image-icon a {
  border-bottom: 1px solid #E6EAEE;
  padding-bottom: 8px;
}
.avatar-body .image-icon a i {
  color: #C8D2DF;
  font-size: 16px;
}
.avatar-body .image-icon.delete-icon {
  padding-top: 15px;
}
.avatar-body .image-icon.delete-icon a {
  border-bottom: transparent;
}
.avatar-body .image-icon.delete-icon a i {
  font-size: 20px;
}
.pencil-icon {
  background: #1991EB;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
}
.pencil-icon i {
  color: #FFFFFF!important;
  font-size: 14px;
  line-height: 26px;
  margin-left: 1px;
}
.trash-icon {
  background: #E76877;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
}
.trash-icon i {
  color: #FFFFFF!important;
  font-size: 13px;
  line-height: 27px;
}
@media (max-width: 600px) {
  .privacy-question .list-group .list-group-item .form-group {
    margin-bottom: 8px;
  }
  .privacy-question .list-group .list-group-item .form-group label {
    padding-top: 0px;
    padding-bottom: 15px;
  }
  .privacy-question .list-group .list-group-item .form-group .form-control {
    float: none;
  }
}
.settings-block {
  text-transform: capitalize;
  background: #2EA1F8;
  padding: 20px 30px;
  font-size: 18px;
  color: #FFFFFF;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.settings-block .close-modal i {
  color: #70BBFB;
}
.settings-list {
  background-color: #222C3C;
  margin-left: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
}
.settings-list li {
  margin-left: 30px;
  margin-right: 30px;
  text-transform: capitalize;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 3px solid transparent;
}
.settings-list li:first-child {
  margin-left: 10px;
}
.settings-list li:last-child {
  margin-right: 7px;
}
.settings-list li a {
  color: #848C98;
  padding: 0px;
  border-radius: 0px;
  padding-top: 14px;
  padding-bottom: 11px;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 400;
}
.settings-list li.active {
  border-bottom: 3px solid #2EA1F8;
}
.settings-list li.active a {
  border-radius: 0px;
  color: #FFFFFF;
}
.settings-content {
  padding: 0px;
  margin-bottom: 0px;
  border: 0px;
}
.list-group {
  margin-bottom: 0px;
}
.list-group .list-group-item {
  padding: 16px 30px;
  cursor: pointer;
  font-size: 14px;
  color: #354052;
  border-left: 0px;
  border-right: 0px;
}
.list-group .list-group-item .text-toggle {
  opacity: 0.6;
  color: #354052;
}
.list-group .list-group-item .text-selected {
  opacity: 1;
  color: #354052;
}
.settings-footer {
  padding: 12px 30px;
}
.settings-footer .btn-send {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #249533;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
}
.btn-custom {
  float: right;
}
.side-left {
  float: left;
}
.side-right {
  float: right;
}
.height-50 {
  height: 50px !important;
}
body.login-bg {
  background: #1e5799;
  /* Old browsers */
  background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 51%, #7db9e8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #1e5799 0%, #2989d8 51%, #7db9e8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #1e5799 0%, #2989d8 51%, #7db9e8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=0);
  /* IE6-9 */
  background-repeat: no-repeat;
  background-attachment: fixed;
}
body.login-bg .text-white {
  color: #fff !important;
}
body.login-bg .zurra-description hr {
  border-color: transparent;
}
body.login-bg .zurra-description h3,
body.login-bg .zurra-description h2 {
  color: #fff;
}
body.login-bg .zurra-description .login-list li {
  color: #fff;
}
body.login-bg .footer-description {
  margin: 0px auto;
}
@media (min-width: 768px) {
  body.login-bg .footer-description {
    width: 750px;
  }
}
@media (min-width: 992px) {
  body.login-bg .footer-description {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  body.login-bg .footer-description {
    width: 1170px;
  }
}
body.login-bg .footer-description .socialite-terms {
  border-color: #FFFFFF;
  color: #fff;
}
body.login-bg .footer-description .socialite-terms a {
  color: #fff;
}
body.login-bg .body-bg {
  display: none;
}
.navbar {
  min-height: 50px !important;
}
.navbar.socialite {
  height: 60px;
  border-bottom: 1px solid #E6EAEE;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.navbar-brand.socialite {
  padding-top: 6px;
  height: 50px;
}
.logo {
  width: 151px;
  height: 44px;
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border-radius: 4px;
  display: block;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  padding-top: 12px;
  font-size: 20px;
  font-weight: 400;
  margin-top: 1px;
}
.logo img {
  margin-top: -2px;
  margin-left: 15px;
  margin-right: 10px;
}
.no-bg {
  background-color: #fff;
}
.form-left {
  margin-top: 15px;
  margin-bottom: 0px;
}
.form-left .form-control {
  margin-left: 0px !important;
  border-left-width: 0px;
  border-right-width: 1px !important;
  height: 30px !important;
  min-width: 360px;
}
.form-left .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.form-left .form-control .selectize-input {
  height: 30px;
  min-height: 30px;
  max-width: 360px;
  border-color: #E6EAEE;
  border-radius: 0px 4px 4px 0px;
  border-left-width: 0px;
  padding-top: 5px;
  padding-left: 8px;
  background-color: #fff;
}
.form-left .form-control .selectize-input:focus {
  outline: none;
  box-shadow: none ;
}
.form-left .form-control .selectize-input.focus {
  box-shadow: none;
}
.form-left .form-control .selectize-dropdown {
  height: auto !important;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #E6EAEE !important;
  border-top-width: 0px;
  top: -7px !important;
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content {
  padding-top: 0px;
  padding-bottom: 0px;
}
@media (max-width: 500px) {
  .form-left .form-control .selectize-dropdown .selectize-dropdown-content {
    max-width: 250px;
  }
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .optgroup-header {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  letter-spacing: 0px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 6px;
  background-color: #fff;
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown {
  padding-left: 15px;
  padding-top: 4px;
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-left {
  padding-right: 15px;
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-left img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-body .media-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  letter-spacing: 0px;
  margin-bottom: 0px;
  line-height: 19px;
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-body p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #9A9FA8;
  letter-spacing: 0px;
  max-height: 43px;
  margin-bottom: 0px;
  height: 18px;
  overflow: hidden;
  width: 250px;
  line-height: 15px;
}
.form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown.active {
  background-color: #fafbfc;
}
.form-left .input-group-btn .btn {
  border-left-width: 1px !important;
  border-right-width: 0px;
  background: #fff !important;
  height: 30px !important;
  padding-top: 4px;
  padding-right: 6px;
}
.notification-list {
  border-right: 1px solid #E6EAEE;
}
.notification-list > li {
  padding-left: 12px;
  padding-right: 12px;
}
.notification-list > li:first-child {
  padding-left: 0px;
}
.notification-list > li:last-child {
  padding-right: 26px;
}
.notification-list > li > a {
  padding-top: 19px;
  padding-bottom: 19px;
  display: block;
  text-decoration: none;
}
@media (max-width: 1010px) {
  .notification-list > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.notification-list > li > a i {
  font-size: 20px;
  color: #859AB5;
  letter-spacing: 0.46px;
  position: relative;
}
.notification-list > li > a .count {
  width: 18px;
  height: 18px;
  background: #E76877;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  top: -10px;
  line-height: 18px;
  right: -3px;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.17px;
}
.notification-list > li .small-screen {
  display: none;
  margin-left: 10px;
  text-transform: capitalize;
  color: #354052;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
}
@media (max-width: 1010px) {
  .notification-list > li .small-screen {
    display: inline-block;
  }
}
@media (max-width: 1325px) {
  .notification-list .chat-list-toggle {
    display: none;
  }
}
@media (max-width: 1325px) {
  .dropdown.message {
    padding-right: 26px;
  }
}
.dropdown.message .dropdown-menu {
  width: 370px;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 2px solid #289DF5;
  margin-top: 0px;
}
@media (max-width: 1010px) {
  .dropdown.message .dropdown-menu {
    left: 34px;
    position: static;
    border: 1px solid #E6EAEE;
    width: 100%;
    border-top: 2px solid #289DF5;
    margin-bottom: 10px;
  }
}
.dropdown.message .dropdown-menu .dropdown-menu-header {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid #E6EAEE;
}
.dropdown.message .dropdown-menu .dropdown-menu-header .side-left {
  color: #354052;
  text-transform: capitalize;
  padding-top: 7px;
  padding-bottom: 9px;
  float: left;
}
.dropdown.message .dropdown-menu .dropdown-menu-header .side-right {
  padding-top: 7px;
  padding-bottom: 9px;
  float: right;
}
.dropdown.message .dropdown-menu .dropdown-menu-header .side-right li {
  padding-right: 6px;
  border-bottom-width: 0px;
}
.dropdown.message .dropdown-menu .dropdown-menu-header .side-right li:last-child {
  padding-right: 0px;
}
.dropdown.message .dropdown-menu .dropdown-menu-header .side-right a {
  color: #289DF5;
  text-transform: capitalize;
}
.dropdown.message .dropdown-menu .dropdown-menu-footer {
  border-top: 1px solid #E6EAEE;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 6px;
  background-color: #FAFBFC;
}
.dropdown.message .dropdown-menu .dropdown-menu-footer a {
  text-transform: capitalize;
}
.dropdown.message .dropdown-menu .no-messages {
  text-align: center;
  border-bottom: 1px solid #E6EAEE;
}
.dropdown.message .dropdown-menu .no-messages i {
  padding-top: 40px;
  font-size: 100px;
  color: #7F8FA4;
  letter-spacing: 0px;
}
.dropdown.message .dropdown-menu .no-messages p {
  font-family: LucidaGrande;
  font-size: 18px;
  color: #7F8FA4;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 5px;
}
.dropdown.message .dropdown-menu .dropdown-messages-list {
  height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.dropdown.message .dropdown-menu > ul li {
  border-bottom: 1px solid #E6EAEE;
  padding-left: 15px;
  padding-right: 15px;
}
.dropdown.message .dropdown-menu > ul li:last-child {
  border-bottom: 0px;
}
.dropdown.message .dropdown-menu > ul li .media .media-left img {
  width: 42px;
  height: 42px;
  border-radius: 4px;
}
.dropdown.message .dropdown-menu > ul li .media .media-body {
  padding-left: 5px;
}
.dropdown.message .dropdown-menu > ul li .media .media-body .media-heading {
  margin-bottom: 0px;
}
.dropdown.message .dropdown-menu > ul li .media .media-body .media-heading .message-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #354052;
  letter-spacing: 0px;
  text-transform: capitalize;
  text-decoration: none;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
}
.dropdown.message .dropdown-menu > ul li .media .media-body .media-heading .online-status {
  background-image: linear-gradient(-180deg, #39B54A 0%, #34AA44 98%);
  border: 1px solid #36AF47;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 7px;
}
.dropdown.message .dropdown-menu > ul li .media .media-body .media-heading .message-time {
  float: right;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 1px;
  font-weight: 400;
  font-size: 11px;
  color: #7F8FA4;
  letter-spacing: 0px;
}
.dropdown.message .dropdown-menu > ul li .media .media-body .message-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #7F8FA4;
  letter-spacing: 0px;
  line-height: 19px;
  margin-bottom: 0px;
  height: 19px;
  overflow: hidden;
  width: 245px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown.message .dropdown-menu li.inbox-message {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dropdown.message .dropdown-menu li.inbox-message > a {
  display: block;
  width: 100%;
  text-decoration: none;
}
.dropdown.message .dropdown-menu li.inbox-message > a .media-heading {
  color: #354052;
  font-weight: 600;
}
.dropdown.message .dropdown-menu li.inbox-message:hover {
  background-color: #FAFBFC;
}
.dropdown.message .dropdown-menu li.inbox-message.active {
  background-color: #FAFBFC;
}
@media (max-width: 1325px) {
  .dropdown.message.notification {
    padding-right: 12px;
  }
}
.dropdown.message.notification .dropdown-menu > ul li .media-left img {
  width: 40px;
  height: 40px;
}
.dropdown.message.notification .dropdown-menu > ul li .media-heading .notification-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  display: block;
  line-height: 13px;
}
.dropdown.message.notification .dropdown-menu > ul li .media-heading .message-time {
  display: block;
  margin-top: 0px;
  float: left !important;
  letter-spacing: 0px;
}
.dropdown.message.notification .dropdown-menu > ul li .media-heading .message-time .notification-type {
  display: inline-block;
  background: #289DF5;
  border: 1px solid #289DF5;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50%;
  line-height: 14px;
  margin-right: 7px;
}
.dropdown.message.notification .dropdown-menu > ul li .media-heading .message-time .notification-type i {
  color: #fff;
  font-size: 10px;
}
.user-image.dropdown.socialite {
  padding-bottom: 16px;
  padding-top: 14px;
  min-width: 189px;
}
.user-image.dropdown.socialite > a {
  margin-left: 26px;
}
@media (max-width: 1010px) {
  .user-image.dropdown.socialite > a {
    margin-left: 31px;
  }
}
.user-image.dropdown.socialite > a .user-name {
  margin-left: 7px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  color: #354052;
  letter-spacing: 0.26px;
  text-transform: capitalize;
}
.user-image.dropdown.socialite > a i {
  color: #354052;
  font-size: 16px;
  border-radius: 83px;
  margin-left: 10px;
}
.user-image.dropdown.socialite > a i.fa-angle-down {
  margin-right: 13px;
}
.user-image.dropdown.socialite .dropdown-menu {
  background-color: #fff;
  width: 100%;
  border: 1px solid #E6EAEE;
  border-top: 2px solid #1585D8;
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: none;
}
.user-image.dropdown.socialite .dropdown-menu li {
  border-bottom: 1px solid #E6EAEE;
}
.user-image.dropdown.socialite .dropdown-menu li:last-child {
  border-bottom-width: 0px;
}
.user-image.dropdown.socialite .dropdown-menu li:hover a,
.user-image.dropdown.socialite .dropdown-menu li.active a {
  background-color: #1991EB !important;
  color: #FFFFFF;
}
.user-image.dropdown.socialite .dropdown-menu li:hover i,
.user-image.dropdown.socialite .dropdown-menu li.active i {
  color: #fff;
}
.user-image.dropdown.socialite .dropdown-menu li a {
  margin-left: 0px;
  padding: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #354052;
  letter-spacing: 0px;
  text-transform: capitalize;
}
@media (max-width: 1010px) {
  .user-image.dropdown.socialite .dropdown-menu li a {
    padding-left: 20px;
  }
}
.user-image.dropdown.socialite .dropdown-menu li i {
  font-size: 18px;
  color: #7F8FA4;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 20px;
}
@media (max-width: 1010px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    margin-top: 9px;
    background-color: #fff;
    border-bottom: 1px solid #E6EAEE;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-form {
    border-top-width: 0px;
    border-bottom-width: 0px;
    float: none !important;
  }
  .navbar-form .input-group {
    width: 100%;
  }
  .navbar-form .input-group .input-group-btn {
    width: 31px;
  }
  .navbar-form .input-group .selectize-input {
    max-width: none;
  }
  .navbar-form button.btn {
    margin-left: 0px;
  }
  .notification-list {
    border-right-width: 0px;
  }
  .notification-list li {
    display: block;
    padding-left: 35px;
  }
  .notification-list li:first-child {
    padding-left: 35px;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .navbar-nav {
    float: none!important;
    margin: 7.5px -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (max-width: 1010px) and (max-width: 500px) {
  .navbar-form .input-group .selectize-input {
    max-width: 400px;
  }
}
@media (max-width: 1010px) and (max-width: 398px) {
  .navbar-form .input-group .selectize-input {
    max-width: 200px;
  }
}
@media (max-width: 1010px) and (max-width: 768px) {
  .navbar-form {
    margin-left: 0px;
    width: 100%;
  }
  .navbar-form .selectize-control {
    width: 100%;
    min-width: 0px;
  }
}
.logout {
  font-size: 18px;
  color: #354052;
  padding-left: 10px;
  padding-bottom: 1px;
  margin-top: 7px;
}
.logout a {
  color: #354052 !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.main-content,
.page-wrapper {
  padding-top: 80px;
}
.container.no-cover .timeline-cover-section .timeline-cover {
  display: none;
}
.container.no-cover .timeline-cover-section .timeline-list ul {
  margin-left: 0px;
}
.container.no-cover .timeline-cover-section .timeline-list ul li.add-cover {
  display: inline-block;
}
.container.no-cover .timeline-cover-section .timeline-list .timeline-user-avtar.top {
  display: none;
}
.container.no-cover .timeline-cover-section .timeline-list .add-cover {
  text-transform: capitalize;
  color: #354052;
}
.container.no-cover .timeline-user-avtar.bottom {
  display: block;
}
.add-cover {
  display: none;
}
.timeline-user-avtar.bottom {
  display: none;
}
.panel-create .panel-heading {
  background: #FAFBFC;
  font-size: 16px;
  color: #5B6B81;
  line-height: 21px;
  border-width: 0px;
  border-bottom: 1px solid #E6EAEE;
}
.panel-create .panel-body {
  padding: 0px;
  padding-bottom: 7px;
}
.panel-create .panel-body textarea {
  border: 0px;
  box-shadow: none;
  padding-left: 0px;
  resize: none;
  font-size: 14px;
  padding: 6px 15px;
}
.panel-create .panel-footer {
  background: #FAFBFC;
  padding: 7px 15px;
  border-top-color: #E6EAEE;
}
.panel-create .panel-footer ul {
  margin: 0px;
}
.panel-create .panel-footer .left-list {
  float: left;
}
.panel-create .panel-footer .left-list li {
  padding-left: 9px;
  padding-right: 9px;
}
.panel-create .panel-footer .left-list li:first-child {
  padding-left: 0px;
}
@media (max-width: 460px) {
  .panel-create .panel-footer .left-list li {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (max-width: 400px) {
  .panel-create .panel-footer .left-list li {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.panel-create .panel-footer .left-list li a {
  color: #859AB5;
  font-size: 18px;
}
@media (max-width: 350px) {
  .panel-create .panel-footer .left-list li a {
    font-size: 15px;
  }
}
.panel-create .panel-footer .right-list {
  float: right;
}
.panel-create .panel-footer .right-list li {
  padding-left: 8px;
  padding-right: 8px;
}
.panel-create .panel-footer .right-list li:last-child {
  padding-right: 0px;
}
@media (max-width: 424px) {
  .panel-create .panel-footer .right-list li {
    padding-left: 0px;
    padding-right: 4px;
  }
}
@media (max-width: 350px) {
  .panel-create .panel-footer .right-list li {
    padding-right: 1px;
  }
}
.panel-create .panel-footer .right-list .create-album {
  color: #2298F1;
  font-size: 14px;
  text-decoration: none;
  vertical-align: middle;
}
@media (max-width: 424px) {
  .panel-create .panel-footer .right-list .create-album {
    font-size: 13px;
  }
}
@media (max-width: 350px) {
  .panel-create .panel-footer .right-list .create-album {
    font-size: 12px;
  }
}
.panel-create .panel-footer .right-list .btn {
  padding: 2px 17px;
}
@media (max-width: 350px) {
  .panel-create .panel-footer .right-list .btn {
    padding: 2px 12px;
    font-size: 12px;
  }
}
.panel-post {
  border-width: 0px;
}
.panel-post .panel-heading {
  padding: 14px 15px;
  height: auto;
  border: 1px solid #E6EAEE !important;
}
.panel-post .panel-heading .post-author .post-options {
  float: right;
  color: #859AB5;
  margin-top: 6px;
}
.panel-post .panel-heading .post-author .post-options > ul > li > a {
  color: #859AB5;
  border-radius: 83px;
  font-size: 20px;
}
.panel-post .panel-heading .post-author .post-options .dropdown-menu {
  right: -15px;
  left: auto;
}
.panel-post .panel-heading .post-author .post-options .dropdown-menu li:hover a {
  background-color: #FAFBFC;
}
.panel-post .panel-heading .post-author .post-options .dropdown-menu li a {
  color: #354052;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
}
.panel-post .panel-heading .post-author .post-options .dropdown-menu li a i {
  width: 20px;
}
.panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a {
  font-weight: 600;
  font-size: 14px;
}
@media (max-width: 500px) {
  .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 16px;
  }
}
@media (max-width: 350px) {
  .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a {
    font-size: 12px;
  }
}
.panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
  font-weight: 400;
  font-size: 14px;
  color: #7F8FA4;
  display: block;
  padding-left: 22px;
}
@media (max-width: 500px) {
  .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
    font-size: 12px;
  }
}
@media (max-width: 350px) {
  .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
    font-size: 11px;
  }
}
.panel-post .panel-heading .post-author .post-options .dropdown-menu li.active a {
  background-color: #FAFBFC;
}
.panel-post .panel-heading .post-author .user-avatar {
  width: 40px;
  height: 40px;
  float: left;
  margin-right: 10px;
}
.panel-post .panel-heading .post-author .user-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.panel-post .panel-heading .post-author .user-post-details {
  line-height: 21px;
}
.panel-post .panel-heading .post-author .user-post-details ul {
  padding-left: 50px;
}
.panel-post .panel-heading .post-author .user-post-details li {
  color: #859AB5;
  font-size: 16px;
  font-weight: 400;
}
.panel-post .panel-heading .post-author .user-post-details li .user {
  text-transform: capitalize;
}
.panel-post .panel-heading .post-author .user-post-details li .post-time {
  color: #354052;
  font-size: 12px;
  letter-spacing: 0px;
  margin-right: 3px;
}
.panel-post .panel-heading .post-author .user-post-details li:last-child {
  font-size: 14px;
}
.panel-post .panel-heading .post-author .user-post-details li:last-child i {
  margin-left: 6px;
  margin-right: 2px;
}
.panel-post .panel-heading .post-author .user-post-details li:last-child .post-place {
  text-transform: capitalize;
}
.panel-post .panel-body {
  border: 1px solid #E6EAEE;
  border-top-width: 0px;
  padding-bottom: 7px;
}
.panel-post .panel-body .text-wrapper p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #5B6B81;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin-bottom: 0px;
  word-break: break-word;
}
.panel-post .panel-body .text-wrapper .post-image-holder {
  margin-top: 10px;
  max-height: 200px;
  overflow: hidden;
}
.panel-post .panel-body .text-wrapper .post-image-holder img {
  max-width: 100%;
  max-height: 200px;
  margin-top: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 4px;
}
.panel-post .panel-body iframe {
  width: 100%;
  margin-top: 10px;
  height: 273px;
}
.panel-post .panel-body .actions-count {
  margin-top: 10px;
  margin-bottom: 0px;
}
.panel-post .panel-body .actions-count li a {
  text-decoration: none;
  font-size: 13px;
  text-transform: capitalize;
  color: #859AB5;
}
@media (max-width: 350px) {
  .panel-post .panel-body .actions-count li a {
    font-size: 12px;
  }
}
.panel-post .panel-body .actions-count li a .count-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  padding: 0px;
  background-color: #9FA9BA;
  line-height: 13px;
  margin-right: 3px;
  vertical-align: 2px;
}
.panel-post .panel-body .actions-count li a .count-circle i {
  font-size: 10px;
  color: #fff;
}
.panel-post .panel-body.image-post {
  padding: 0px;
}
.panel-post .panel-body.image-post .text-wrapper p {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.panel-post .panel-body.image-post .text-wrapper .post-image-holder {
  margin-top: 10px;
  max-height: 800px;
  overflow: hidden;
}
.panel-post .panel-body.image-post .text-wrapper .post-image-holder a {
  width: 100%;
  height: auto;
  margin-bottom: 0px;
}
.panel-post .panel-body.image-post .text-wrapper .post-image-holder a img {
  width: 100%;
  height: auto;
  border-radius: 0px;
  margin: 0px;
}
.panel-post .panel-body.image-post .actions-count {
  padding-left: 15px;
  padding-bottom: 7px;
}
.panel-post .panel-footer.socialite {
  background-color: #fff;
  border-top: 0px;
  padding: 15px;
  border: 1px solid #E6EAEE;
  border-top-width: 0px;
}
.panel-post .panel-footer.socialite .footer-list {
  margin-bottom: 0px;
}
.panel-post .panel-footer.socialite .footer-list li {
  padding-left: 14px;
  padding-right: 14px;
}
@media (max-width: 350px) {
  .panel-post .panel-footer.socialite .footer-list li {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.panel-post .panel-footer.socialite .footer-list li:first-child {
  padding-left: 5px;
}
.panel-post .panel-footer.socialite .footer-list li:nth-child(2) {
  padding-left: 5px;
}
.panel-post .panel-footer.socialite .footer-list li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #859AB5;
  text-transform: capitalize;
}
.panel-post .panel-footer.socialite .footer-list li a i {
  font-size: 16px;
  color: #9FA9BA;
  letter-spacing: 0px;
  margin-right: 4px;
}
.panel-post .comments-section {
  width: 100%;
  height: auto;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #eff3f6;
}
.panel-post .comments-section .comments-wrapper {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 26px;
  height: auto;
  background: #FCFCFC;
  border: 1px solid #E6EAEE;
  border-top-width: 0px;
}
.panel-post .comments-section .comments-wrapper .comment-form {
  position: relative;
}
.panel-post .comments-section .comments-wrapper .comment-form .meme-reply {
  position: absolute;
  top: 50%;
  margin-bottom: 0px;
  margin-top: -9px;
  right: 10px;
}
.panel-post .comments-section .comments-wrapper .comment-form .meme-reply li a i {
  font-size: 16px;
  color: #859AB5;
}
.panel-post .comments-section .comments-wrapper .to-comment {
  padding-top: 16px;
}
.panel-post .comments-section .comments-wrapper .to-comment.comment-reply {
  margin-left: 45px;
  padding-top: 12px;
}
.panel-post .comments-section .comments-wrapper .to-comment.comment-reply .commenter-avatar {
  width: 30px;
  height: 30px;
}
.panel-post .comments-section .comments-wrapper .to-comment.comment-reply .comment-textfield {
  padding-left: 45px;
}
.panel-post .comments-section .comments-wrapper .to-comment.comment-reply .comment-textfield .form-control {
  height: 30px;
  font-size: 13px;
}
.panel-post .comments-section .comments-wrapper .commenter {
  margin-top: -3px;
  padding-left: 45px;
}
.panel-post .comments-section .comments-wrapper .commenter-avatar {
  width: 36px;
  height: 36px;
  float: left;
  display: inline-block;
}
.panel-post .comments-section .comments-wrapper .commenter-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.panel-post .comments-section .comments-wrapper .comment-replies {
  margin-left: 45px;
}
.panel-post .comments-section .comments-wrapper .comment-textfield {
  padding-left: 51px;
}
.panel-post .comments-section .comments-wrapper .comment-textfield .form-control {
  border-radius: 0px;
  padding-top: 8px;
  padding-right: 60px;
  padding-bottom: 8px;
}
.panel-post .comments-section .comments-wrapper .comments {
  padding-top: 15px;
}
.panel-post .comments-section .comments-wrapper .delete-comment {
  float: right;
  margin-right: 2px;
  font-size: 14px;
  color: #7F8FA4;
}
.panel-post .comments-section .comments-wrapper .commenter-name a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #2298F1;
  letter-spacing: 0px;
  text-transform: capitalize;
}
.panel-post .comments-section .comments-wrapper .commenter-name .comment-description {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #5B6B81;
  margin-left: 8px;
  word-break: break-word;
}
.panel-post .comments-section .comments-wrapper .comment-options {
  margin-top: 1px;
}
.panel-post .comments-section .comments-wrapper .comment-options li {
  padding-left: 4px;
  padding-right: 4px;
  color: #859AB5;
}
.panel-post .comments-section .comments-wrapper .comment-options li:first-child {
  padding-left: 5px;
}
.panel-post .comments-section .comments-wrapper .comment-options li a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #859AB5;
  text-transform: capitalize;
}
.panel-post .comments-section .comments-wrapper .comment-options li a i {
  margin-right: 3px;
}
.panel-post .comments-section .comments-wrapper .replies-count {
  margin-top: 13px;
  width: 100px;
  display: block;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9FA9BA;
  margin-left: 45px;
}
.panel-post .comments-section .comments-wrapper .replies-count i {
  font-size: 16px;
  color: #9FA9BA;
  margin-right: 5px;
  vertical-align: -1px;
}
.btn-follow {
  float: right;
  margin-top: 4px;
}
.btn-follow .btn {
  padding: 4px 15px;
}
.media-body.socialte-timeline .text-muted {
  display: block;
  font-size: 14px;
  margin-top: -4px;
}
.timeline-cover-section {
  width: 100%;
  background-color: #000;
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .timeline-cover-section {
    margin-bottom: 70px;
  }
}
.timeline-cover-section .timeline-cover {
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.timeline-cover-section .timeline-cover .btn-light-video {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  display: none;
}
@media (max-width: 660px) {
  .timeline-cover-section .timeline-cover {
    min-height: 185px;
    width: auto;
  }
}
.timeline-cover-section .timeline-cover:hover .btn-camera-cover {
  display: block;
}
.timeline-cover-section .timeline-cover:hover .btn-light-video {
  display: block;
}
.timeline-cover-section .timeline-cover img {
  height: auto!important;
  width: 100%!important;
  opacity: 0.7;
  position: relative;
}
@media (max-width: 660px) {
  .timeline-cover-section .timeline-cover img {
    min-height: 185px;
    width: auto;
  }
}
.timeline-cover-section .timeline-cover .btn-play {
  position: absolute;
  top: 10px;
}
.timeline-cover-section .timeline-cover .btn-camera-cover {
  height: 40px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0px;
  text-align: left;
  position: absolute;
  top: 8px;
  left: 20px;
  display: none;
}
@media (max-width: 560px) {
  .timeline-cover-section .timeline-cover .btn-camera-cover {
    width: 148px;
    height: 40px;
  }
}
.timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
  color: #fff;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0.56px;
  margin-top: 7px;
}
@media (max-width: 560px) {
  .timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
    font-size: 15px;
    margin-right: 10px;
  }
}
.timeline-cover-section .timeline-cover .btn-camera-cover .change-cover-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-transform: none;
  font-size: 16px;
  margin-left: 12px;
  color: #FFFFFF;
  letter-spacing: 0.37px;
  vertical-align: text-bottom;
}
@media (max-width: 560px) {
  .timeline-cover-section .timeline-cover .btn-camera-cover .change-cover-text {
    font-size: 14px;
  }
}
.timeline-cover-section .timeline-cover .btn-camera-cover.save-position {
  right: 40px !important;
  left: auto !important;
}
.timeline-cover-section .timeline-cover .btn-camera-cover.save-position .change-cover-text {
  display: none;
}
.timeline-cover-section .timeline-cover .btn-camera-cover.save-position:hover .change-cover-text {
  display: inline-block;
}
.timeline-cover-section .timeline-cover .btn-change-cover {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  z-index: 996;
  top: 20px;
  left: 20px;
  display: none;
}
.timeline-cover-section .timeline-cover .btn-change-cover .btn-default.dropdown-toggle {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  text-align: left;
  border-color: transparent;
}
.timeline-cover-section .timeline-cover .btn-change-cover i.fa {
  color: #fff;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0.56px;
  margin-top: 7px;
}
@media (max-width: 560px) {
  .timeline-cover-section .timeline-cover .btn-change-cover i.fa {
    font-size: 15px;
    margin-right: 10px;
  }
}
.timeline-cover-section .timeline-cover .btn-change-cover .change-cover-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  margin-left: 12px;
  color: #FFFFFF;
  letter-spacing: 0.37px;
  vertical-align: text-bottom;
}
@media (max-width: 560px) {
  .timeline-cover-section .timeline-cover .btn-change-cover .change-cover-text {
    font-size: 14px;
  }
}
.timeline-cover-section .timeline-cover:hover .btn-change-cover {
  display: block;
}
.timeline-cover-section.with-slim .wrap {
  height: 280px;
  cursor: move;
}
.timeline-cover-section.with-slim .timeline-cover {
  height: 319px;
  max-height: 319px;
}
.timeline-cover-section.with-slim .timeline-cover img {
  width: 100%;
  min-height: 319px !important;
}
@media (max-width: 660px) {
  .timeline-cover-section.with-slim .timeline-cover img {
    height: 100% !important;
    width: auto !important;
  }
}
.timeline-cover-section.with-slim .timeline-cover .slim {
  width: 100%;
  height: 100%;
}
.timeline-cover-section.with-slim .timeline-cover .slim .slim-btn-group {
  top: 0px;
  right: 10px;
  bottom: auto;
  left: auto;
}
.timeline-cover-section.with-slim.hide-remove .slim-btn-remove {
  display: none !important;
}
.timeline-user-avtar {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -120px;
  left: 20px;
  padding: 7px;
  background-color: #fff;
  border: 1px solid #E6EAEE;
}
.timeline-user-avtar video {
  width: 133px;
  height: 133px;
}
@media (max-width: 560px) {
  .timeline-user-avtar {
    width: 120px;
    height: 120px;
    top: -90px;
  }
}
@media (max-width: 350px) {
  .timeline-user-avtar {
    width: 100px;
    height: 100px;
    top: -70px;
  }
}
.timeline-user-avtar .watch-video {
  width: 100%;
  height: 100%;
}
.timeline-user-avtar .watch-video .plyr {
  width: 100%;
  height: 100%;
  min-width: auto;
}
.timeline-user-avtar .watch-video .plyr .avatar-video {
  width: 100%;
  height: 100%;
}
.timeline-user-avtar .watch-video .plyr .plyr__play-large {
  display: none;
}
.timeline-user-avtar .watch-video .plyr--init-hide-controls .plyr__controls {
  opacity: 0;
  display: none;
}
.timeline-user-avtar .avatar-area-video-image {
  width: 100%;
  height: 100%;
  position: relative;
}
.timeline-user-avtar .avatar-area-video-image .btn-light-video {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0px;
  left: 0px;
  display: none;
}
.timeline-user-avtar img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.timeline-user-avtar .play-buttons {
  position: absolute;
  top: 7px;
  right: 11px;
  display: none;
}
.timeline-user-avtar .play-buttons .btn {
  background-color: rgba(0, 0, 0, 0.67);
  color: #fff;
  padding: 0px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
}
.timeline-user-avtar:hover .chang-user-avatar {
  display: block;
}
.timeline-user-avtar:hover .avatar-area-video-image .btn-light-video {
  display: block;
}
.timeline-user-avtar:hover .play-buttons {
  display: block;
}
.timeline-user-avtar.bottom {
  position: static;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}
.event-avatar {
  width: 50px;
  height: 50px;
  background-color: transparent;
  position: absolute;
  top: -63px;
  left: 18px;
  border: 2px solid #fff;
  border-radius: 5px;
  text-align: center;
}
.event-avatar .event-month {
  color: #fff;
  text-transform: uppercase;
  margin-top: 1px;
  font-size: 12px;
  line-height: 16px;
}
.event-avatar .event-date {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 3px;
}
.chang-user-avatar {
  position: relative;
  display: none;
}
.chang-user-avatar .btn-camera {
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 36px;
  border-radius: 0px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  color: #fff;
}
@media (max-width: 560px) {
  .chang-user-avatar .btn-camera {
    padding-left: 4px;
    font-size: 8px;
  }
}
.chang-user-avatar .btn-camera .fa {
  font-size: 16px;
  margin-top: 3px;
  margin-right: 17px;
  vertical-align: middle;
}
@media (max-width: 560px) {
  .chang-user-avatar .btn-camera .fa {
    margin-right: 4px;
  }
}
@media (max-width: 350px) {
  .chang-user-avatar .btn-camera .fa {
    font-size: 13px;
    margin-top: 5px;
  }
}
.chang-user-avatar .btn-camera .avatar-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  width: 78px;
  line-height: 10px;
  margin-top: -3px;
  vertical-align: top;
}
@media (max-width: 560px) {
  .chang-user-avatar .btn-camera .avatar-text {
    font-size: 11px;
  }
}
@media (max-width: 350px) {
  .chang-user-avatar .btn-camera .avatar-text {
    font-size: 8px;
  }
}
.chang-user-avatar .btn-camera .avatar-text span {
  display: block;
  margin-top: -5px;
}
@media (max-width: 350px) {
  .chang-user-avatar .btn-camera .avatar-text span {
    margin-top: -7px;
  }
}
.user-timeline-name {
  position: absolute;
  bottom: 22px;
  left: 193px;
}
.user-timeline-name a {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  text-decoration: none;
  letter-spacing: 0.56px;
  text-transform: capitalize;
}
@media (max-width: 560px) {
  .user-timeline-name {
    bottom: 16px;
    left: 162px;
  }
}
@media (max-width: 350px) {
  .user-timeline-name {
    bottom: 9px;
    left: 130px;
  }
  .user-timeline-name a {
    font-size: 22px;
  }
}
.user-timeline-name.event {
  left: 90px;
  bottom: 19px;
}
.status-button {
  position: absolute;
  top: 0px;
  text-align: right;
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: none;
}
@media (max-width: 1200px) {
  .status-button {
    display: block;
  }
}
.status-button .btn-status {
  background-color: #1991EB;
  color: #fff;
  margin-top: 8px;
  margin-right: 15px;
}
.user-avatar-progress {
  background-color: rgba(0, 0, 0, 0.67);
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 100%;
  color: #fff;
  text-align: center;
  padding-top: 65px;
  width: 100%;
}
.user-cover-progress {
  background-color: rgba(0, 0, 0, 0.67);
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 100%;
  color: #fff;
  text-align: center;
  padding-top: 150px;
  width: 100%;
}
.timeline-list {
  background-color: #fff;
  height: 50px;
  position: relative;
}
.timeline-list > ul {
  margin-left: 170px;
  text-align: left;
  font-size: 0px;
}
.timeline-list > ul li.add-cover {
  display: none;
}
.timeline-list > ul > li {
  border-right: 1px solid #E6EAEE;
  padding-left: 0px;
  padding-right: 0px;
}
.timeline-list > ul > li:hover > a {
  color: #FFFFFF;
  background: -webkit-linear-gradient(271deg, #1991EB 2%, #2DA1F8 98%);
  background: -o-linear-gradient(271deg, #1991EB 2%, #2DA1F8 98%);
  background: linear-gradient(1deg, #1991EB 2%, #2DA1F8 98%);
}
.timeline-list > ul > li:last-child {
  border-right-width: 0px;
}
@media (max-width: 1200px) {
  .timeline-list > ul > li:last-child {
    margin-bottom: -60px;
  }
}
.timeline-list > ul > li > a {
  display: block;
  padding: 16px 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #354052;
  text-decoration: none;
  text-transform: capitalize;
  background: #fff;
}
.timeline-list > ul > li > a i {
  font-size: 15px;
  vertical-align: bottom;
}
.timeline-list > ul > li .dropdown-menu {
  padding-top: 0px;
  padding-bottom: 0px;
  border-width: 1px solid #E6EAEE;
  border-top: 2px solid #1585d8;
  border-radius: 0px 0px 4px 4px;
}
.timeline-list > ul > li .dropdown-menu li a {
  text-align: center;
  color: #354052;
}
.timeline-list > ul > li .dropdown-menu li a i {
  margin-right: 10px;
}
.timeline-list > ul > li .dropdown-menu li:hover a {
  color: #fff;
  background-color: #1991EB;
}
.timeline-list > ul .dropdown.open:hover > a {
  background: #fff;
  color: #354052;
}
.timeline-list > ul li.active a {
  color: #FFFFFF;
  background: -webkit-linear-gradient(271deg, #1991EB 2%, #2DA1F8 98%);
  background: -o-linear-gradient(271deg, #1991EB 2%, #2DA1F8 98%);
  background: linear-gradient(1deg, #1991EB 2%, #2DA1F8 98%);
}
.timeline-list > ul li.largescreen-report {
  border-right: 1px solid transparent;
}
@media (max-width: 1200px) {
  .timeline-list > ul li.largescreen-report {
    display: none;
  }
}
.timeline-list > ul li.smallscreen-report {
  display: none;
}
@media (max-width: 1200px) {
  .timeline-list > ul li.smallscreen-report {
    display: block;
  }
  .timeline-list > ul li.smallscreen-report a {
    text-align: center;
  }
}
.timeline-list > ul li.report-button {
  position: absolute;
  right: 0px;
}
.timeline-list > ul li.report-button a {
  font-weight: 400;
  color: #7F8FA4;
}
.timeline-list > ul li.report-button a i {
  margin-right: 3px;
  vertical-align: middle;
}
.timeline-list > ul li.report-button:hover a {
  background: transparent;
  text-decoration: underline;
}
@media (max-width: 1200px) {
  .timeline-list {
    height: auto;
    min-height: 40px;
    background-color: #EFF3F6;
  }
  .timeline-list > ul {
    margin-left: 0px;
    display: none;
  }
  .timeline-list > ul li {
    border-radius: 4px;
    display: block;
    border-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .timeline-list > ul li:first-child {
    margin-top: 0px;
    padding-top: 60px;
  }
  .timeline-list > ul li:last-child {
    margin-bottom: -60px;
  }
  .timeline-list > ul li > a {
    border-radius: 4px;
    padding: 12px 16px;
  }
  .timeline-list > ul li > a .top-list {
    text-align: center;
    display: block;
    margin: 0px auto;
  }
  .timeline-list .report-dropdown li {
    margin-top: 1px;
    margin-bottom: 0px;
  }
  .timeline-list .report-dropdown li:first-child {
    padding-top: 0px;
  }
  .timeline-list .report-dropdown li a {
    padding: 8px 16px;
    border-radius: 0px 0px 4px 4px;
  }
}
.timeline-list.event > ul {
  margin-left: 0px;
}
.user-profile-buttons .left-col {
  padding-right: 10px;
}
.user-profile-buttons .right-col {
  padding-left: 10px;
}
@media (max-width: 991px) {
  .user-profile-buttons .btn {
    padding: 9px 16px;
  }
}
.user-profile-buttons .btn-profile {
  background-color: #fff;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  width: 100%;
  padding: 9px 12px;
  text-transform: capitalize;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #354052;
}
@media (max-width: 991px) {
  .user-profile-buttons .btn-profile {
    padding: 12px 17px;
  }
}
.user-profile-buttons .btn-profile i.fa {
  font-size: 14px;
  color: #354052;
  letter-spacing: 0px;
  margin-right: 10px;
}
.user-profile-buttons .btn-profile.text-center {
  text-align: center;
}
.mini-profile.socialite .avatar-img img {
  width: 60px;
  height: 60px;
}
.mini-profile.socialite .activity-list {
  text-align: center;
  margin-top: 7px;
}
.mini-profile.socialite .avatar-details {
  padding-left: 80px;
}
.mini-profile.socialite .activity-name {
  color: #354052;
}
.mini-profile.socialite .activity-count {
  color: #7F8FA4;
}
.user-tag-names {
  display: inline-block;
}
.users-results-wrapper .user-results {
  margin-bottom: 0px;
  border-top: 1px solid #E6EAEE;
}
.users-results-wrapper .user-results .user-result-item.list-group-item {
  background-color: #fff;
  color: #354052;
  border-radius: 0px;
  border-width: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  border-bottom: 2px solid #E6EAEE;
}
.users-results-wrapper .user-results .user-result-item.list-group-item:last-child {
  border-bottom-width: 1px;
}
.users-results-wrapper .user-results .user-result-item.list-group-item:hover {
  background-color: #FAFBFC;
}
.users-results-wrapper .user-results .user-result-item.list-group-item img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 5px;
  float: left;
  margin-right: 15px;
}
.users-results-wrapper .user-results .user-result-item.list-group-item span {
  display: block;
  font-size: 12px;
  color: #7F8FA4;
}
.typeahead.dropdown-menu {
  padding: 0px;
  min-width: 250px;
  top: 114px;
}
.typeahead.dropdown-menu li a {
  padding: 0px;
  background-color: #fff;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-top: 5px;
}
.typeahead.dropdown-menu li a img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 5px;
  float: left;
}
.typeahead.dropdown-menu li a .mention_name {
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  margin-left: 5px;
  font-size: 14px;
}
.typeahead.dropdown-menu li a .mention_username {
  color: #7F8FA4;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
  display: block;
  font-size: 12px;
  padding-left: 50px;
}
.typeahead.dropdown-menu li.active a {
  background-color: #337ab7;
}
.typeahead.dropdown-menu li.active a .mention_name {
  color: #fff;
}
.typeahead.dropdown-menu li.active a .mention_username {
  color: #fff;
}
select.form-control {
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff url("../images/arrow-new.png") no-repeat 97% 50%;
  background-size: 16px;
}
.group-suggested-users .holder,
.page-suggested-users .holder,
.event-suggested-users .holder,
.suggested-top-friends .holder {
  padding: 10px 15px;
}
.group-suggested-users .holder .follower img,
.page-suggested-users .holder .follower img,
.event-suggested-users .holder .follower img,
.suggested-top-friends .holder .follower img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 4px;
}
.group-suggested-users .holder .follower span,
.page-suggested-users .holder .follower span,
.event-suggested-users .holder .follower span,
.suggested-top-friends .holder .follower span {
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: 4px;
}
.group-suggested-users .holder .follow-links,
.page-suggested-users .holder .follow-links,
.event-suggested-users .holder .follow-links,
.suggested-top-friends .holder .follow-links {
  padding-top: 3px;
}
.group-suggested-users .holder .follow-links .left-col .btn,
.page-suggested-users .holder .follow-links .left-col .btn,
.event-suggested-users .holder .follow-links .left-col .btn,
.suggested-top-friends .holder .follow-links .left-col .btn {
  margin-right: 5px;
}
.page-likes .connect-list .follow-links {
  padding-top: 0px !important;
}
.page-likes .connect-list .follow-links .row {
  margin-left: 0px;
  margin-right: 0px;
}
.page-likes .connect-list .follow-links .row .padding-5 {
  padding: 5px;
}
.page-likes .connect-list .follow-links .row .padding-5 .btn {
  width: 100%;
}
.page-likes .connect-list .follow-links .row .padding-5 .remove-pagemember {
  padding-left: 7px;
}
.page-likes .connect-list .follow-links .remove-button {
  display: inline-block;
  width: 95px;
}
.panel .panel-heading {
  height: auto;
}
.holder {
  padding-left: 15px;
}
.soundcloud-results-wrapper .soundcloud-results {
  margin-bottom: 0px;
}
.soundcloud-results-wrapper .soundcloud-results .list-group-item:first-child {
  border-radius: 0px;
}
.soundcloud-results-wrapper .soundcloud-results .list-group-item {
  border-width: 0px;
  border-top: 1px solid #E6EAEE;
  padding: 10px;
  color: #354052;
  font-size: 14px;
}
.soundcloud-results-wrapper .soundcloud-results .list-group-item img {
  border-radius: 4px;
  margin-right: 1px;
}
.soundcloud-results-wrapper .soundcloud-results a.list-group-item:focus,
.soundcloud-results-wrapper .soundcloud-results a.list-group-item:hover,
.soundcloud-results-wrapper .soundcloud-results button.list-group-item:focus,
.soundcloud-results-wrapper .soundcloud-results button.list-group-item:hover {
  background-color: #fafbfc;
}
.new-conversation {
  padding-left: 15px;
  padding-right: 15px;
}
.new-conversation .selectize-dropdown {
  height: auto !important;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #E6EAEE !important;
  border-top-width: 0px;
}
.new-conversation .selectize-dropdown .selectize-dropdown-content {
  padding-top: 0px;
  padding-bottom: 0px;
}
.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown {
  padding-left: 15px;
  padding-top: 4px;
}
.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-left {
  padding-right: 15px;
}
.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-left img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-body .media-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  letter-spacing: 0px;
  margin-bottom: 0px;
  line-height: 19px;
}
.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-body p {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #9A9FA8;
  letter-spacing: 0px;
  max-height: 43px;
  margin-bottom: 0px;
  height: 18px;
  overflow: hidden;
  width: 250px;
  line-height: 15px;
}
.new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown.active {
  background-color: #fafbfc;
}
.form-control {
  -webkit-appearance: none !important;
}
.form-group.required label::after {
  content: '*' !important;
  color: #e76877;
  font-size: 15px;
  margin-left: 3px;
}
.signup-form .form-group label {
  text-transform: capitalize !important;
}
.signup-form .form-group .optional {
  font-size: 11px;
  color: #7F8FA4;
  margin-left: 2px;
}
.pagination-holder {
  text-align: center;
  padding-top: 15px;
}
.pagination-holder .pagination {
  margin: 0px;
}
.table.announcements-table tbody tr td .description {
  display: block;
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table.announcements-table tbody tr td ul.list-inline {
  margin-bottom: 0px;
}
.table.announcements-table tbody tr td .announcement-status {
  padding: 2px 8px;
}
.table.announcements-table tbody tr:last-child td {
  border-bottom: 0px;
}
.existing-products-table.socialite tr:last-child td {
  border-bottom: 0px;
}
.announcement-title {
  padding-left: 13px;
  font-size: 14px;
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.announcement-title .expiry-date {
  font-weight: 400;
  font-size: 14px;
  padding-right: 10px;
  text-transform: none;
}
.announcement-description {
  padding-left: 13px;
  padding-top: 15px;
  width: 400px;
}
.announcement-actions {
  padding-top: 15px;
  text-align: right;
}
.announcement-actions .view-by {
  display: block;
  padding-bottom: 10px;
  color: #354052;
  text-decoration: none;
  font-size: 12px;
}
.announcement-actions .view-by i {
  font-size: 10px;
}
.announcement-actions a {
  text-transform: capitalize;
}
.btn.btn-success {
  color: #fff !important;
}
.btn.btn-default {
  color: #7F8FA4!important;
}
@media (max-width: 700px) {
  .modal.fade.in {
    padding-right: 0px !important;
  }
}
.modal.fade.in .album-modal.modal-dialog,
.modal.fade.in .modal-likes.modal-dialog {
  margin: 30px auto;
}
@media (max-width: 510px) {
  .modal.fade.in .album-modal.modal-dialog,
  .modal.fade.in .modal-likes.modal-dialog {
    margin: 0px auto;
    width: 380px;
  }
  .modal.fade.in .album-modal.modal-dialog .modal-content,
  .modal.fade.in .modal-likes.modal-dialog .modal-content {
    margin-top: 50px;
  }
  .modal.fade.in .album-modal.modal-dialog .modal-content .album-body,
  .modal.fade.in .modal-likes.modal-dialog .modal-content .album-body {
    padding: 10px 16px;
  }
  .modal.fade.in .album-modal.modal-dialog .modal-content .album-body .form-group,
  .modal.fade.in .modal-likes.modal-dialog .modal-content .album-body .form-group {
    margin-bottom: 10px;
  }
}
@media (max-width: 400px) {
  .modal.fade.in .album-modal.modal-dialog,
  .modal.fade.in .modal-likes.modal-dialog {
    width: 330px;
  }
  .modal.fade.in .album-modal.modal-dialog .modal-content,
  .modal.fade.in .modal-likes.modal-dialog .modal-content {
    margin-top: 20px;
  }
}
@media (max-width: 350px) {
  .modal.fade.in .album-modal.modal-dialog,
  .modal.fade.in .modal-likes.modal-dialog {
    width: 300px;
  }
}
.time-created {
  color: #7F8FA4;
}
@media (max-width: 1010px) {
  .largescreen-message {
    display: none !important;
  }
}
.smallscreen-message {
  display: none !important;
}
@media (max-width: 1010px) {
  .smallscreen-message {
    display: block !important;
  }
}
.dropdown-loading {
  text-align: center;
}
.dropdown-loading i {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}
[v-cloak] {
  display: none;
}
.tooltip-inner {
  white-space: nowrap;
}
.installer-container {
  padding-top: 80px;
}
.settings-content {
  min-height: 300px;
  padding-bottom: 60px;
}
.settings-content p {
  text-align: center;
  font-size: 22px;
  padding-top: 100px;
}
.settings-content .btn-installer {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -45px;
}
.settings-content .fa {
  font-size: 24px !important;
}
.settings-content .fa.success {
  color: #1BB934;
}
.settings-content .fa.error {
  color: #ED1C24;
}
.installer-form {
  text-align: center;
}
.installer-form .btn {
  margin: 10px 0px;
}
.input-group.input-group-focus .input-group-addon.addon-left {
  border: 1px solid #8D98A9;
  border-right-width: 0px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
  transition-property: border-color, box-shadow !important;
  transition-duration: 0.15s, 0.15s !important;
  transition-timing-function: ease-in-out, ease-in-out !important;
  transition-delay: initial, initial !important;
}
.input-group.input-group-focus .form-control {
  border-top: 1px solid #8D98A9;
  border-bottom: 1px solid #8D98A9;
}
.input-group.input-group-focus .input-group-addon.addon-right {
  border: 1px solid #8D98A9;
  border-left-width: 0px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
  transition-property: border-color, box-shadow !important;
  transition-duration: 0.15s, 0.15s !important;
  transition-timing-function: ease-in-out, ease-in-out !important;
  transition-delay: initial, initial !important;
}
.badge-verification {
  padding-left: 10px;
  padding-top: 5px;
}
.badge-verification a {
  display: block;
  position: relative;
}
.badge-verification a .verified-badge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -3px;
  left: -6px;
  line-height: 10px;
}
.badge-verification a .verified-badge .fa {
  font-size: 11px;
}
.list-details {
  margin-bottom: 0px;
}
.list-details li {
  padding-bottom: 5px;
  text-transform: capitalize;
}
.list-details li:last-child {
  padding-bottom: 0px;
}
.list-details li.no-caps {
  text-transform: none !important;
}
.list-details li .tag-heading {
  margin-bottom: 2px;
  font-weight: bold;
}
.list-details li .badge {
  border-radius: 5px;
  background-color: #BBC5D5;
  margin-top: 5px;
}
.bio-description {
  padding-bottom: 0px !important;
  margin-top: 10px;
}
.tag-heading.song {
  margin-bottom: 7px !important;
}
hr.pad-t-b-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.event-avatar.settings {
  width: 35px;
  height: 35px;
  top: auto;
  bottom: 10px;
}
.event-avatar.settings .event-date {
  margin-top: 0px;
  font-size: 13px;
}
.event-avatar.settings .event-month {
  margin-top: 0px;
  font-size: 10px;
  line-height: 9px;
}
.avatar-details.event-settings {
  padding-left: 17px!important;
}
.report-dropdown li {
  border-bottom: 1px solid #E6EAEE;
}
.report-dropdown li a {
  padding: 8px 20px;
}
.nav-tabs.events {
  margin-top: 15px;
  margin-left: 10px;
}
.nav-tabs.events li a {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
}
.tab-content.events {
  padding: 20px 15px;
  border-width: 0px;
}
.tab-content.events .my-best-pictures {
  padding: 0px;
}
.best-pictures .new-events .list-group-item {
  border-top: transparent;
  padding: 4px 4px;
}
.best-pictures .new-events .list-group-item a img {
  width: 34px;
  height: 34px;
  border-radius: 4px;
}
.best-pictures .new-events .list-group-item .date {
  padding-left: 20px;
  padding-top: 7px;
}
.register-tabs {
  display: none;
}
.diretion-buttons {
  margin-top: 15px;
}
.diretion-buttons a {
  text-transform: capitalize;
  color: #7F8FA4 !important;
}
.diretion-buttons .btn-success {
  width: auto;
  padding: 6px 12px;
}
.diretion-buttons .btn-lg {
  width: 100%;
  padding: 10px 16px!important;
}
.tab-content.register {
  margin-bottom: 0px;
  padding: 0px;
}
.tab-content.register .btn-primary {
  color: #fff!important;
}
.tab-content.register .checkbox-pane input {
  margin-left: 0px !important;
}
.tab-content.register .checkbox-pane label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #7F8FA4;
}
.register-panel .social-connect {
  margin-top: 12px;
}
.album-panel .panel-heading {
  padding-left: 15px;
}
.album-panel .panel-heading p {
  margin-top: 10px;
  margin-bottom: -5px;
}
.album-panel .album-row {
  margin-left: -10px;
  margin-right: -10px;
}
.album-panel .album-row .col-md-4 {
  padding-right: 10px;
  padding-left: 10px;
}
.album-panel .label {
  font-size: 10px!important;
}
.widget-card {
  position: relative;
}
.widget-card:hover .widget-card-bg .slim .slim-result {
  opacity: 0.4;
}
.widget-card:hover .widget-card-bg .slim .slim-status {
  display: none;
}
.widget-card .widget-card-bg {
  height: 220px;
  width: 100%;
  overflow: hidden;
}
.widget-card .widget-card-bg .photo-holder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  position: relative;
}
.widget-card .widget-card-bg .video-holder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}
.widget-card .widget-card-bg .slim {
  width: 100%;
  height: 100%;
  background: #000;
}
.widget-card .widget-card-bg img {
  min-height: 145px;
  border-radius: 4px;
}
.widget-card .widget-card-bg iframe {
  width: 100%;
  height: auto;
}
.widget-card .upadate-project.date {
  color: #7F8FA4;
}
.widget-card .upadate-project.description {
  color: #354052;
  font-size: 14px;
}
.widget-card .widget-card-project .btn-trash {
  padding: 6px 6px;
}
.widget-card .widget-card-project .btn-trash i {
  color: #a94442;
  font-size: 16px;
}
.widget-card.wallpaper .widget-card-project .bridge-text a {
  display: inline-block;
  max-width: 200px;
}
.widget-card.wallpaper .widget-card-project .upadate-project {
  max-width: 200px;
}
.widget-card.preview .widget-card-bg {
  background: #000;
  border-radius: 4px;
}
.widget-card.preview:hover .widget-card-bg img {
  opacity: 0.4;
}
.widget-card.preview:hover .widget-card-bg iframe {
  opacity: 0.4;
}
.widget-card.preview:hover .bridge-text {
  display: block;
}
.widget-card.preview .bridge-text {
  position: absolute;
  left: 50%;
  margin-left: -56px;
  top: 50%;
  margin-top: -42px;
  display: none;
}
.widget-card.preview .bridge-text .btn {
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  background: transparent;
}
.widget-card.preview .bridge-text .btn-preview {
  display: block;
}
.widget-card.preview .bridge-text .btn-default {
  color: #fff!important;
  margin-left: 3px;
  margin-right: 3px;
}
.widget-card.preview .bridge-text .btn-single {
  margin-top: 20px;
}
.widget-card.preview .bridge-text.video-buttons {
  margin-top: -16px;
}
.widget-card.preview.with-slim {
  position: relative;
}
.widget-card.preview.with-slim:hover .btn-preview {
  display: block;
}
.widget-card.preview.with-slim:hover .slim-btn-group,
.widget-card.preview.with-slim:hover .lightgallery-item.btn-lightbox {
  display: block;
}
.widget-card.preview.with-slim .btn {
  color: #fff;
  font-size: 14px;
  background: transparent;
}
.widget-card.preview.with-slim .btn-default {
  color: #fff!important;
  margin-left: 3px;
  margin-right: 3px;
}
.widget-card.preview.with-slim .btn-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  margin-top: -36px;
  margin-left: -60px;
  border-width: 3px;
  border-color: transparent!important;
  background: rgba(0, 0, 0, 0.7);
}
.widget-card.preview.with-slim .btn-preview:hover {
  border-color: #fff!important;
}
.widget-card.preview.with-slim .btn-lightbox {
  position: absolute;
  bottom: 22px;
  left: 50%;
  margin-left: -85px;
  z-index: 4;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  border-color: transparent!important;
  border-width: 3px;
  padding: 0px;
  line-height: 36px;
}
.widget-card.preview.with-slim .btn-lightbox:hover {
  border-width: 3px;
  border-color: #fff!important;
}
.widget-card.preview.with-slim.hide-edit-remove .widget-card-bg .slim .slim-btn-group {
  display: none;
}
.widget-card.preview.with-slim.hide-edit-remove .btn-lightbox {
  margin-left: -18px;
}
.lg-thumb-outer {
  display: none;
}
.add-youtube-input {
  color: #00AEEF !important;
}
.follow-header {
  padding-left: 20px;
}
.follow-header .small-heading {
  font-weight: 600;
  padding-top: 5px;
}
.follow-header .show-link .btn-link {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #1A91EB;
  text-decoration: none;
}
.panel-body {
  padding: 5px 20px 30px 20px;
}
.who-follow .media-left {
  padding-right: 12px;
}
@media (min-width: 991px) and (max-width: 1200px) {
  .who-follow .media-left {
    padding-right: 8px;
  }
}
.who-follow .media-left a img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.who-follow .media-body .media-heading {
  margin-bottom: 7px;
  font-weight: 500;
}
@media (max-width: 1200px) and (min-width: 991px) {
  .who-follow .media-body .media-heading {
    width: 126px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.who-follow .media-body .media-heading a {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #354052;
  text-decoration: none;
  font-weight: 600;
}
.who-follow .media-body .media-heading .text-muted {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #7F8FA4;
  font-weight: 600;
}
.who-follow .media-body .btn-follow a {
  padding: 4px 10px;
  color: #354052;
  font-weight: 600;
  background-image: linear-gradient(0deg, #F2F4F7 0%, #FFFFFF 100%);
  border: 1px solid #CED0DA;
  border-radius: 4px;
}
.who-follow .media-body .btn-follow a i {
  color: #CED0DA;
  margin-right: 5px;
}
.padding-b5 {
  padding-bottom: 5px;
}
.panel-donation .form-group {
  margin-bottom: 5px;
}
.panel-donation .form-group .btn {
  width: 100%;
}
.panel-donation .panel-heading .small-heading {
  text-transform: uppercase;
}
.panel-donation .panel-body {
  padding-bottom: 15px;
}
.panel-donation .panel-body .form-group select.form-control {
  text-transform: uppercase;
}
.panel-donation .panel-body .form-group select.form-control option {
  text-transform: uppercase;
  color: red;
}
.panel-donation .panel-body .donation-row {
  margin-left: -3px;
  margin-right: -3px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.panel-donation .panel-body .donation-row .donation-col {
  padding: 3px 3px 3px 3px;
}
.panel-donation .panel-body .donation-row .donation-col .btn-primary {
  width: 100%;
  padding: 10px 0px;
  color: #fff !important;
}
.panel-donation .panel-body .donation-row .donation-col .btn {
  width: 100%;
  padding: 10px 0px;
}
.panel-donation .panel-body .donation-row .donation-col .btn:hover {
  background-color: #2DA1F8;
  border: 1px solid #1383d8;
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  color: #fff!important;
}
.panel-donation .panel-body .donation-row .donation-col .form-group {
  margin-bottom: 5px;
}
.panel-donation .panel-body .donation-row .donation-col .form-group .form-control {
  height: 42px;
}
.panel-donation .panel-body .donation-row .donation-col .input-group {
  height: 42px;
}
.panel-donation .panel-body .donation-row .donation-col .input-group .input-group-addon {
  height: 42px;
  padding: 6px 20px;
}
.panel-donation .panel-body .donation-row .donation-col .input-group .form-control {
  height: 42px;
}
.panel-donation .btn-paypal {
  background-image: url('../images/paypal.png');
  background-size: 123px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 56%;
  width: 100%;
  height: 34px;
  color: #fff !important;
}
.panel-donation .payment-divider {
  border: 1px solid #F2F3F5;
  width: 100%;
  height: 1px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}
.panel-donation .payment-divider .divider-text {
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 20px;
  text-align: center;
  font-size: 14px;
  color: #9A9FA8;
  text-transform: capitalize;
  font-weight: 600;
  background: #fff;
}
.transaction-modal .modal-header .modal-title {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #354052;
  text-decoration: none;
  letter-spacing: 0.56px;
}
.transaction-modal .modal-header .modal-title .text-muted {
  display: block;
  color: #7F8FA4;
  font-size: 18px;
}
.transaction-modal .modal-body {
  padding: 15px 30px;
}
.transaction-modal .modal-body .small-heading {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin-bottom: 14px;
  font-size: 16px;
}
.transaction-modal .modal-body .form-group {
  margin-bottom: 18px;
}
.panel.panel-top-friends .select-friends.form-group {
  line-height: 36px;
}
.panel.panel-top-friends .sp-navbar-form .form-group {
  width: 100%;
}
.panel.panel-top-friends .sp-navbar-form .form-group .form-control {
  width: 100%;
}
.panel.panel-top-friends .suggested-top-friends {
  margin: 0px 15px;
  margin-top: -9px;
  border: 1px solid #e6eaee;
  border-top-width: 0px;
  border-radius: 0px 0px 4px 4px;
}
.btn-mystore img {
  width: 38px;
  border-radius: 50%;
}
.circle-success {
  border-color: #39B54A !important;
}
.circle-danger {
  border-color: #F75954 !important;
  background-image: none !important;
}
.circle-primary {
  border-color: #1991EB !important;
  background-image: none !important;
}
.event-report i {
  margin-top: -5px;
}
.image-rotate-modal .img_prieview {
  width: 100%;
  height: 100%;
  max-height: 350px;
  overflow-y: scroll;
}
.image-rotate-modal .img_prieview img {
  width: 100%;
  height: auto;
}
.image-rotate-modal.vertical .img_prieview {
  width: auto;
  max-width: 100%;
  overflow: scroll;
  height: auto;
  text-align: center;
  max-height: 100%;
}
.image-rotate-modal.vertical .img_prieview img {
  width: auto;
  height: 100%;
}
.camel-case {
  text-transform: capitalize;
}
.wrapper {
  position: relative;
  overflow: hidden;
}
.viewer {
  width: 100%;
  height: 500px;
  border: 1px solid black;
  position: relative;
}
.viewer img {
  left: 0!important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0!important;
  margin: auto auto;
}
.mce-txt {
  color: #000000 !important;
}
.note-editor .btn {
  background-image: none !important;
}
@media (max-width: 991px) {
  .no-padding.small-screen-padding {
    padding-left: 15px!important;
    padding-right: 15px!important;
  }
}
.delete-menu .list-icon {
  background: #ED1C24 !important;
}
.delete-menu .list-icon i {
  color: #FFFFFF !important;
}
.delete-menu .list-text {
  color: #ED1C24 !important;
}
.election-header {
  background-color: #FAFBFC !important;
  margin-bottom: 20px;
  border-top-width: 0px !important;
}
.grey-header {
  padding-top: 15px;
  padding-bottom: 15px !important;
  background-color: #FAFBFC !important;
  margin-bottom: 10px;
}
.no-bold {
  font-weight: 400 !important;
}
.font14 {
  font-size: 14px !important;
}
.user-bio-block {
  padding: 0px 0px 21px !important;
}
.bio-description {
  padding: 0px 19px !important;
}
.padding-15 {
  padding: 15px !important;
}
.message-delete {
  display: none;
  position: absolute;
  right: 10px;
  top: 5px;
}
.message-delete i {
  color: #E76877;
}
.message-conversation.with-delete {
  position: relative;
}
.message-conversation.with-delete:hover .message-delete {
  display: inline-block;
}
.message-conversation.with-delete:first-child:hover .message-delete {
  display: inline-block;
  top: 10px!important;
}
.message-conversation.delete-chatbox.current-user:hover p {
  padding-left: 20px !important;
}
.message-conversation.delete-chatbox.current-user:hover .message-delete {
  right: auto;
  left: 1px;
  top: 0px;
  display: block;
}
.message-conversation.delete-chatbox.current-user:hover .message-delete i {
  font-size: 16px;
}
.message-conversation.delete-chatbox.current-user p {
  position: relative;
}
.slim-image-editor .slim-container {
  height: calc(84%) !important;
}
.listen-audio .plyr {
  min-width: auto !important;
}
.listen-audio .plyr__controls {
  display: none !important;
}
.no-caps {
  text-transform: none !important;
}
.zurra-description .login-list {
  margin-top: 20px;
}
.zurra-description .login-list li {
  font-size: 17px;
  line-height: 40px;
  letter-spacing: 0.7px;
}
.zurra-description .login-list li i {
  font-size: 25px;
  margin-right: 20px;
  vertical-align: sub;
}
.sp-navbar-form .form-group {
  width: 100%;
}
.sp-navbar-form .form-group .form-control {
  width: 100%;
}
.panel.panel-wallpaper .panel-heading .pull-right {
  margin-top: 14px;
}
.panel.panel-wallpaper .panel-heading .pull-right .btn i {
  font-size: 12px;
  margin-right: 2px;
}
.wallpaper .bridge-text {
  margin-left: -45px !important;
}
.padding-r15 {
  padding-right: 15px !important;
}
.friend-requests {
  padding: 15px 30px;
}
.friend-requests .holder {
  padding: 15px;
  border-bottom: 1px solid #EEEEEE;
}
.friend-requests .holder .follower a {
  color: #354052;
  font-family: 'Source Sans Pro', sans-serif;
  margin-left: 4px;
}
.friend-requests .holder .left-col a {
  margin-right: 10px;
}
.friend-requests .holder .img-icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 4px;
}
.delete-thread {
  position: absolute;
  right: 20px;
  top: 20px;
}
.slim-btn-group,
.lightgallery-item.btn-lightbox {
  display: none;
}
.slim-btn-group {
  padding-left: 20px !important;
}
.lg-sub-html {
  display: none !important;
}
.home-img {
  overflow: hidden;
  max-width: 100%;
}
.checkbox.widget-checkbox {
  width: 25px;
  margin: 0px;
  position: relative;
}
.checkbox.widget-checkbox input[type=checkbox] {
  display: none;
}
.checkbox.widget-checkbox input[type=checkbox] + label {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 10px;
  left: 10px;
  background: #fff;
  border: 1px solid #ddd;
  z-index: 10;
}
.checkbox.widget-checkbox input[type=checkbox] + label.extra-space {
  top: 20px;
  left: 20px;
}
.checkbox.widget-checkbox input[type=checkbox]:checked + label {
  opacity: 1;
  background-color: #5cb85c;
  border: 1px solid #5cb85c;
}
.checkbox.widget-checkbox input[type=checkbox] + label:after {
  opacity: 0;
  content: '';
  position: absolute;
  width: 15px;
  height: 8px;
  background: transparent;
  top: 6px;
  left: 4px;
  border: 3px solid #333333;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
.checkbox.widget-checkbox input[type=checkbox] + label:hover::after {
  opacity: 1;
  border-color: #FFFFFF;
}
.checkbox.widget-checkbox input[type=checkbox]:checked + label:after {
  opacity: 1;
  color: #fff;
  border-color: #FFFFFF;
}
.grid-photos li {
  padding: 0px !important;
}
.grid-photos li .checkbox-panel,
.grid-photos li .checkbox-panel-videos {
  margin-bottom: 0px !important;
}
.grid-photos li .checkbox-panel .widget-card,
.grid-photos li .checkbox-panel-videos .widget-card {
  padding: 1px !important;
}
.grid-photos li .checkbox-panel .widget-card-bg.pd-5,
.grid-photos li .checkbox-panel-videos .widget-card-bg.pd-5 {
  padding: 10px !important;
  background: #C5CBD3 !important;
}
.grid-photos li .checkbox-panel .widget-card-bg.pd-5 .btn-lightbox,
.grid-photos li .checkbox-panel-videos .widget-card-bg.pd-5 .btn-lightbox {
  bottom: 32px;
}
.grid-photos li .checkbox-panel-videos .widget-card-bg img {
  max-height: 200px;
}
body.direction-rtl {
  direction: rtl;
}
body.direction-rtl .navbar-form button.btn {
  margin-left: 0px;
}
body.direction-rtl .form-left .form-control {
  margin-left: 0px !important;
  border-left-width: 1px solid #E6EAEE !important;
  border-right-width: 0px!important;
  border-radius: 4px 0px 0px 4px;
  max-width: 360px !important;
}
@media (max-width: 1010px) {
  body.direction-rtl .form-left .form-control {
    max-width: initial!important;
  }
}
body.direction-rtl .form-left .form-control .selectize-input {
  border-radius: 4px 0px 0px 4px;
  border-left-width: 1px;
  padding-right: 8px;
  border-right-width: 0px!important;
}
body.direction-rtl .form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown {
  padding-left: 0px;
  padding-right: 15px;
}
body.direction-rtl .form-left .form-control .selectize-dropdown .selectize-dropdown-content .optgroup .big-search-dropdown .media-left {
  padding-right: 0px;
  padding-left: 15px;
}
body.direction-rtl .form-left .input-group-btn .btn {
  border-right-width: 1px !important;
  border-left-width: 0px !important;
  border-radius: 0px 4px 4px 0px;
}
body.direction-rtl .notification-list {
  padding-right: 0px;
}
body.direction-rtl .notification-list > li {
  padding-left: 12px;
  padding-right: 12px;
}
body.direction-rtl .notification-list > li:first-child {
  padding-left: 12px;
  padding-right: 24px;
}
body.direction-rtl .notification-list > li:last-child {
  padding-right: 12px;
}
body.direction-rtl .notification-list > li .small-screen {
  margin-right: 10px;
}
@media (max-width: 1010px) {
  body.direction-rtl .notification-list .smallscreen-message {
    padding-right: 26px;
  }
}
@media (max-width: 767px) {
  body.direction-rtl .notification-list .smallscreen-message {
    padding-right: 16px;
  }
}
@media (max-width: 1325px) {
  body.direction-rtl .dropdown.message {
    padding-right: 12px;
  }
}
@media (max-width: 1010px) {
  body.direction-rtl .dropdown.message .dropdown-menu {
    left: 34px;
    position: static;
  }
}
body.direction-rtl .dropdown.message .dropdown-menu .dropdown-menu-header .side-left {
  float: right;
}
body.direction-rtl .dropdown.message .dropdown-menu .dropdown-menu-header .side-right {
  float: left;
}
body.direction-rtl .dropdown.message .dropdown-menu .dropdown-messages-list {
  padding-right: 0px;
}
body.direction-rtl .dropdown.message .dropdown-menu > ul li .media .media-left {
  padding-right: 0px;
  padding-left: 10px;
}
body.direction-rtl .dropdown.message .dropdown-menu > ul li .media .media-body {
  padding-left: 0px;
  padding-right: 5px;
}
body.direction-rtl .dropdown.message .dropdown-menu > ul li .media .media-body .media-heading {
  text-align: right;
}
body.direction-rtl .dropdown.message .dropdown-menu > ul li .media .media-body .media-heading .online-status {
  margin-left: 0px;
  margin-right: 7px;
}
body.direction-rtl .dropdown.message .dropdown-menu > ul li .media .media-body .media-heading .message-time {
  float: left;
}
body.direction-rtl .dropdown.message .dropdown-menu > ul li .media .media-body .message-text {
  text-align: right;
}
@media (max-width: 1325px) {
  body.direction-rtl .dropdown.message.notification {
    padding-right: 26px;
  }
}
@media (max-width: 767px) {
  body.direction-rtl .dropdown.message.notification {
    padding-right: 16px;
  }
}
body.direction-rtl .dropdown.message.notification .dropdown-menu > ul li .media-heading .message-time {
  float: right !important;
}
body.direction-rtl .dropdown.message.notification .dropdown-menu > ul li .media-heading .message-time .notification-type {
  text-align: center;
  margin-right: 7px;
}
body.direction-rtl .dropdown.message.notification .dropdown-menu > ul li .media-heading .message-time .notification-type i {
  color: #fff;
  font-size: 10px;
}
body.direction-rtl .user-image.dropdown.socialite > a {
  margin-left: 24px;
}
@media (max-width: 1010px) {
  body.direction-rtl .user-image.dropdown.socialite > a {
    margin-left: 31px;
    margin-right: 26px;
  }
}
@media (max-width: 1010px) {
  body.direction-rtl .user-image.dropdown.socialite > a {
    margin-right: 16px;
  }
}
body.direction-rtl .user-image.dropdown.socialite > a .user-name {
  margin-right: 7px;
  margin-left: 7px;
}
body.direction-rtl .user-image.dropdown.socialite > a i {
  margin-left: 0px;
}
body.direction-rtl .user-image.dropdown.socialite > a i.fa-angle-down {
  margin-right: 0px;
}
body.direction-rtl .user-image.dropdown.socialite .dropdown-menu li a {
  margin-left: 0px;
  text-align: right;
}
@media (max-width: 1010px) {
  body.direction-rtl .user-image.dropdown.socialite .dropdown-menu li a {
    padding-left: 20px;
  }
}
body.direction-rtl .user-image.dropdown.socialite .dropdown-menu li i {
  margin-left: 20px;
  margin-right: 10px;
}
@media (max-width: 1010px) {
  body.direction-rtl .navbar-collapse .navbar-right {
    padding-right: 0px;
  }
}
body.direction-rtl .panel-create .panel-footer .left-list {
  float: right;
  padding-right: 0px;
}
body.direction-rtl .panel-create .panel-footer .left-list li:first-child {
  padding-right: 0px;
  padding-left: 9px;
}
body.direction-rtl .panel-create .panel-footer .right-list {
  float: left;
  padding-right: 0px;
}
body.direction-rtl .panel-create .panel-footer .right-list li {
  padding-left: 0px;
}
body.direction-rtl .post-addon .post-addon-icon {
  float: right!important;
  border-left: 1px solid #d3d4d5;
  border-right-width: 0px!important;
}
body.direction-rtl .post-addon .form-group {
  padding-left: 0px!important;
  padding-right: 36px;
}
body.direction-rtl .post-addon .form-group .selectize-control .selectize-input {
  padding-left: 12px!important;
  padding-right: 0px;
  padding-top: 8px;
}
body.direction-rtl .post-addon .form-group .selectize-control .selectize-dropdown {
  padding-left: 0px;
}
body.direction-rtl .post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown {
  padding-left: 15px;
  padding-right: 15px !important;
}
body.direction-rtl .post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-left {
  padding-right: 0px !important;
  padding-left: 10px;
}
body.direction-rtl .smiley-pics-content {
  padding: 5px 15px 5px 0px;
}
body.direction-rtl .panel-post .panel-heading .post-author .post-options {
  float: left;
}
body.direction-rtl .panel-post .panel-heading .post-author .post-options .dropdown-menu {
  left: -15px;
  right: auto;
}
body.direction-rtl .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link {
  text-align: right;
}
body.direction-rtl .panel-post .panel-heading .post-author .post-options .dropdown-menu .main-link a .small-text {
  padding-right: 22px;
}
body.direction-rtl .panel-post .panel-heading .post-author .user-avatar {
  float: right;
  margin-right: 0px;
}
body.direction-rtl .panel-post .panel-heading .post-author .user-post-details ul {
  padding-left: 0px;
  padding-right: 50px;
}
body.direction-rtl .panel-post .panel-body .text-wrapper .post-image-holder img {
  margin-right: 0px;
  margin-left: 3px;
}
body.direction-rtl .panel-post .panel-body .actions-count {
  padding-right: 0px;
  margin-left: 0px;
  margin-right: -5px;
}
body.direction-rtl .panel-post .panel-body .actions-count li a .count-circle {
  margin-right: 0px;
  margin-left: 3px;
}
body.direction-rtl .panel-post .panel-footer.socialite .footer-list {
  padding-right: 0px;
  margin-left: 0px;
  margin-right: -5px;
}
body.direction-rtl .panel-post .panel-footer.socialite .footer-list li:first-child {
  padding-left: 14px;
  padding-right: 5px;
}
body.direction-rtl .panel-post .panel-footer.socialite .footer-list li:nth-child(2) {
  padding-left: 14px;
  padding-right: 5px;
}
body.direction-rtl .panel-post .panel-footer.socialite .footer-list li a i {
  margin-right: 0px;
  margin-left: 4px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-form .meme-reply {
  left: 10px;
  right: auto;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .to-comment.comment-reply {
  margin-left: 0px;
  margin-right: 45px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .to-comment.comment-reply .comment-textfield {
  padding-left: 0px;
  padding-right: 45px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .main-comment {
  padding-right: 0px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter {
  padding-left: 0px;
  padding-right: 45px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter-avatar {
  float: right;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-replies {
  margin-left: 0px;
  margin-right: 45px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-replies .comment-replys {
  padding-right: 0px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-textfield {
  padding-left: 0px;
  padding-right: 51px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-textfield .form-control {
  padding-right: 12px;
  padding-left: 60px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .delete-comment {
  float: left;
  margin-right: 0px;
  margin-left: 2px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter-name a {
  display: inline-block;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .commenter-name .comment-description {
  margin-right: 8px;
  margin-left: 0px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options {
  padding-right: 0px;
  margin-right: -5px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options li {
  padding-left: 4px;
  padding-right: 4px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options li:first-child {
  padding-left: 5px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .comment-options li a i {
  margin-right: 0px;
  margin-left: 3px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .replies-count {
  margin-top: 13px;
  margin-left: 0px;
  margin-right: 45px;
}
body.direction-rtl .panel-post .comments-section .comments-wrapper .replies-count i {
  margin-right: 0px;
  margin-left: 5px;
}
body.direction-rtl .close {
  float: left;
}
body.direction-rtl .btn-follow {
  float: left;
}
body.direction-rtl .user-follow .media-left {
  padding-right: 0px;
  padding-left: 10px;
}
body.direction-rtl .timeline-cover-section .timeline-cover .btn-camera-cover {
  text-align: right;
  right: 20px;
  left: auto;
}
body.direction-rtl .timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
  margin-right: 0px;
  margin-left: 12px;
}
@media (max-width: 560px) {
  body.direction-rtl .timeline-cover-section .timeline-cover .btn-camera-cover i.fa {
    margin-right: 0px;
    margin-left: 10px;
  }
}
body.direction-rtl .timeline-user-avtar {
  left: auto;
  right: 20px;
}
body.direction-rtl .chang-user-avatar .btn-camera {
  left: auto;
  right: 0px;
}
@media (max-width: 560px) {
  body.direction-rtl .chang-user-avatar .btn-camera {
    padding-left: 0px;
    padding-right: 4px;
  }
}
body.direction-rtl .chang-user-avatar .btn-camera .fa {
  margin-right: 0px;
  margin-left: 17px;
  vertical-align: middle;
}
@media (max-width: 560px) {
  body.direction-rtl .chang-user-avatar .btn-camera .fa {
    margin-right: 0px;
    margin-left: 4px;
  }
}
body.direction-rtl .user-timeline-name {
  left: auto;
  right: 193px;
}
@media (max-width: 560px) {
  body.direction-rtl .user-timeline-name {
    left: auto;
    right: 162px;
  }
}
@media (max-width: 350px) {
  body.direction-rtl .user-timeline-name {
    right: 130px;
    left: auto;
  }
}
body.direction-rtl .status-button {
  text-align: left;
}
body.direction-rtl .status-button .btn-status {
  margin-right: 0px;
  margin-left: 15px;
}
body.direction-rtl .user-avatar-progress {
  left: auto;
  right: 0px;
}
body.direction-rtl .user-cover-progress {
  left: auto;
  right: 0px;
}
body.direction-rtl .timeline-list > ul {
  padding-right: 0px;
  margin-left: auto;
  margin-right: 170px;
  text-align: right;
}
body.direction-rtl .timeline-list > ul > li:last-child {
  border-right-width: 1px;
}
body.direction-rtl .timeline-list > ul > li:first-child {
  border-right-width: 0px;
}
body.direction-rtl .timeline-list > ul > li .dropdown-menu li a i {
  margin-right: 0px;
  margin-left: 10px;
}
body.direction-rtl .timeline-list > ul li.largescreen-report {
  border-right: 1px solid #E6EAEE;
}
@media (max-width: 1200px) {
  body.direction-rtl .timeline-list > ul {
    margin-left: auto;
    margin-right: 0px;
  }
}
body.direction-rtl .typeahead.dropdown-menu {
  left: auto!important;
  right: 16px!important;
}
body.direction-rtl .typeahead.dropdown-menu li a {
  padding-left: 15px;
  padding-right: 15px;
}
body.direction-rtl .typeahead.dropdown-menu li a img {
  margin-right: 0px;
  margin-left: 5px;
  float: right;
}
body.direction-rtl .typeahead.dropdown-menu li a .mention_name {
  margin-left: 0px;
  margin-right: 5px;
}
body.direction-rtl .typeahead.dropdown-menu li a .mention_username {
  padding-left: 0px;
  padding-right: 50px;
}
body.direction-rtl .verified-badge {
  margin-right: 10px;
  margin-left: 0px;
}
body.direction-rtl .chat-conversation-list {
  padding-right: 0px;
}
body.direction-rtl .new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown {
  padding-left: 12px;
  padding-right: 15px;
}
body.direction-rtl .new-conversation .selectize-dropdown .selectize-dropdown-content .big-search-dropdown .media-left {
  padding-right: 0px;
  padding-left: 15px;
}
body.direction-rtl .messages-page .panel-body .message-col-4 {
  border-right-width: 0px;
  border-left: 1px solid #E6EAEE;
}
body.direction-rtl .chat-box .chat-box-header .side-left {
  padding-left: 0px;
  padding-right: 10px;
}
body.direction-rtl .chat-box .chat-box-header .list-inline {
  padding-right: 5px;
  margin-left: 0px;
}
body.direction-rtl .left-sidebar.socialite .following-group {
  padding-right: 0px;
}
body.direction-rtl .left-sidebar.socialite .following-group .list-group-item a .media-left {
  padding-left: 12px;
  padding-right: 0px;
}
@media (max-width: 1660px) {
  body.direction-rtl .left-sidebar.socialite {
    direction: ltr;
  }
  body.direction-rtl .left-sidebar.socialite .following-group {
    padding-right: 0px;
  }
  body.direction-rtl .left-sidebar.socialite .following-group .list-group-item a .media-left {
    padding-left: 0px;
    padding-right: 12px;
  }
  body.direction-rtl .left-sidebar.socialite:hover {
    direction: rtl;
  }
  body.direction-rtl .left-sidebar.socialite:hover .following-group {
    padding-right: 0px;
  }
  body.direction-rtl .left-sidebar.socialite:hover .following-group .list-group-item a .media-left {
    padding-left: 12px;
    padding-right: 0px;
  }
}
body.direction-rtl select.form-control {
  direction: ltr!important;
  padding-right: 35px!important;
  padding-left: 12px !important;
}
body.direction-rtl .message-conversation .media .media-body .post-text {
  margin-right: 4px;
}
body.direction-rtl .event-avatar {
  left: auto;
  right: 18px;
}
body.direction-rtl .user-timeline-name.event {
  left: auto;
  right: 90px;
}
body.direction-rtl .timeline-list.event > ul {
  margin-right: 0px;
}
body.direction-rtl .avatar-details.event-settings {
  padding-left: 0px!important;
  padding-right: 17px !important;
}
body.direction-rtl .form_datetime {
  direction: ltr;
}
body.direction-rtl .widget-left-panel .menu-list ul {
  padding-right: 0px;
}
body.direction-rtl .widget-left-panel .menu-list ul li .menu-btn {
  text-align: right;
}
body.direction-rtl .widget-left-panel .menu-list ul li .menu-btn i {
  padding-left: 10px;
}
body.direction-rtl .widget-left-panel .menu-list ul li .event-circle {
  float: left;
}
body.direction-rtl .widget-left-panel .menu-heading {
  padding-right: 29px;
}
body.direction-rtl .widget-left-panel .categotry-list ul {
  padding-right: 26px;
}
body.direction-rtl .widget-left-panel .categotry-list ul li .hash-icon {
  margin-left: 5px;
}
body.direction-rtl .mini-profile.socialite .background .avatar-img {
  right: 8px;
  left: auto;
}
body.direction-rtl .mini-profile.socialite .avatar-profile .avatar-details {
  padding-right: 80px;
  padding-left: 0px;
}
body.direction-rtl .mini-profile .background .avatar-img {
  right: 8px;
  left: auto;
}
body.direction-rtl .mini-profile .avatar-profile .avatar-details {
  padding-right: 94px;
  padding-left: 0px;
}
body.direction-rtl .user-follow .socialte-timeline .btn-follow {
  float: left;
  margin-top: 10px;
}
body.direction-rtl .post-filters .mini-profile .avatar-profile .avatar-details {
  padding-right: 94px;
}
body.direction-rtl .socialite-group .list-group-item .list-icon {
  float: right;
}
body.direction-rtl .socialite-group .list-group-item .list-text {
  margin-right: 60px;
  margin-left: 0px;
}
body.direction-rtl .socialite-form .facebook-input-group .input-group-addon {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
body.direction-rtl .socialite-form .facebook-input-group .form-control {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
body.direction-rtl .socialite-form .facebook-input-group .form-control:focus {
  border-left-color: #354052;
}
body.direction-rtl .panel-heading.panel-settings {
  padding-right: 27px;
}
body.direction-rtl .privacy-question .list-group {
  padding-right: 0px;
}
body.direction-rtl .privacy-question .list-group .list-group-item .form-control {
  float: left !important;
  padding-right: 25px;
}
body.direction-rtl .affiliate-settings .affiliate-buttons a i {
  padding-left: 9px;
}
body.direction-rtl .affiliate-settings .affliate-followers {
  padding-right: 0px;
}
body.direction-rtl .user-profile-buttons .btn-profile i {
  margin-left: 10px;
}
body.direction-rtl .user-bio-block .bio-list {
  padding-right: 0px;
}
body.direction-rtl .user-bio-block .bio-list li i {
  margin-left: 5px;
}
body.direction-rtl .side-left {
  float: right;
}
body.direction-rtl .side-right {
  float: left;
}
body.direction-rtl .page-likes .connect-list .connect-link {
  padding-right: 0px;
}
body.direction-rtl .page-likes .connect-list img {
  margin-left: 20px;
  margin-right: 0px !important;
}
body.direction-rtl .group-suggested-users .holder .side-left,
body.direction-rtl .page-suggested-users .holder .side-left,
body.direction-rtl .event-suggested-users .holder .side-left,
body.direction-rtl .suggested-top-friends .holder .side-left {
  padding-right: 0px;
}
body.direction-rtl .group-suggested-users .holder .side-left a img,
body.direction-rtl .page-suggested-users .holder .side-left a img,
body.direction-rtl .event-suggested-users .holder .side-left a img,
body.direction-rtl .suggested-top-friends .holder .side-left a img {
  margin-left: 4px;
}
body.direction-rtl .group-suggested-users .holder .side-left a span,
body.direction-rtl .page-suggested-users .holder .side-left a span,
body.direction-rtl .event-suggested-users .holder .side-left a span,
body.direction-rtl .suggested-top-friends .holder .side-left a span {
  margin-right: 4px;
}
body.direction-rtl .group-suggested-users .holder .side-right,
body.direction-rtl .page-suggested-users .holder .side-right,
body.direction-rtl .event-suggested-users .holder .side-right,
body.direction-rtl .suggested-top-friends .holder .side-right {
  padding-left: 0px;
}
body.direction-rtl .messages-page .page-heading {
  float: right;
}
body.direction-rtl .messages-page .user-info-bk {
  float: left;
}
body.direction-rtl .messages-page .message-box .input-group .form-control {
  border-left: 0px !important;
  border-right: 1px solid #DFE3E9 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
body.direction-rtl .messages-page .message-box .input-group .input-group-btn .btn {
  border-right: 0px !important;
  border-left: 1px solid #DFE3E9 !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
body.direction-rtl .messages-page .message-box .coversation-tree .left-side {
  float: right !important;
}
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread {
  padding-right: 0px;
}
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation {
  padding-right: 0px;
}
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list {
  padding-right: 0px;
}
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list .media-left {
  padding-left: 10px;
  padding-right: 0px;
}
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list .media-body {
  padding-right: 10px;
  padding-left: 0px;
}
body.direction-rtl .messages-page .message-box .coversation-tree .coversations-thread .message-conversation .post-list .media-body .media-heading .text-muted {
  float: left;
  margin-left: 11px;
  margin-right: 0px;
}
body.direction-rtl .messages-page .message-box .coversations-list {
  padding-right: 0px;
}
body.direction-rtl .messages-page .message-box .coversations-list .message-holder.active {
  border-right: 3px solid #2399F1 !important;
  border-left: transparent;
}
body.direction-rtl .messages-page .message-box .coversations-list .message-holder a .post-list {
  padding-right: 8px;
}
body.direction-rtl .messages-page .message-box .coversations-list .message-holder a .post-list .media-left {
  padding-left: 10px;
  padding-right: 0px;
}
body.direction-rtl .messages-page .message-box .coversations-list .message-holder a .post-list .media-body {
  padding-right: 5px;
  padding-left: 0px;
}
body.direction-rtl .messages-page .new-message .form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
body.direction-rtl .messages-page .new-message .input-group-btn .btn {
  border-radius: 4px 0px 0px 4px;
}
body.direction-rtl .socialite-form .form-group .radio label {
  margin-right: 20px;
}
body.direction-rtl .socialite-form .form-group .radio label input {
  margin-right: -20px;
}
body.direction-rtl .widget-pictures .side-left {
  padding-right: 17px;
  padding-left: 0px;
}
body.direction-rtl .widget-pictures .side-right {
  padding-left: 17px;
}
body.direction-rtl .socialite-form .form-control {
  padding-left: 35px;
  padding-right: 12px !important;
}
body.direction-rtl .btn-rtl {
  float: left;
}
body.direction-rtl .announcement-container table thead tr th {
  text-align: right;
}
body.direction-rtl .announcement-container table tbody tr td ul {
  padding-right: 0px;
}
body.direction-rtl .manage-table table thead tr th {
  text-align: right;
}
body.direction-rtl .manage-table table tbody td ul {
  padding-right: 0px;
}
.create-body .create-list-group .list-group-item {
  border-color: #E6EAEE!important;
}
.create-body .create-list-group .list-group-item:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.create-body .create-list-group .form-control {
  float: none!important;
  width: 100%!important;
  border-color: #E6EAEE!important;
}
.create-body .create-list-group .create-form {
  padding-right: 0px;
}
.create-body .create-list-group .create-form .no-bg {
  background: #fff!important;
}
.create-body .side-label {
  line-height: 25px;
  font-size: 14px;
}
.create-body .btn-success-create {
  padding: 7px 20px;
}
.create-body .line-separator {
  border-bottom: 1px solid #E6EAEE;
}
.create-body .select-option .radio-header {
  color: #354052;
  font-size: 14px;
  text-transform: none;
}
.create-body .select-option i {
  margin-right: 5px;
  color: #7F8FA4;
}
.create-body .select-option .radio-text {
  font-size: 14px;
  color: #7F8FA4;
  text-transform: none;
  margin-bottom: 0px;
  margin-left: 15px;
}
.right-panel .privacy-question {
  padding-bottom: 0px;
}
.right-panel .privacy-question .right-list-group {
  margin-bottom: 10px;
}
.right-panel .privacy-question .right-list-group .list-group-item .holder {
  padding-left: 0px;
}
.right-panel .privacy-question .right-list-group .list-group-item:first-child {
  padding-top: 7px;
}
.right-panel .privacy-question .right-list-group .list-group-item:last-child {
  padding-bottom: 0px;
}
.right-panel .privacy-question .right-list-group .list-group-item .right-side-label {
  font-size: 14px;
  text-transform: none;
  color: #354052;
  font-weight: 400;
}
.right-panel .privacy-question .right-list-group .list-group-item .page-description {
  color: #7F8FA4;
  font-size: 12px;
  margin-top: 10px;
}
.user-profile-buttons {
  margin-bottom: 20px !important;
}
.user-bio-block {
  background: #FFFFFF;
  margin-bottom: 20px;
  border: 1px solid #E6EAEE;
  border-radius: 4px 4px 0px 0px;
  padding: 18px 21px;
  font-size: 14px;
}
.user-bio-block .bio-header {
  color: #354052;
  font-weight: 600;
}
.user-bio-block .privacy-type {
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6EAEE;
}
.user-bio-block .privacy-type .fa {
  color: #354052;
  margin-right: 5px;
  margin-left: 5px;
}
.user-bio-block .bio-description {
  color: #5B6B81;
}
.user-bio-block .bio-description p iframe {
  width: 100% !important;
  height: auto !important;
}
.user-bio-block .bio-list {
  margin-bottom: 0px;
}
.user-bio-block .bio-list li {
  margin-bottom: 7px;
}
.user-bio-block .bio-list li:last-child {
  margin-bottom: 0px;
}
.user-bio-block .bio-list li i {
  font-size: 16px;
  color: #BBC5D5;
  width: 20px;
  margin-right: 5px;
  vertical-align: top;
}
.user-bio-block .bio-list li span {
  color: #354052;
  font-weight: 600;
}
.user-bio-block .bio-list li div {
  color: #354052;
  font-weight: 600;
  display: inline-block;
}
.user-bio-block .bio-list li div {
  display: inline-block;
  max-width: 185px;
}
.user-bio-block .bio-list li div span {
  display: block;
}
.widget-best-pictures {
  background: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 0px;
  border-radius: 4px;
  border: 1px solid #E6EAEE;
}
.widget-best-pictures .picture {
  padding-bottom: 0px;
  text-transform: capitalize;
}
.widget-best-pictures .pull-right.show-all {
  padding-right: 17px;
}
.widget-best-pictures .pull-right.show-all a {
  color: #00AEEF;
}
.widget-best-pictures .my-best-pictures {
  padding: 15px 15px 10px 15px;
}
.widget-best-pictures .my-best-pictures .best-pics {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}
.widget-best-pictures .my-best-pictures .best-pics a {
  display: inline-block;
}
.make-announcements {
  background: #FFFFFF;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  padding: 25px 20px 10px 20px;
}
.make-announcements .form-group {
  margin-bottom: 0px;
}
.make-announcements .form-group .announcement-header {
  font-size: 14px;
  margin-bottom: 12px;
}
.make-announcements .form-group .wrap {
  background: #FFFFFF;
  border-radius: 4px;
}
.make-announcements .add {
  margin-top: 10px;
}
.make-announcements .add .btn-add {
  background-image: linear-gradient(-1deg, #1991EB 2%, #2DA1F8 98%);
  border: 1px solid #1B92EC;
  border-radius: 4px;
  padding: 5px 16px;
}
.smiley-list {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E6EAEE;
}
.smiley-list li {
  margin-left: 0px;
}
.smiley-list li a {
  font-size: 14px;
  margin-left: 0px;
  border-bottom: 2px solid transparent;
  color: #354052;
  border-radius: 0px;
  padding-bottom: 5px;
  padding-left: 0px;
  padding-right: 0px;
}
.smiley-list li a .fa {
  color: #354052;
  border-right: 0px;
}
.smiley-list li.active {
  border-bottom: 2px solid #2EA2F8;
}
.smiley-list li.active a {
  color: #354052!important;
}
.smiley-pics-content {
  border: 0px;
  padding: 5px 0px 5px 15px;
  margin-bottom: 0px;
  max-height: 120px;
  overflow: auto;
}
.smiley-pics-content .smiley-post {
  padding-right: 3px;
  margin-top: 7px;
  display: inline-block;
}
.modal-likes {
  width: 500px;
}
.modal-likes .people-like-list {
  margin-bottom: 0px;
  border-radius: 0px 0px 4px 4px;
  height: 360px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.modal-likes .people-like-list .list-group-item {
  border-radius: 0px;
  border: 1px solid #E6EAEE;
}
.modal-likes .people-like-list .list-group-item:first-child {
  border-top: 0px;
}
.modal-likes .people-like-list .list-group-item:last-child {
  border-radius: 0px 0px 4px 4px;
}
.modal-likes .people-like-list .list-group-item .media img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.modal-likes .people-like-list .list-group-item .media .media-body .media-heading .user {
  text-decoration: none;
  color: #354052;
}
.modal-likes .people-like-list .list-group-item .media .media-body .username {
  text-decoration: none;
  color: #7F8FA4;
}
.modal-likes .people-like-list .list-group-item .media .media-body .media-heading {
  margin-bottom: 0px;
  line-height: 20px!important;
}
.modal-likes .people-like-list .list-group-item .media .media-body .media-heading .follow-user {
  padding: 6px 30px;
  margin-top: 3px;
}
.modal-likes .people-like-list .list-group-item .media .media-body .media-heading .following-user {
  padding: 6px 20px;
}
.album-modal {
  width: 500px;
}
.album-modal .modal-dialog {
  margin: 30px auto;
}
.album-modal .album-header {
  border-bottom: 0px;
}
.album-modal .album-header .modal-title {
  font-size: 18px;
  font-weight: 600;
}
.album-modal .album-body {
  padding: 30px 16px;
}
.album-modal .album-body .albums-list {
  margin-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.album-modal .album-body .albums-list .album-images:hover .album,
.album-modal .album-body .albums-list .album-images.active .album {
  border-radius: 4px;
}
.album-modal .album-body .albums-list .album-images:hover .album img,
.album-modal .album-body .albums-list .album-images.active .album img {
  opacity: 0.7;
}
.album-modal .album-body .albums-list .album-images.active .btn-remove {
  display: block;
}
.album-modal .album-body .albums-list .album-images {
  margin-bottom: 14px;
  padding-left: 7px;
  padding-right: 7px;
}
.album-modal .album-body .albums-list .album-images .album {
  background-color: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  height: 90px;
  overflow: hidden;
}
.album-modal .album-body .albums-list .album-images .album img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.album-modal .album-body .albums-list .album-images .btn-remove {
  position: absolute;
  display: none;
  right: 10px;
  width: 16px;
  height: 16px;
  top: 2px;
  opacity: 0.7;
  padding: 0px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #000000;
  background: #000000;
  line-height: 12px;
}
.album-modal .album-body .albums-list .album-images .btn-remove i {
  color: #E76877;
  font-size: 12px;
}
.album-modal .album-body .album-box {
  border: 1px solid transparent!important;
  padding: 0px;
  height: 110px!important;
}
.album-modal .album-body .album-box .inner-box {
  background: #F7F8FA;
  padding: 10px;
  border: 2px dashed #C4CDD9!important;
}
.album-modal .album-body .album-box .inner-box .inner-box-header {
  font-size: 16px;
  color: #354052;
  margin: 0px;
}
.album-modal .album-body .album-box .inner-box i {
  margin-top: 0px!important;
  font-size: 36px;
  color: #1B92EC;
}
.album-modal .album-body .album-buttons {
  margin-top: 15px;
}
.album-modal .album-body .album-buttons .btn-danger {
  padding: 7px 22px;
  background: #E76877;
  border-radius: 4px;
}
.album-modal .album-body .album-buttons .btn-default {
  padding: 6px;
}
.album-modal .album-body .album-buttons .btn-success {
  padding: 7px 30px;
}
.album-modal .album-body .album-buttons .right-buttons a {
  margin-left: 12px;
}
.dark-well {
  background: #273142;
  border: 1px solid #E6EAEE;
  padding: 11px 11px 11px 77px;
  border-radius: 4px 4px 0px 0px;
}
.dark-well p {
  color: #FFFFFF;
  margin: 0px;
}
.independent-themes .independent-block {
  background: #FFFFFF;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}
.independent-themes .independent-block .theme-image a img {
  width: 100%;
  height: 160px;
}
.independent-themes .independent-block .theme-image .selected {
  display: none;
  text-transform: none;
  position: absolute;
  font-weight: 400;
  right: 36px;
  top: 20px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.59);
  border: 1px solid transparent;
  color: #FFFFFF;
  padding: 5px 17px;
}
.independent-themes .independent-block .theme-sale {
  margin-top: 15px;
  margin-bottom: 5px;
}
.independent-themes .independent-block .theme-sale .theme-details {
  margin-left: 10px;
}
.independent-themes .independent-block .theme-sale .theme-details .theme-name {
  color: #354052;
  font-size: 14px;
}
.independent-themes .independent-block .theme-sale .theme-details .theme-author {
  color: #7F8FA4;
  font-size: 12px;
}
.independent-themes .independent-block .theme-sale .theme-rate {
  background: #E8EAF1;
  border: 1px solid #E8EAF1;
  border-radius: 4px;
  font-size: 14px;
  color: #8D909F;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 36px;
}
.independent-themes .independent-block:hover,
.independent-themes .independent-block.active {
  background: #222C3C;
  border: 1px solid #E6EAEE;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}
.independent-themes .independent-block:hover .theme-sale .theme-details .theme-name,
.independent-themes .independent-block.active .theme-sale .theme-details .theme-name {
  color: #FFFFFF;
}
.independent-themes .independent-block:hover .theme-rate,
.independent-themes .independent-block.active .theme-rate {
  background: rgba(25, 145, 235, 0.4);
  border: 1px solid #1991EB;
  border-radius: 4px;
  color: #FFFFFF;
}
.independent-themes .independent-block.active .selected {
  display: block;
}
.login-block.static-pages {
  width: 100%;
  padding-top: 0px;
}
.panel-body.static-body {
  padding: 0px 30px 30px 30px;
}
.static-para {
  margin-bottom: 0px;
  padding: 25px 0px 42px 0px;
}
.contact-panel .contact-login {
  padding: 0px 15px;
  margin-top: 0px;
}
.contact-panel .contact-login .form-group {
  margin: 11px 0px;
}
.contact-panel .contact-login .form-group .form-control {
  padding: 13px;
}
.contact-panel .contact-login .btn-submit {
  margin: 0;
  padding: 8px;
  border-radius: 2px;
  min-width: 142px;
  outline: none;
}
.post-addon {
  border-top: 1px solid #E6EAEE;
  border-bottom: 1px solid transparent;
  vertical-align: middle;
}
.post-addon .post-addon-icon {
  display: inline-block;
  float: left;
  border-right: 1px solid #d3d4d5;
  margin-top: 10px;
}
.post-addon .post-addon-icon i {
  color: #9FA9BA;
}
.post-addon .post-addon-icon .fa-map-marker {
  padding-left: 13px;
  padding-right: 13px;
}
.post-addon .post-addon-icon .fa-music {
  padding-right: 13px;
}
.post-addon .post-addon-icon .fa-music.fa-spinner {
  padding-right: 10px;
}
.post-addon i {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  font-size: 13px;
  color: #354052;
  font-weight: 400;
}
.post-addon .form-group {
  padding-left: 36px;
  margin-bottom: 0px;
}
.post-addon .form-group .form-control {
  background-color: #fff !important;
  border-width: 0px;
}
.post-addon .form-group .youtube-text {
  border-radius: 0px;
  border: 1px solid transparent;
  box-shadow: none;
  outline: none;
  float: right;
  padding-left: 8px;
  color: #354052;
  font-size: 13px;
  font-weight: 400;
}
.post-addon .form-group .selectize-control {
  border: 0px;
  background-color: transparent !important;
}
.post-addon .form-group .selectize-control .selectize-input {
  border: 0px;
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 0px;
}
.post-addon .form-group .selectize-control .selectize-input input {
  color: #354052;
}
.post-addon .form-group .selectize-control .selectize-input input::-webkit-input-placeholder {
  color: #c3c6cb;
}
.post-addon .form-group .selectize-control .selectize-input input:-moz-placeholder {
  /* Firefox 18- */
  color: #c3c6cb;
}
.post-addon .form-group .selectize-control .selectize-input input::-moz-placeholder {
  /* Firefox 19+ */
  color: #c3c6cb;
}
.post-addon .form-group .selectize-control .selectize-input input:-ms-input-placeholder {
  color: #c3c6cb;
}
.post-addon .form-group .selectize-control .selectize-input.focus {
  outline: none;
  box-shadow: none;
}
.post-addon .form-group .selectize-control .selectize-dropdown {
  padding-left: 0px;
  border-color: #E6EAEE;
}
.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown {
  background-color: #fff;
  padding-top: 15px;
  padding-left: 15px;
}
.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-left img {
  border-radius: 4px;
}
.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-body {
  max-height: 24px;
  overflow: hidden;
}
.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-body .media-heading {
  margin-bottom: 0px;
  color: #354052;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown .media-body p {
  color: #7F8FA4;
  font-size: 12px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  height: 17px;
  overflow: hidden;
  margin-bottom: 10px;
  line-height: 15px;
}
.post-addon .form-group .selectize-control .selectize-dropdown .big-search-dropdown.active {
  background-color: #fafbfc;
}
.emoticons-wrapper {
  margin-bottom: 0px;
  border-top: 1px solid #E6EAEE;
  border-radius: 0px;
  width: 100%;
  display: none;
}
.emoticons-wrapper a {
  margin-left: 8px;
}
.emoticons-wrapper a img {
  padding: 7px 2px 7px 3px;
}
.images-selected {
  padding: 5px 10px;
  border-top: 1px solid #E6EAEE;
  font-size: 13px;
  color: #898F9C;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
#post-image-holder img {
  width: auto !important;
  height: 60px !important;
  margin-right: 5px;
  padding-left: 13px;
}
.post-image-holder a {
  width: 32.5%;
  max-height: 100px;
  margin-bottom: 10px;
  display: inline-block;
  background-size: cover !important;
  overflow: hidden;
}
.post-image-holder.single-image {
  max-height: inherit !important;
  height: auto;
}
.post-image-holder.single-image a {
  max-height: inherit;
  height: auto;
  width: 100%;
}
.post-image-holder.single-image a img {
  max-height: inherit!important;
  height: auto;
  width: 100%;
}
.soundcloud-results,
.user-results {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
.soundcloud-results img,
.user-results img {
  width: 30px;
  height: 30px;
}
.soundcloud-wrapper {
  height: 166px;
  margin-top: 10px;
  overflow: hidden;
}
.selectize-input {
  min-width: 260px;
}
.big-search-dropdown {
  margin-top: 0px;
  margin-bottom: 10px;
  /*border-bottom: 1px solid #ddd;*/
  font-size: 12px;
}
.big-search-dropdown:nth-child(2n) {
  /*background-color:#f9f9f9;*/
}
.big-search-dropdown img {
  width: 38px;
  height: auto;
}
.big-search-dropdown h4 {
  font-size: 14px;
  font-weight: 600;
}
.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
}
.youtube-iframe {
  margin-bottom: -5px;
}
.youtube-iframe iframe {
  width: 100%;
  height: 360px;
}
.img-rectangle {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.announcement p {
  line-height: 20px;
  font-size: 13px;
}
.announcement h3 {
  margin-top: 0px;
  margin-bottom: 5px;
}
/* switch margin from right to left */
.mCustomScrollbar .mCSB_inside > .mCSB_container {
  margin-right: 0;
}
.verified-badge {
  width: 20px;
  height: 20px;
  background: #2AB314;
  border-radius: 50%;
  padding: 3px;
  line-height: 15px;
  vertical-align: text-bottom;
  margin-left: 10px;
  color: #FFFFFF;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 5px;
}
.verified-badge.verified-small {
  width: 17px;
  height: 17px;
  padding: 2px;
  line-height: 13px;
}
.verified-badge.verified-medium {
  padding: 2px;
  margin-left: 0px;
}
.socialite-logo {
  height: 46px;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
.media-heading a {
  color: #354052;
}
.small-text {
  font-size: 14px;
}
.small-case {
  text-transform: lowercase !important;
}
.announcements .form_onlydate {
  padding-left: 19px !important;
  padding-right: 19px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-18 {
  font-size: 18px !important;
}
.grey-panel .panel-heading {
  padding: 0px !important;
}
.grey-panel .panel-title {
  padding-top: 24px;
  padding-left: 27px;
}
.grey-panel .create-btn {
  padding-top: 15px;
  padding-right: 15px;
}
.activate {
  padding-right: 11px;
  padding-bottom: 11px;
}
.padding-t5 {
  padding-top: 5px !important;
}
.padding-b10 {
  padding-bottom: 10px !important;
}
.custom-label {
  padding-left: 0px !important;
  padding-right: 30px;
}
.tag-heading {
  display: block !important;
}
.no-caps .badge {
  color: #FFFFFF !important;
  font-weight: 400 !important;
}
.posts-list .nav-pills {
  background: #FFFFFF !important;
}
.posts-list .tab-content {
  background-color: transparent !important;
  padding-left: 0px;
  padding-right: 0px;
}
.nav-pills li a {
  font-size: 17px;
  padding: 11px !important;
}
.nav-pills .divider {
  margin-top: 11px;
  height: 27px;
}
.font-15 {
  font-size: 15px !important;
}
.text-white {
  color: #FFFFFF !important;
}
.no-activate {
  pointer-events: none;
  cursor: default;
  background: #CED0DA;
}
.label-active {
  background: #1bb934;
}
.label-pending {
  background: #8a6d3b;
}
.label-planned {
  background: #4695d9;
}
.label-rejected {
  background: #a94442;
}
.label-closed {
  background: #9299A7;
}
#cometchat_tabcontainer {
  display: none !important;
}
#cometchat_chats {
  display: none !important;
}
#cometchat_groups {
  display: none !important;
}
#cometchat_float_NewGroup {
  display: none !important;
}
.second-bar {
  border-top: 1px solid #EEEEEE;
  padding-top: 10px;
  font-size: 16px;
}
.take-tour {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 8px !important;
  padding-bottom: 4px !important;
  margin-top: 8px;
  margin-right: 10px;
}
.take-tour i {
  padding-bottom: 4px;
}
.popover-navigation .btn-default {
  margin-left: 20px;
}
#cometchat_newchat {
  display: none !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.inherit-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.overlay {
  background: transparent;
  position: relative;
  width: 640px;
  height: 480px;
  /* your iframe height */
  top: 480px;
  /* your iframe height */
  margin-top: -480px;
  /* your iframe height */
}
.small-link {
  border-bottom-color: transparent !important;
}
.small-link a {
  font-size: 13px !important;
  font-weight: 400;
  color: #7f8fa4 !important;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 12px;
}
.small-link a:hover {
  color: #7f8fa4 !important;
  text-decoration: underline !important;
  background-color: transparent !important;
}
.small-link:hover {
  border-bottom: 1px solid transparent !important;
}
.small-link:hover a {
  color: #7f8fa4 !important;
  text-decoration: underline !important;
  background-color: transparent !important;
}
.small-link:active {
  border-bottom-color: transparent !important;
}
.header-text {
  color: #354052;
}
.zurra-description .large-text {
  margin-top: 0px;
}
.no-padding-top {
  padding-top: 0px !important;
}
.no-padding-left {
  padding-left: 0px !important;
}
.no-padding-right {
  padding-right: 0px !important;
}
.no-margin-top {
  margin-top: 0px !important;
}
.no-margin-right {
  margin-right: 0px !important;
}
.no-margin-bottom {
  margin-bottom: 0px !important;
}
.no-margin-left {
  margin-left: 0px !important;
}
.guest-nav {
  margin-bottom: 0px !important;
}
.landing-bg {
  background-image: url('/themes/default/assets/images/ropes copy.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.p-t-50 {
  padding-top: 50px !important;
}
