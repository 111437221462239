@import 'variables.less';

// ****************************************************************************
// *                               NAVBAR BRAND                               *
// *                            logo or brand name                            *
// ****************************************************************************
.panel-default
{
	background: #FFFFFF;
	border: 1px solid @panel-border-color;
	border-radius: 4px;
	-webkit-box-shadow: none; 
    box-shadow : none;
   .panel-heading
   {
        .gradient(@default-from-color,@default-to-color);
        border: 1px solid #E6EAEE;
        border-radius: 4px 4px 0px 0px !important;
        height: 50px;
        color: @primary-content-color;
        border: 1px solid #E6EAEE;
        border-radius: 0px 0px 4px 4px;
        .panel-title
        {
            font-size: 16px;
            line-height: 28px;
            text-transform: capitalize;
            color: @primary-content-color;
            font-weight: 600;
            a
            {
                color: @primary-color;
                font-weight: 600;
                font-size: 12px;
                text-decoration: none !important;
                &:hover
                {
                    color: darken(@primary-color, 5%);
                }
            }
        }

        &.no-bg
        {
            background: none;
            border-color: transparent;
        }
    }
    .panel-body.nopadding-top-bottom
    {
        padding: 0px 15px;
    }
    .panel-body.nopadding,.panel-heading.nopadding
    {
        padding: 0px;
    }
    .panel-body.bg-grey
    {
        background-color: #EFF3F6;
    }

}
